import React, { useCallback, useEffect, useState } from 'react';
import { Construction, Download, Upload, NotebookPen, CheckCircle2, X, MapPinHouse } from 'lucide-react';
import '../styles/components/_docsincorporadoras.scss';
import '../styles/components/_docsenterprise.scss';
import { ToastContainer, toast } from "react-toastify";
import Unit from "../domain/entities/unit";
import Tooltip from './tootip/Tootip';
import ConstructionCompanyProvider from '../infra/provider/constructionCompanyProvider';
import { Address } from '../domain/entities/address';
interface Enterprise {
    id: number;
    name: string;
}
interface Document {
    id: number;
    code: string;
    name: string;
    status: 'Não enviado' | 'Enviado' | 'Aprovado' | 'Reprovado';
    observation?: string;
    uploaded: boolean;
}
interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (observation: string) => void;
    currentObservation: string;
}
interface AddDocumentModalProps {
    isOpen: boolean;
    onClose: () => void;
    onAdd: (name: string) => void;
    selectedEnterpriseId: string;
}

interface Incorporadora {
    id: string;
    createdAt: string;
    cnpj: string;
    razaoSocial: string;
    responsibleName: string;
    email: string;
    phone: string;
    cpf: string;
    address: Address;
    status: "active" | "inactive";
}

const AddDocumentModal: React.FC<AddDocumentModalProps> = ({ isOpen, onClose, onAdd, selectedEnterpriseId }) => {
    const [documentName, setDocumentName] = useState('');
    const [currentDoc, setCurrentDoc] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const constructionCompanyProvider = ConstructionCompanyProvider.getInstance();

    if (!isOpen) return null;

    const handleCleanDocuments = () => {
        setDocumentName('');
        setCurrentDoc('');
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        handleCleanDocuments();
        try {
            await constructionCompanyProvider.postConstructionCompanyDocumentById(selectedEnterpriseId, 'EX', documentName, currentDoc);
            toast.success("Documento adicionado com sucesso!");
        } catch (error) {
            toast.error("Erro ao adicionar documento.");
        }
        finally {
            onClose();
            setIsLoading(false);
        }
    };

    const handleUpload = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.onchange = async (e) => {
            const file = (e.target as HTMLInputElement).files?.[0];
            if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64String = reader.result?.toString().split(',')[1];
                    if (base64String) {
                        setCurrentDoc(base64String);
                        toast.success("Documento enviado com sucesso!");
                    }
                };
                reader.readAsDataURL(file);
            }
        };
        input.click();

        console.log(currentDoc);
    };

    return (
        <div className="modal-overlay-unidade">
            <div className="modal-unidade">
                <div className="modal-header-unidade">
                    <h2>Adicionar novo documento</h2>
                    <button className="close-button-unidade" onClick={onClose}>✕</button>
                </div>
                <p>Digite o nome do novo documento.</p>
                <div className="divisor-enterprise"></div>
                <div className='form-group'>
                    <input
                        value={documentName}
                        onChange={(e) => setDocumentName(e.target.value)}
                        placeholder="Nome do documento"
                    />
                    <button
                        onClick={() => handleUpload()}
                        className="upload-button-incorp"
                        title="Upload Documento"
                    >
                        <Upload size={10} />
                        <span className="upload-button-text"> Upload do documento</span>
                    </button>
                </div>

                <div className="form-buttons-unidade">
                    <button
                        type="button"
                        className="cancel-button-unidade"
                        onClick={onClose}
                    >
                        Cancelar
                    </button>
                    <button
                        type="submit"
                        className={`create-button-unidade ${isLoading ? "loading" : ""}`}
                        disabled={isLoading || !documentName.trim()}
                        onClick={handleSubmit}
                    >
                        {isLoading ? "Salvando..." : "Adicionar"}
                    </button>
                </div>
            </div>
        </div>
    );
};

const ObservationModal: React.FC<ModalProps> = ({ isOpen, onClose, onSave, currentObservation }) => {
    const [observation, setObservation] = useState(currentObservation);
    const [isLoading, setIsLoading] = useState(false);

    if (!isOpen) return null;

    const handleSave = () => {
        setIsLoading(true);
        onSave(observation);
        setIsLoading(false);
        onClose();
    };

    return (
        <div className="modal-overlay-unidade">
            <div className="modal-unidade">
                <div className="modal-header-unidade">
                    <h2>Adicionar observação:</h2>
                    <button className="close-button-unidade" onClick={onClose}>✕</button>
                </div>
                <p>Adicione uma observação para justificar a invalidação do documento.</p>
                <div className="divisor-enterprise"></div>
                <div className='form-group'>
                    <input
                        value={observation}
                        onChange={(e) => setObservation(e.target.value)}
                        placeholder="Digite o motivo da invalidação do documento."
                    />
                </div>

                <div className="form-buttons-unidade">
                    <button
                        type="button"
                        className="cancel-button-unidade"
                        onClick={onClose}
                    >
                        Cancelar
                    </button>
                    <button
                        type="submit"
                        className={`create-button-unidade ${isLoading ? "loading" : ""}`}
                        disabled={isLoading}
                        onClick={handleSave}
                    >
                        {isLoading ? "Salvando..." : "Salvar"}
                    </button>
                </div>
            </div>
        </div>
    );
};

function DocsIncorporadores() {
    const [selectedEnterprise, setSelectedEnterprise] = useState<string>('');
    const [activeTab, setActiveTab] = useState<string>('tab1');
    const [modalOpen, setModalOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [selectedDoc, setSelectedDoc] = useState<Document | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [documents, setDocuments] = useState<{
        docsExistentes: Document[];
        outros: Document[];
    }>({
        docsExistentes: [],
        outros: []
    });

    const constructionCompanyProvider = ConstructionCompanyProvider.getInstance();
    const [incorporadoras, setIncorporadoras] = useState<Incorporadora[]>([]);

    const getConstructionCompanies = useCallback(async () => {
        setIsLoading(true);

        const storedData = await constructionCompanyProvider.getConstructionCompaniesAsync();
        if (storedData && storedData.length > 0) {
            const updatedData = storedData.map((company) => ({
                id: company.id,
                createdAt: company.createdAt,
                cnpj: company.document,
                razaoSocial: company.name,
                responsibleName: company.responsibleName,
                email: company.email,
                phone: company.phone,
                cpf: company.responsibleDocument,
                address: company.address,
                status: company.status as "active" | "inactive"
            }));
            setIncorporadoras(updatedData);
        }
        setIsLoading(false);
    }, [constructionCompanyProvider]);

    useEffect(() => {
        getConstructionCompanies();
    }, [getConstructionCompanies]);

    const formatCNPJ = (cnpj: string) => {
        if (cnpj === "") return "";
        return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
    };

    const enterprises: Enterprise[] = incorporadoras.map((incorporadora) => ({
        id: Number(incorporadora.id),
        name: `${incorporadora.razaoSocial} | ${formatCNPJ(incorporadora.cnpj)}`
    }));

    const translateStatus = (status: Document['status']) => {
        switch (status) {
            case 'Não enviado':
                return 'not_sent';
            case 'Enviado':
                return 'sent';
            case 'Aprovado':
                return 'approved';
            case 'Reprovado':
                return 'reproved';
            default:
                return 'unknown';
        }
    };

    const handleStatusChange = async (documentId: number, newStatus: Document['status']) => {
        try {
            const documentCode = documents.docsExistentes.find(doc => doc.id === documentId)?.code;
            const uploded = documents.docsExistentes.find(doc => doc.id === documentId)?.uploaded;
            if (!uploded) {
                toast.info("O documento precisa ser enviado antes de atualizar o status.");
                return;
            }
            if (documentCode)
                await constructionCompanyProvider.putConstructionCompanyDocumentById(selectedEnterprise, documentCode, translateStatus(newStatus));

            toast.success("Status do documento atualizado com sucesso!");
        } catch (error) {
            toast.error("Não foi possivel atualizar o status.");
        }
    };

    const handleDownload = (documentId: number) => {
        const link = document.createElement('a');
        link.href = '#';
        link.download = `document-${documentId}.pdf`;
        link.click();
        toast.success("Documento baixado com sucesso!");
    };

    const handleUpload = (documentId: number) => {
        const input = document.createElement('input');
        input.type = 'file';
        input.onchange = (e) => {
            const file = (e.target as HTMLInputElement).files?.[0];
            if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64String = reader.result?.toString().split(',')[1];
                    if (base64String) {
                        handleSubmit(documentId, base64String);
                    }
                };
                reader.readAsDataURL(file);
            }
        };
        input.click();
    };

    const handleSubmit = async (documentId: number, base64String: string) => {
        setIsLoading(true);
        // handleCleanDocuments();
        try {
            const document = documents.docsExistentes.find(doc => doc.id === documentId);
            if (!document) return;

            await constructionCompanyProvider.postConstructionCompanyDocumentById(selectedEnterprise, document.code, document.name, base64String);
            toast.success("Documento adicionado com sucesso!");
        } catch (error) {
            toast.error("Erro ao adicionar documento.");
        }
        finally {
            setIsLoading(false);
            await getConstructionCompanies();
        }
    };

    const handleObservationClick = (doc: Document) => {
        if (doc.status === 'Reprovado') {
            setSelectedDoc(doc);
            setModalOpen(true);
        }
    };

    const handleAddDocument = (name: string) => {
        const newDoc: Document = {
            id: Date.now(),
            code: 'new',
            name,
            status: 'Não enviado',
            uploaded: false
        };
        setDocuments(prev => ({
            ...prev,
            outros: [...prev.outros, newDoc]
        }));
        toast.success("Documento adicionado com sucesso!");
    };

    const saveObservation = (observation: string) => {
        if (selectedDoc) {
            const activeDocuments = getActiveDocuments();
            const newDocs = activeDocuments.map(doc =>
                doc.id === selectedDoc.id ? { ...doc, observation } : doc
            );
            updateDocuments(newDocs);
            setModalOpen(false);
        }
    };

    const getActiveDocuments = () => {
        switch (activeTab) {
            case 'tab1': return documents.docsExistentes;
            case 'tab2': return documents.outros;
            default: return [];
        }
    };

    const updateDocuments = (newDocs: Document[]) => {
        setDocuments(prev => {
            const newState = { ...prev };
            switch (activeTab) {
                case 'tab1': newState.docsExistentes = newDocs; break;
                case 'tab2': newState.outros = newDocs; break;
            }
            return newState;
        });
    };

    const handleCleanDocuments = () => {
        setDocuments({
            docsExistentes: [],
            outros: []
        });
    }

    const handleEnterpriseChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        handleCleanDocuments();

        try {
            const selectedValue = e.target.value;
            setSelectedEnterprise(selectedValue);
            if (selectedValue) {
                const response = await constructionCompanyProvider.getConstructionCompanyDocumentById(selectedValue);
                if (response) {
                    response.forEach((doc) => {
                        const newDoc: Document = {
                            id: Number(doc.id),
                            code: doc.code,
                            name: doc.description,
                            status: doc.status as Document['status'],
                            observation: doc.obs,
                            uploaded: false
                        };
                        setDocuments(prev => ({
                            ...prev,
                            docsExistentes: [...prev.docsExistentes, newDoc]
                        }));
                    });
                }
            }
            toast.success(`Documentos carregados com sucesso!`);
        } catch (error) {
            toast.error("Erro ao carregar documentos.");
        }
    }

    const renderTable = (documents: Document[]) => (
        <>
            {activeTab === 'tab2' && (
                <div className="add-document-button-container" style={{ marginBottom: '1rem', textAlign: 'right' }}>
                    <button
                        onClick={() => setAddModalOpen(true)}
                        className="add-button-plan-viab">
                        + Adicionar documento
                    </button>
                </div>
            )}
            <table className="docs-table-incorp">
                <thead>
                    <tr>
                        <th>Situação</th>
                        <th>Nome do Documento</th>
                        <th>Status</th>
                        <th>Observação</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {documents.map((doc) => (
                        <tr key={doc.id}>
                            <td>
                                <div className="icon-name-incorp">
                                    {doc.uploaded ? (
                                        <CheckCircle2 size={20} className="check-icon-incorp" />
                                    ) : (
                                        <X size={20} className="x-icon-incorp" />
                                    )}
                                </div>

                            </td>
                            <td className="document-name-incorp">
                                {doc.name}
                            </td>
                            <td>
                                <select
                                    value={doc.status}
                                    onChange={(e) => handleStatusChange(doc.id, e.target.value as Document['status'])}
                                    className="status-select-incorp"
                                >
                                    <option value="Não enviado">Não enviado</option>
                                    <option value="Enviado">Enviado</option>
                                    <option value="Aprovado">Aprovado</option>
                                    <option value="Reprovado">Reprovado</option>
                                </select>
                            </td>
                            <td className="observation-cell-incorp">
                                {doc.observation || '-'}
                            </td>
                            <td className="actions-cell-incorp">
                                <Tooltip text="Upload">
                                    <button
                                        onClick={() => handleUpload(doc.id)}
                                        className="action-button-incorp"
                                        title="Upload"
                                    >
                                        <Upload size={20} />
                                    </button>
                                </Tooltip>
                                <Tooltip text="Download"> <button
                                    onClick={() => handleDownload(doc.id)}
                                    className="action-button-incorp"
                                    title="Download"
                                >
                                    <Download size={20} />
                                </button>
                                </Tooltip>
                                <Tooltip text="Observação">
                                    <button
                                        onClick={() => handleObservationClick(doc)}
                                        className={`action-button-incorp ${doc.status !== 'Reprovado' ? 'disabled' : ''}`}
                                        disabled={doc.status !== 'Reprovado'}
                                        title="Observação"
                                    >
                                        <NotebookPen size={20} />
                                    </button>
                                </Tooltip>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );

    return (
        <div className="container-docsenterprises-incorp">
            <div className="select-container-incorp">
                <label htmlFor="enterprise-select" className="select-label-incorp">
                    Selecione uma incorporadora para visualizar os documentos:
                </label>
                <select
                    id="enterprise-select-incorp"
                    className="enterprise-select-incorp"
                    value={selectedEnterprise}
                    onChange={(e) => handleEnterpriseChange(e)}
                >
                    <option value="" disabled>Selecione uma incorporadora</option>
                    {enterprises.map((enterprise) => (
                        <option key={enterprise.id} value={enterprise.id}>
                            {enterprise.name}
                        </option>
                    ))}
                </select>
            </div>

            {!selectedEnterprise ? (
                <div className="empty-state-incorp">
                    <MapPinHouse size={150} className="empty-icon-incorp" />
                    <p className="empty-text-incorp">
                        Selecione uma incorporadora para visualizar os resultados.
                    </p>
                </div>
            ) : (
                <>
                    <div className="tabs-navigation">
                        <button
                            className={`tab-button ${activeTab === 'tab1' ? 'active' : ''}`}
                            onClick={() => setActiveTab('tab1')}
                        >
                            Documentos existentes
                        </button>
                        <button
                            className={`tab-button ${activeTab === 'tab2' ? 'active' : ''}`}
                            onClick={() => setActiveTab('tab2')}
                        >
                            Outros
                        </button>
                    </div>
                    {renderTable(getActiveDocuments())}
                </>
            )}

            <ObservationModal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                onSave={saveObservation}
                currentObservation={selectedDoc?.observation || ''}
            />

            <AddDocumentModal
                isOpen={addModalOpen}
                onClose={() => setAddModalOpen(false)}
                onAdd={handleAddDocument}
                selectedEnterpriseId={selectedEnterprise}
            />

            <ToastContainer />
        </div>
    );
}

export default DocsIncorporadores;