import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/components/_listapropostas.scss";
import ProposalProvider from "../../infra/provider/proposalProvider";
import { Formatter } from "../../utils/formatter";
import ProposalStatus from "../../domain/entities/valueObjects/proposalStatus";
import { toast, ToastContainer } from "react-toastify";
import { ListSearch } from "../../domain/entities/valueObjects/listSearch";
import Tooltip from "../tootip/Tootip";
import { FilterX, Search } from "lucide-react";

interface TableData {
  id: string;
  createdAt: string;
  name: string;
  document: string;
  phone: string;
  email: string;
  enterpriseName: string;
  value: string;
  status: string;
}

const Enterprises = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [data, setData] = useState<TableData[]>([]);
  const navigate = useNavigate();
  const isFetching = useRef(false);
  const proposalProvider = ProposalProvider.getInstance();
  const [searchTrigger, setSearchTrigger] = useState(false); // Estado para acionar a busca
  const [fetchProposals, setFetchProposals] = useState(true); // Estado para acionar a busca

  const getProposals = useCallback(async () => {
    if (!fetchProposals && (!searchTrigger || isFetching.current)) return;
    setFetchProposals(false);
    isFetching.current = true;

    const search = new ListSearch({
      searchTerm,
      status: selectedStatus || undefined,
      orderBy: "createdAt",
      limit: itemsPerPage,
      offset: (currentPage - 1) * itemsPerPage,
      fromDate: selectedFromDate ? Formatter.dateStringToBrtTimestamp(selectedFromDate) : undefined,
      toDate: selectedToDate ? Formatter.dateStringToBrtTimestamp(selectedToDate) : undefined,
    });

    try {
      const response = await proposalProvider.getProposals(search);
      const mappedData = response.proposals.map((proposal) => ({
        id: proposal.id,
        createdAt: Formatter.timestampToDateBR(proposal.createdAt),
        name: proposal.lead.name,
        document: proposal.lead.document,
        phone: proposal.lead.phone,
        email: proposal.lead.email,
        enterpriseName: proposal.enterprise.name,
        value: proposal.unitPrice.getValueFormatted(),
        status: proposal.status.getFormatted(),
      }));
      setData(mappedData);
      setTotalItems(response.totalItems);
    } catch (error) {
      console.error(error);
      toast.error("Erro ao buscar propostas");
    } finally {
      isFetching.current = false;
      setSearchTrigger(false); // Reseta o gatilho de busca
    }
  }, [searchTrigger, searchTerm, selectedStatus, selectedFromDate, selectedToDate, itemsPerPage, currentPage]);

  useEffect(() => {
    if (searchTrigger || fetchProposals) {
      getProposals()
    };
  }, [searchTrigger, currentPage, fetchProposals]);

  const handleRowClick = (id: string) => {
    navigate(`/propostas/${id}`);
  };

  const handleClearFilters = () => {
    if (!searchTerm && !selectedStatus && !selectedFromDate && !selectedToDate) return;

    setSearchTerm("");
    setSelectedStatus("");
    setSelectedFromDate("");
    setSelectedToDate("");
    setCurrentPage(1);
  };

  const formatCPF = (document: string) =>
    document.replace(/\D/g, "").replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");

  const formatPhone = (phone: string): string => {
    const cleaned = phone.replace(/\D/g, "");
    if (cleaned.length === 13) {
      return `+${cleaned.slice(0, 2)} (${cleaned.slice(2, 4)}) ${cleaned.slice(4, 8)}-${cleaned.slice(8)}`;
    } else if (cleaned.length === 11) {
      return `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 7)}-${cleaned.slice(7)}`;
    }
    return phone;
  };

  return (
    <div className="data-table-container">
      <button className="add-button-plan-viab" onClick={() => navigate("/propostas/novo")}>
        + Criar nova proposta
      </button>
      <div className="filters-container">
        <div className="search-container">
          <input
            type="text"
            placeholder="Busque por um nome, CPF, ID etc..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onBlur={() => setSearchTrigger(true)}
          />
        </div>
        <select
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
          className="status-select"
        >
          <option value="">Selecione um status</option>
          {ProposalStatus.getAllStatus().map((status) => (
            <option key={status} value={status}>
              {new ProposalStatus(status).getFormatted()}
            </option>
          ))}
        </select>
        <div className="date-filter">
          <label className="data-picker-proposal">De:</label>
          <input
            type="date"
            value={selectedFromDate}
            onChange={(e) => setSelectedFromDate(e.target.value)}
            className="date-input"
          />
          <label className="data-picker-proposal">Até:</label>
          <input
            type="date"
            value={selectedToDate}
            onChange={(e) => setSelectedToDate(e.target.value)}
            className="date-input"
          />
        </div>
        <Tooltip text="Limpar filtros">
          <button className="clear-filters-button-enterprise" onClick={handleClearFilters}>
            <FilterX size={16} />
          </button>
        </Tooltip>
      </div>
      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Data de criação</th>
              <th>Nome</th>
              <th>CPF</th>
              <th>Celular</th>
              <th>Email</th>
              <th>Empreendimento</th>
              <th>Valor</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index} onClick={() => handleRowClick(item.id)}>
                <td>{item.id}</td>
                <td>{item.createdAt}</td>
                <td>{item.name}</td>
                <td>{item.document && formatCPF(item.document)}</td>
                <td>{item.phone && formatPhone(item.phone)}</td>
                <td>{item.email}</td>
                <td>{item.enterpriseName}</td>
                <td>{item.value}</td>
                <td>{item.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="table-footer">
        <div className="items-per-page">
          <span>Exibir por</span>
          <select
            value={itemsPerPage}
            onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
        </div>
        <div className="pagination">
          <button onClick={() => {
            setFetchProposals(true);
            setCurrentPage((prev) => Math.max(prev - 1, 1));
          }}
            disabled={currentPage === 1}
          >
            Anterior
          </button>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <button
              key={page}
              onClick={() => {
                setFetchProposals(true);
                setCurrentPage(page);
              }}
              className={currentPage === page ? "active" : ""}
            >
              {page}
            </button>
          ))}
          <button
            onClick={() => {
              setFetchProposals(true);
              setCurrentPage((prev) => Math.min(prev + 1, totalPages));
            }}
            disabled={currentPage === totalPages}
          >
            Próximo
          </button>
        </div>
      </div>
      <ToastContainer />
    </div >
  );
};

export default Enterprises;
