import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/components/_empreedimentos.scss";
import EnterpriseProvider from "../../infra/provider/enterpriseProvider";
import { ListSearch } from "../../domain/entities/valueObjects/listSearch";
import { debounce } from "lodash";
import { Formatter } from "../../utils/formatter";
import { ToastContainer, toast } from "react-toastify";
import Tooltip from "../tootip/Tootip";
import { FilterX } from "lucide-react";

interface TableData {
  id: string;
  createdAt: string;
  name: string;
  category: string;
  city: string;
  state: string;
  address: string;
  status: string;
}

const Enterprises = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState<TableData[]>([]);
  const navigate = useNavigate();
  const isFetching = useRef(false);
  const [dateRangeStart, setDateRangeStart] = useState("");
  const [dateRangeEnd, setDateRangeEnd] = useState("");

  const enterpriseProvider = EnterpriseProvider.getInstance();

  const getEnterprises = useCallback(
    async (
      pageNumber = 1,
      searchTerm = "",
      selectedDate = "",
      selectedStatus = "",
      startDate?: string,
      endDate?: string
    ) => {
      if (isFetching.current) return;
      isFetching.current = true;

      const search = new ListSearch({
        searchTerm: searchTerm,
        status: selectedStatus,
        orderBy: "createdAt",
        limit: itemsPerPage,
        offset: (pageNumber - 1) * itemsPerPage,
        fromDate: startDate ? Formatter.dateStringToTimestamp(startDate) : undefined,
        toDate: endDate ? Formatter.dateStringToTimestamp(endDate) : undefined,
      });

      const response = await enterpriseProvider.getEnterprises(search);
      const mappedData = response.enterprises.map((enterprise) => ({
        id: enterprise.id,
        createdAt: Formatter.timestampToDateBR(enterprise.createdAt, false),
        name: enterprise.name,
        category: enterprise.category.name,
        city: enterprise.address.city,
        state: enterprise.address.uf,
        address: enterprise.address.street,
        status: enterprise.status.getFormatted(),
      }));

      setData(mappedData);
      setTotalItems(response.totalItems);
      isFetching.current = false;
    },
    [itemsPerPage, enterpriseProvider]
  );

  useEffect(() => {
    getEnterprises(currentPage, searchTerm, selectedDate, selectedStatus);
  }, [itemsPerPage, currentPage, getEnterprises, searchTerm, selectedDate, selectedStatus]);

  const handleRowClick = (id: string) => {
    navigate(`/empreendimentos/${id}`);
  };

  const handleFiltersChange = (
    searchTerm: string,
    status: string,
    startDate: string,
    endDate: string
  ) => {
    getEnterprises(1, searchTerm, selectedDate, status, startDate, endDate);
  };

  const handleDateRangeStartChange = (value: string) => {
    if (value && dateRangeEnd && new Date(value) > new Date(dateRangeEnd)) {
      toast.error("A data de início não pode ser maior que a data de término.");
      return;
    }
    setDateRangeStart(value);
    if (value && dateRangeEnd) {
      handleFiltersChange(searchTerm, selectedStatus, value, dateRangeEnd);
    }
  };

  const handleDateRangeEndChange = (value: string) => {
    if (value && dateRangeStart && new Date(value) < new Date(dateRangeStart)) {
      toast.error("A data de término não pode ser menor que a data de início.");
      return;
    }
    setDateRangeEnd(value);
    if (value && dateRangeStart) {
      handleFiltersChange(searchTerm, selectedStatus, dateRangeStart, value);
    }
  };

  const debouncedSearchTerm = useRef(
    debounce((term: string) => {
      getEnterprises(1, term, `${dateRangeStart},${dateRangeEnd}`, selectedStatus);
    }, 600)
  ).current;

  const debouncedDateRangeChange = useRef(
    debounce(() => {
      getEnterprises(1, searchTerm, `${dateRangeStart},${dateRangeEnd}`, selectedStatus);
    }, 600)
  ).current;

  const handleSearchTermChange = (term: string) => {
    setSearchTerm(term);
    debouncedSearchTerm(term);
  };

  const handleDateRangeChange = () => {
    debouncedDateRangeChange();
  };

  const handleSearchDateChange = (date: string) => {
    setSelectedDate(date);
    debouncedDateRangeChange();
  };

  const handleClearFilters = () => {
    setSearchTerm("");
    setSelectedStatus("");
    setDateRangeStart("");
    setDateRangeEnd("");
    setCurrentPage(1);

    getEnterprises(1, "", "", "");
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <div className="data-table-container">
      <button
        className="add-button-plan-viab"
        onClick={() => navigate("/empreendimentos/novo")}>
        + Criar novo empreendimento
      </button>
      <div className="filters-container">
        <div className="search-container">
          <input
            type="text"
            placeholder="Busque por um nome, CPF, ID etc..."
            value={searchTerm}
            onChange={(e) => handleSearchTermChange(e.target.value)}
          />
          <button className="search-button">{/* Botão de busca */}</button>
        </div>
        <select
          value={selectedStatus}
          onChange={(e) => {
            setSelectedStatus(e.target.value);
            getEnterprises(1, searchTerm, selectedDate, e.target.value);
          }}
          className="status-select"
        >
          <option value="">Selecione um status</option>
          <option value="available">Ativo</option>
          <option value="not_available">Inativo</option>
        </select>
        <div className="date-range-filter">
          <label className="data-picker-enterprise">
            De:
            <input
              type="date"
              value={dateRangeStart}
              onChange={(e) => handleDateRangeStartChange(e.target.value)}
              className="date-input"
            />
          </label>
          <label className="data-picker-enterprise">
            Até:
            <input
              type="date"
              value={dateRangeEnd}
              onChange={(e) => handleDateRangeEndChange(e.target.value)}
              className="date-input"
            />
          </label>
        </div>
        <Tooltip text="Limpar filtros">
          <button className="clear-filters-button-enterprise" onClick={handleClearFilters}>
            <FilterX size={16} />
          </button>
        </Tooltip>
      </div>

      <div className="table-wrapper">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Data de criação</th>
              <th>Nome</th>
              <th>Categoria</th>
              <th>Cidade</th>
              <th>Estado</th>
              <th>Endereço</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr
                key={index}
                onClick={() => handleRowClick(item.id)} // Adicionando o clique na linha
              >
                <td>{item.id}</td>
                <td>{item.createdAt}</td>
                <td>{item.name}</td>
                <td>{item.category}</td>
                <td>{item.city}</td>
                <td>{item.state}</td>
                <td>{item.address}</td>
                <td>{item.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="table-footer">
        <div className="items-per-page">
          <span>Exibir por</span>
          <select
            value={itemsPerPage}
            onChange={(e) => {
              setItemsPerPage(parseInt(e.target.value));
              setCurrentPage(1);
            }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
        </div>
        <div className="pagination">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            Anterior
          </button>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <button
              key={page}
              onClick={() => setCurrentPage(page)}
              className={currentPage === page ? "active" : ""}
            >
              {page}
            </button>
          ))}
          <button
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            disabled={currentPage === totalPages}
          >
            Próximo
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Enterprises;
