import React, { useState } from "react";
import axios from "axios";
import "../../styles/components/_novosempreendimentos.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EnterpriseProvider from "../../infra/provider/enterpriseProvider";
import UploadImageProvider from "../../infra/provider/uploadImageProvider";
import CreateEnterprise from "../../domain/entities/createEnterprise";
import PropertyCategoryProvider from "../../infra/provider/propertyCategoryProvider";
import ConstructionCompanyProvider from "../../infra/provider/constructionCompanyProvider";
import ReleaseTypeProvider from "../../infra/provider/releaseTypeProvider";
import { Address } from "../../domain/entities/address";
import ZipCode from "../../domain/entities/valueObjects/zipCode";
import { useNavigate } from "react-router";
import "react-toastify/dist/ReactToastify.css";
import InputMask from "react-input-mask";
import { Upload } from "lucide-react";
import ConstructionCompany from "../../domain/entities/constructionCompany";

const StepsComponent: React.FC = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [cep, setCep] = useState("");
  const [address, setAddress] = useState({
    city: "",
    uf: "",
    neighborhood: "",
    street: "",
    number: "",
    zipCode: "",
    complement: "",
  });
  const [tags, setTags] = useState<string[]>([]);
  const [serviceTags, setServiceTags] = useState<string[]>([]);
  const [spaceTags, setSpaceTags] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [services, setServices] = useState<string[]>([]);
  const [areas, setAreas] = useState<string[]>([]);
  const [areaInput, setAreaInput] = useState("");
  const [spaces, setSpaces] = useState<string[]>([]);
  const [serviceInput, setServiceInput] = useState("");
  const [spaceInput, setSpaceInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [images, setImages] = useState<
    {
      file: File;
      progress: number;
      url?: string;
    }[]
  >([]);
  const [loading, setLoading] = useState(false);

  const enterpriseProvider = EnterpriseProvider.getInstance();
  const uploadImageProvider = UploadImageProvider.getInstance();
  const propertyCategoryProvider = PropertyCategoryProvider.getInstance();
  const constructionCompanyProvider = ConstructionCompanyProvider.getInstance();
  const releaseTypeProvider = ReleaseTypeProvider.getInstance();

  const [enterprise, setEnterprise] = useState<CreateEnterprise>(
    new CreateEnterprise()
  );

  const totalSteps = 11;

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const handleFileChange = (files: FileList) => {
    const currentLength = images.length; // Get current number of images
    const newImages = Array.from(files).map((file) => ({
      file,
      progress: 0,
    }));

    setImages((prevImages) => [...prevImages, ...newImages]);

    // Upload each new image with correct index
    newImages.forEach((image, idx) => {
      const actualIndex = currentLength + idx; // Calculate correct position
      uploadImage(actualIndex, image.file);
    });
  };

  // Fix the uploadImage function
  const uploadImage = async (index: number, file: File) => {
    try {
      // Set initial progress to 0 for this specific image
      setImages((prevImages) =>
        prevImages.map((img, i) =>
          i === index ? { ...img, progress: 0 } : img
        )
      );

      // Upload the image
      const imageUrl = await uploadImageProvider.upload(file);

      if (imageUrl) {
        // Update the specific image with URL and 100% progress
        setImages((prevImages) =>
          prevImages.map((img, i) =>
            i === index ? { ...img, progress: 100, url: imageUrl } : img
          )
        );
      }
    } catch (error) {
      console.error("Erro ao fazer upload da imagem:", error);
      toast.error(`Erro ao fazer upload de ${file.name}`);
    }
  };
  // Remove uma imagem
  const handleDeleteImage = (index: number) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  // Manipula drag and drop
  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.dataTransfer.files) {
      handleFileChange(e.dataTransfer.files);
    }
  };

  const handleFinalize = async () => {
    if (images.some((image) => !image.url)) {
      return toast.error("Por favor, espere todas as imagens serem carregadas");
    }

    setLoading(true);
    try {
      const addressObj = new Address(
        0,
        address.city,
        address.uf,
        address.neighborhood,
        address.street,
        address.number,
        new ZipCode(address.zipCode),
        address.complement
      );
      enterprise.address = addressObj;
      enterprise.tags = tags;
      enterprise.services = services;
      enterprise.commonAreas = areas;
      enterprise.spaces = spaces;
      enterprise.imagesUrl = images.map((image) => image.url || "");

      await enterpriseProvider.createEnterprise(enterprise);

      toast.success("Cadastro finalizado com sucesso!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      setTimeout(() => {
        navigate(`/empreendimentos/visualizar`);
      }, 1000);
    } catch (error) {
      toast.error("Erro ao criar o empreendimento");
    } finally {
      setLoading(false);
    }
  };


  // Função para avançar ao próximo passo
  const handleNext = () => {
    let isValid = true;

    if (currentStep === 1) {
      const propertyName = (
        document.getElementById("property-name") as HTMLInputElement
      ).value.trim();
      const propertyDescription = (
        document.getElementById("property-description") as HTMLInputElement
      ).value.trim();

      if (!propertyName || !propertyDescription) {
        isValid = false;
        toast.error("Preencha todos os campos obrigatórios!");
      }
    }

    if (currentStep === 2) {
      const propertyCategory = (
        document.getElementById("property-category") as HTMLSelectElement
      ).value;

      if (!propertyCategory) {
        isValid = false;
        toast.error("Selecione uma categoria!");
      }
    }

    if (currentStep === 3) {
      if (!cep || cep.length !== 9) {
        isValid = false;
        toast.error("Digite um CEP válido!");
      }
    }

    if (currentStep === 4) {
      const numero = (document.getElementById("numero") as HTMLInputElement).value.trim();
      const logradouro = (document.getElementById("logradouro") as HTMLInputElement).value.trim();
      const bairro = (document.getElementById("bairro") as HTMLInputElement).value.trim();
      const estado = (document.getElementById("estado") as HTMLInputElement).value.trim();
      const cidade = (document.getElementById("cidade") as HTMLInputElement).value.trim();

      if (!numero) {
        isValid = false;
        toast.error("Digite o número do endereço!");
      }

      if (!logradouro) {
        isValid = false;
        toast.error("Digite um endereço válido!");
      }

      if (!bairro) {
        isValid = false;
        toast.error("Digite um bairro válido!");
      }

      if (!estado) {
        isValid = false;
        toast.error("Digite um estado válido!");
      }

      if (!cidade) {
        isValid = false;
        toast.error("Digite uma cidade válido!");
      }
    }


    if (currentStep === 5) {
      if (tags.length === 0) {
        isValid = false;
        toast.error("Adicione pelo menos uma tag!");
      }
    }

    if (currentStep === 6) {
      if (services.length === 0) {
        isValid = false;
        toast.error("Adicione pelo menos uma tag para serviço e unidade!");
      }
    }

    if (currentStep === 7) {
      if (areas.length === 0) {
        isValid = false;
        toast.error("Adicione pelo menos uma tag!");
      }
    }

    if (currentStep === 8) {
      const construtora = (
        document.getElementById("property-category") as HTMLSelectElement
      ).value;

      if (!construtora) {
        isValid = false;
        toast.error("Selecione uma construtora!");
      }
    }

    if (currentStep === 9) {
      const selectedType = (
        document.getElementById("property-category") as HTMLSelectElement
      ).value;

      if (!selectedType) {
        isValid = false;
        toast.error("Selecione um tipo de lançamento!");
      }
    }

    if (currentStep === 10) {
      if (!isValidDate) {
        isValid = false;
        toast.error("Por favor, preencha uma data válida!");
      }
    }

    if (currentStep === 11) {
      const termsAccepted = (
        document.getElementById("terms-accept") as HTMLInputElement
      ).checked;

      // Verificar se os termos foram aceitos
      if (!termsAccepted) {
        isValid = false;
        toast.error("Aceite os termos para finalizar no passo 11!");
      }

      // Verificar se pelo menos uma imagem foi carregada
      if (images.length === 0) {
        isValid = false;
        toast.error(
          "Por favor, faça o upload de pelo menos uma imagem no passo 11!"
        );
      }
    }

    if (isValid && currentStep < totalSteps) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleCepChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputCep = e.target.value;

    inputCep = inputCep.replace(/\D/g, '');

    if (inputCep.length > 5) {
      inputCep = inputCep.replace(/^(\d{5})(\d)/, '$1-$2');
    }
    if (inputCep.length > 9) {
      inputCep = inputCep.substring(0, 9);
    }

    setCep(inputCep);

    if (inputCep.replace('-', '').length === 8) {
      try {
        setIsLoading(true); // Inicia o estado de loading

        const response = await axios.get(
          `https://viacep.com.br/ws/${inputCep.replace('-', '')}/json/`
        );

        if (response.data && !response.data.erro) {
          setAddress({
            city: response.data.localidade || '',
            uf: response.data.uf || '',
            neighborhood: response.data.bairro || '',
            street: response.data.logradouro || '',
            number: '',
            zipCode: inputCep.replace('-', ''),
            complement: response.data.complemento || '',
          });
        } else {
          toast.error('CEP não encontrado. Verifique e tente novamente.');
          setAddress({
            city: '',
            uf: '',
            neighborhood: '',
            street: '',
            number: '',
            zipCode: '',
            complement: '',
          });
        }
      } catch (error) {
        console.error('Erro ao buscar o CEP:', error);
        toast.error('Erro ao buscar o CEP. Tente novamente mais tarde.');
      } finally {
        setIsLoading(false); // Finaliza o estado de loading
      }
    }
  };




  const removeTag = (tagToRemove: string, type: string) => {
    if (type === "service") {
      setServiceTags(serviceTags.filter((tag) => tag !== tagToRemove));
    } else if (type === "space") {
      setSpaceTags(spaceTags.filter((tag) => tag !== tagToRemove));
    } else {
      setTags(tags.filter((tag) => tag !== tagToRemove));
    }
  };

  const handeTagsKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      e.preventDefault();
      setTags([...tags, inputValue.trim()]);
      setInputValue("");
    }
  };

  const handleServiceKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && serviceInput.trim() !== "") {
      e.preventDefault();
      setServices([...services, serviceInput.trim()]);
      setServiceInput("");
    }
  };

  // Adicionar espaços ao pressionar Enter
  //const handleSpaceKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
  //  if (e.key === "Enter" && spaceInput.trim() !== "") {
  //    e.preventDefault();
  //    setSpaces([...spaces, spaceInput.trim()]);
  //    setSpaceInput("");
  //  }
  //};

  // Função para adicionar áreas ao pressionar Enter
  const handleAreaKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && areaInput.trim() !== "") {
      e.preventDefault();
      setAreas([...areas, areaInput.trim()]);
      setAreaInput("");
    }
  };

  // Função para remover tags de serviços
  const removeService = (serviceToRemove: string) => {
    setServices(services.filter((service) => service !== serviceToRemove));
  };

  // Função para remover tags de espaços
  const removeSpace = (spaceToRemove: string) => {
    setSpaces(spaces.filter((space) => space !== spaceToRemove));
  };

  // Criando novo empreendimento
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [newConstructionCompany, setNewConstructionCompany] = useState('');

  const createNewConstructionCompany = async () => {
    if (!newConstructionCompany.trim()) {
      toast.error("Por favor, digite o nome da nova construtora.");
      return;
    }

    try {
      const response = await constructionCompanyProvider.createConstructionCompany(
        new ConstructionCompany("", newConstructionCompany.trim())
      );

      setEnterprise((prev) => ({
        ...prev,
        constructionCompanyId: response.id,
      }));

      setNewConstructionCompany("");
      setIsCreatingNew(false);

      toast.success("Construtora criada com sucesso!");
    } catch (error) {
      console.error("Erro ao criar a construtora:", error);
      toast.error("Houve um erro ao criar a construtora. Por favor, tente novamente.");
    }
  };




  const [isValidDate, setIsValidDate] = useState(true);

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const dateValue = e.target.value;
    setEnterprise((prev) => ({
      ...prev,
      deliveryDate: dateValue,
    }));

    if (isValidDeliveryDate(dateValue)) {
      setIsValidDate(true);
    } else {
      setIsValidDate(false);
    }
  };

  const isValidDeliveryDate = (date: string): boolean => {
    const parsedDate = new Date(date);
    const isValid = !isNaN(parsedDate.getTime());
    const now = new Date();
    if (isValid && parsedDate > now) {
      return true;
    }
    return false;
  };

  return (
    <div className="steps-container">
      <div className="form-header">
        <button className="back-button-arrow" onClick={prevStep}>
          &lt;
        </button>

        <span className="step-indicator">
          {currentStep}/{totalSteps}
        </span>
      </div>
      <div className="progress-bar-main">
        <div
          className="progress-bar-main__fill"
          style={{ width: `${(currentStep / totalSteps) * 100}%` }}
        ></div>
      </div>

      <form className="step-form">
        {currentStep === 1 && (
          <>
            <h2 className="step-header">
              Preencha as informações de identificação:
            </h2>
            <div className="form-group">
              <label htmlFor="property-name">Nome do imóvel:</label>
              <input
                required
                id="property-name"
                type="text"
                placeholder="Digite o nome do imóvel"
                value={enterprise.name}
                onChange={(e) =>
                  setEnterprise((prev) => ({
                    ...prev,
                    name: e.target.value,
                  }))
                }
              />
            </div>
            <div className="divisor"></div>
            <div className="form-group">
              <label htmlFor="property-description">Descrição do imóvel:</label>
              <input
                id="property-description"
                type="text"
                required
                placeholder="Digite uma descrição para o imóvel"
                value={enterprise.description}
                onChange={(e) =>
                  setEnterprise((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
              />
            </div>
          </>
        )}

        {currentStep === 2 && (
          <>
            <h2 className="step-header">Selecione uma categoria:</h2>
            <div className="form-group">
              <label htmlFor="property-category">Categoria do imóvel:</label>
              <select
                id="property-category"
                value={enterprise.categoryId}
                onChange={(e) =>
                  setEnterprise((prev) => ({
                    ...prev,
                    categoryId: e.target.value,
                  }))
                }
              >
                <option value="">Selecione uma categoria:</option>
                {propertyCategoryProvider
                  .getPropertyCategorys()
                  .filter((category) => category.id !== "")
                  .map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="divisor"></div>
          </>
        )}

        {currentStep === 3 && (
          <>
            <h2 className="step-header">Digite o CEP:</h2>
            <div className="form-group">
              <label htmlFor="property-cep">CEP:</label>
              <InputMask
                mask="99999-999"
                id="property-cep"
                type="text"
                value={cep}
                onChange={handleCepChange}
                placeholder="Digite o CEP"
              />
            </div>
            <div className="divisor"></div>
          </>
        )}

        {currentStep === 4 && (
          <>
            <h2 className="step-header">Confira o endereço:</h2>
            <div className="form-grid">
              <div className="form-group">
                <label htmlFor="logradouro">Logradouro:</label>
                <input
                  id="logradouro"
                  required
                  type="text"
                  value={address.street}
                  onChange={(e) =>
                    setAddress((prev) => ({ ...prev, street: e.target.value }))
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="numero">Número:</label>
                <input
                  id="numero"
                  type="text"
                  value={address.number}
                  onChange={(e) =>
                    setAddress((prev) => ({ ...prev, number: e.target.value }))
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="bairro">Bairro:</label>
                <input
                  id="bairro"
                  type="text"
                  value={address.neighborhood}
                  onChange={(e) =>
                    setAddress((prev) => ({
                      ...prev,
                      neighborhood: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="estado">Estado:</label>
                <input id="estado" type="text" value={address.uf} readOnly />
              </div>
              <div className="form-group">
                <label htmlFor="cidade">Cidade:</label>
                <input id="cidade" type="text" value={address.city} readOnly />
              </div>
              <div className="form-group">
                <label htmlFor="complemento">
                  Complemento <i>(Opcional):</i>
                </label>
                <input
                  id="complemento"
                  type="text"
                  value={address.complement}
                  onChange={(e) =>
                    setAddress((prev) => ({
                      ...prev,
                      complement: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <div className="divisor"></div>
          </>
        )}

        {currentStep === 5 && (
          <>
            <h2 className="step-header">Digite uma ou mais tags:</h2>
            <p className="step-subtitle">
              Tags são palavras chaves para buscar um imóvel.
            </p>
            <div className="form-group">
              <label htmlFor="property-tags">Tags</label>
              <input
                id="property-tags"
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={(e) => handeTagsKeyPress(e)}
                placeholder="Digite uma ou mais tags do empreendimento"
              />
            </div>
            <p className="step-subtitle-two">
              Digite ao menos uma tag e separe os itens apertando enter.
            </p>
            <div className="divisor"></div>
            <div className="tags-container">
              {tags.map((tag, index) => (
                <div key={index} className="tag">
                  <span>{tag}</span>
                  <button
                    type="button"
                    className="remove-tag-button"
                    onClick={() => removeTag(tag, "general")}
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          </>
        )}

        {currentStep === 6 && (
          <>
            <h2 className="step-header">Digite um ou mais serviços:</h2>

            <div className="form-group">
              <label htmlFor="services-input">Serviços:</label>
              <input
                id="services-input"
                type="text"
                value={serviceInput}
                onChange={(e) => setServiceInput(e.target.value)}
                onKeyDown={handleServiceKeyPress}
                placeholder="Digite os serviços do empreendimento"
              />
            </div>
            <p className="step-subtitle-two">
              Digite ao menos um serviço e separe os itens apertando enter.
            </p>
            <div className="tags-container">
              {services.map((service, index) => (
                <div key={index} className="tag">
                  <span>{service}</span>
                  <button
                    type="button"
                    className="remove-tag-button"
                    onClick={() => removeService(service)}
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
            <div className="divisor"></div>

            {/* <div className="form-group">
              <label htmlFor="spaces-input">Espaços</label>
              <input
                id="spaces-input"
                type="text"
                value={spaceInput}
                onChange={(e) => setSpaceInput(e.target.value)}
                onKeyDown={handleSpaceKeyPress}
                placeholder="Digite os espaços do empreendimento"
              />
            </div> */}
            {/* <p className="step-subtitle-two">
              Digite ao menos um espaço e separe os itens apertando enter.
            </p>
            <div className="tags-container">
              {spaces.map((space, index) => (
                <div key={index} className="tag">
                  <span>{space}</span>
                  <button
                    type="button"
                    className="remove-tag-button"
                    onClick={() => removeSpace(space)}
                  >
                    ×
                  </button>
                </div>
              ))}
            </div> */}
          </>
        )}

        {currentStep === 7 && (
          <>
            <h2 className="step-header">Digite as áreas comuns:</h2>
            <p className="step-subtitle">
              Áreas do empreendimento que os residentes podem usufruir.
            </p>
            <div className="form-group">
              <label htmlFor="common-area-tags">Áreas comuns</label>
              <input
                id="common-area-tags"
                type="text"
                value={areaInput}
                onChange={(e) => setAreaInput(e.target.value)}
                onKeyDown={(e) => handleAreaKeyPress(e)}
                placeholder="Digite as áreas comuns do empreendimento"
              />
            </div>
            <p className="step-subtitle-two">
              Digite ao menos uma área comum e separe os itens apertando enter.
            </p>
            <div className="divisor"></div>
            <div className="tags-container">
              {areas.map((area, index) => (
                <div key={index} className="tag">
                  <span>{area}</span>
                  <button
                    type="button"
                    className="remove-tag-button"
                    onClick={() => setAreas(areas.filter((a) => a !== area))}
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          </>
        )}

        {currentStep === 8 && (
          <>
            <h2 className="step-header">Selecione a construtora:</h2>
            <div className="form-group">
              <label htmlFor="property-category">Construtora:</label>
              {isCreatingNew ? (
                <div>
                  <input
                    type="text"
                    className="new-construtora"
                    placeholder="Digite o nome da nova construtora"
                    value={newConstructionCompany}
                    onChange={(e) => setNewConstructionCompany(e.target.value)}
                  />
                  <div className="btn-actions-construtora">
                    <button onClick={() => setIsCreatingNew(false)} className="cancel-btn-new-construtora">Cancelar</button>
                    <button
                      className="save-btn-new-construtora"
                      onClick={(e) => {
                        e.preventDefault(); // Evita o reload da página
                        createNewConstructionCompany();
                      }}
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              ) : (
                // Select para escolher construtora existente
                <div>
                  <select
                    id="property-category"
                    required
                    className="select-new-construtora"
                    value={enterprise.constructionCompanyId}
                    onChange={(e) =>
                      setEnterprise((prev) => ({
                        ...prev,
                        constructionCompanyId: e.target.value,
                      }))
                    }
                  >
                    <option value="">Selecione uma construtora:</option>
                    {constructionCompanyProvider.getConstructionCompanies().map((company) => (
                      <option key={company.id} value={company.id}>
                        {company.name}
                      </option>
                    ))}
                  </select>
                  <p className="frase-construtora">Nao achou a construtora que queria?</p>
                  <button onClick={() => setIsCreatingNew(true)} className="btn-new-construtora">
                    Criar nova construtora
                  </button>
                </div>
              )}
            </div>
            <div className="divisor"></div>
          </>
        )}

        {currentStep === 9 && (
          <>
            <h2 className="step-header">Selecione o tipo de lançamento:</h2>
            <div className="form-group">
              <label htmlFor="property-category">Tipo de lançamento:</label>
              <select
                id="property-category"
                required
                value={enterprise.releaseTypeId}
                onChange={(e) =>
                  setEnterprise((prev) => ({
                    ...prev,
                    releaseTypeId: e.target.value,
                  }))
                }
              >
                <option value="">Selecione um tipo de lançamento</option>
                {releaseTypeProvider.getReleaseTypes().map((releaseType) => (
                  <option key={releaseType.id} value={releaseType.id}>
                    {releaseType.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="divisor"></div>
          </>
        )}

        {currentStep === 10 && (
          <>
            <h2 className="step-header">Preencha a data de entrega:</h2>
            <div className="form-group">
              <label htmlFor="property-name">Data de entrega:</label>
              <input id="property-name" type="date" placeholder="dd/mm/aaaa" required onChange={handleDateChange} />
            </div>
            <div className="divisor"></div>
          </>
        )}

        {currentStep === 11 && (
          <>
            <div>
              <h2 className="step-header">Upload de fotos:</h2>
              <div
                className="upload-area"
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleDrop}
                onClick={() => document.getElementById("file-input")?.click()}
              >
                <div className="upload-icon"></div>
                <Upload size={24} />
                <p className="upload-text">
                  Arraste e solte uma ou mais fotos aqui, ou clique para
                  selecionar diretamente do seu dispositivo.
                </p>
              </div>

              <input
                id="file-input"
                type="file"
                accept="image/png, image/jpeg"
                multiple
                onChange={(e) =>
                  e.target.files && handleFileChange(e.target.files)
                }
                style={{ display: "none" }}
              />

              <div className="upload-info">
                <p>Formatos suportados: PNG e JPEG</p>
                <p>Tamanho máximo da foto: 10 Mb</p>
              </div>
              <div className="divisor"></div>
              <div className="image-preview-container">
                {images.map((image, index) => (
                  <div key={index} className="image-preview">
                    <button
                      className="delete-button"
                      onClick={() => handleDeleteImage(index)}
                    >
                      &times;
                    </button>

                    <div className="details">
                      <div className="image-thumbnail">
                        <img
                          src={URL.createObjectURL(image.file)}
                          alt={`Preview ${index + 1}`}
                          className="preview-image"
                        />
                      </div>
                      <div>
                        <p className="file-name">{image.file.name}</p>
                        <p className="file-size">
                          {(image.file.size / 1024 / 1024).toFixed(2)} Mb
                        </p>
                      </div>
                    </div>
                    <div className="progress-container">
                      <div className="progress-bar-img">
                        <div
                          className="progress-img"
                          style={{ width: `${image.progress}%` }}
                        ></div>
                      </div>
                      <span className="progress-percentage">
                        {image.progress}%
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </form>

      <div className="step-footer">
        {/* Botão Voltar */}
        <button type="button" className="btn btn--back" onClick={handleBack}>
          Voltar
        </button>

        {/* Botão Avançar / Finalizar */}
        <button
          type="submit"
          className="btn btn--next"
          disabled={loading}
          onClick={currentStep === totalSteps ? handleFinalize : handleNext}
        >
          {currentStep === totalSteps && loading
            ? "Carregando..."
            : currentStep === totalSteps
              ? "Finalizar"
              : "Avançar"}
        </button>

        {/* Toast Container */}
        <ToastContainer />
      </div>
    </div>
  );
};

export default StepsComponent;
