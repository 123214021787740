import "../styles/components/_paginanaoencontrada.scss";
import React from "react"
import NotFoundIllustration from "../assets/images/404.png";

export const PageNotFound = () => {
    const handleGoBack = () => {
        window.history.back()
    }

    return (
        <div className="page-not-found-container">
            <div className="content">
                <img src={NotFoundIllustration || "/placeholder.svg"} alt="404 Illustration" className="illustration" />
                <h1 className="erro-titulo-page-not">Ops! Página não encontrada</h1>
                <p className="page-not-found-frase">Parece que você se perdeu no caminho. A página que você está procurando não existe ou foi movida.</p>
                <button onClick={handleGoBack} className="add-button-plan-viab">
                    Voltar ao inicio
                </button>
            </div>
        </div>
    )
}
