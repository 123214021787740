import ConstructionCompanyDocsDTO from "../../domain/dto/constructionCompanyDocsDTO";
import ConstructionCompanyDTO from "../../domain/dto/constructionCompanyDTO";
import ConstructionCompany from "../../domain/entities/constructionCompany";
import ConstructionCompanyDocs from "../../domain/entities/constructionCompanyDocs";
import Http from "../../http/http";
import HttpRequest from "../../http/httpRequest";
import ApiRoutes from "../../http/routes";

export default class ConstructionCompanyRepository {
  private static instance: ConstructionCompanyRepository | null = null;

  constructor(private http: Http) {}

  static getInstance(http: Http): ConstructionCompanyRepository {
    if (!ConstructionCompanyRepository.instance) {
      ConstructionCompanyRepository.instance =
        new ConstructionCompanyRepository(http);
    }
    return ConstructionCompanyRepository.instance;
  }

  async createConstructionCompany(constructionCompany: ConstructionCompany) {
    const request = new HttpRequest(
      ApiRoutes.constructionCompany,
      constructionCompany
    );

    return await this.http.post<ConstructionCompany>(request);
  }

  async updateConstructionCompany(constructionCompany: ConstructionCompany) {
    const request = new HttpRequest(
      `${ApiRoutes.constructionCompany}/${constructionCompany.id}`,
      constructionCompany
    );

    return await this.http.put<ConstructionCompany>(request);
  }
  async updateConstructionCompanyStatus(id: string, status: string) {
    const request = new HttpRequest(
      ApiRoutes.constructionCompanyStatus.replace("{id}", id),
      { status }
    );

    return await this.http.put<ConstructionCompany>(request);
  }

  async getConstructionCompanies(): Promise<ConstructionCompany[]> {
    const request = new HttpRequest(ApiRoutes.constructionCompany);

    const response = await this.http.get<{ data: ConstructionCompany[] }>(
      request
    );

    return response.data.map((constructionCompany) =>
      ConstructionCompanyDTO.fromJson(constructionCompany)
    );
  }

  async getConstructionCompanyById(id: string): Promise<ConstructionCompany> {
    const request = new HttpRequest(`${ApiRoutes.constructionCompany}/${id}`);

    const response = await this.http.get<ConstructionCompany>(request);
    return ConstructionCompanyDTO.fromJson(response);
  }

  async getConstructionCompanyDocumentById(
    id: string
  ): Promise<ConstructionCompanyDocs[]> {
    const request = new HttpRequest(
      ApiRoutes.constructionCompanyDocument.replace("{id}", id)
    );

    const response = await this.http.get<ConstructionCompanyDocs[]>(request);
    return response.map((value: any) =>
      ConstructionCompanyDocsDTO.fromJson(value)
    );
  }

  async putConstructionCompanyDocumentById(
    id: string,
    code: string,
    status: string
  ) {
    const request = new HttpRequest(
      ApiRoutes.constructionCompanyDocument.replace("{id}", id),
      { code, status }
    );
    const response = await this.http.put(request);
    return response;
  }

  async postConstructionCompanyDocumentById(
    id: string,
    code: string,
    description: string,
    documentLink: string | null
  ) {
    const request = new HttpRequest(
      ApiRoutes.constructionCompanyDocument.replace("{id}", id),
      { code, description, documentLink }
    );
    const response = await this.http.post(request);
    return response;
  }
}
