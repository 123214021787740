import ProjectPlan from "../../domain/entities/projectPlan";
import { AxiosHttp } from "../../http/axios";
import ProjectPlanRepository from "../repository/projectPlanRepository";

export default class ProjectPlanProvider {
  private static instance: ProjectPlanProvider;
  private projectPlanRepository: ProjectPlanRepository;
  authErrors: { email: string; password: string } = { email: "", password: "" };

  private constructor() {
    this.projectPlanRepository = ProjectPlanRepository.getInstance(
      AxiosHttp.getInstance()
    );
  }

  static getInstance(): ProjectPlanProvider {
    if (!ProjectPlanProvider.instance) {
      ProjectPlanProvider.instance = new ProjectPlanProvider();
    }
    return ProjectPlanProvider.instance;
  }

  async createEnterpriseProjectPlan(
    enterpriseId: string,
    projectPlan: ProjectPlan
  ) {
    return await this.projectPlanRepository.createProjectPlan(
      enterpriseId,
      projectPlan
    );
  }

  async updateProjectPlan(projectPlan: ProjectPlan) {
    return await this.projectPlanRepository.updateProjectPlan(projectPlan);
  }

  async deleteProjectPlan(projectPlanId: string) {
    return await this.projectPlanRepository.deleteProjectPlan(projectPlanId);
  }

  async deleteProjectPlanImage(projectPlanId: string, imageId: string) {
    return await this.projectPlanRepository.deleteProjectPlanImage(
      projectPlanId,
      imageId
    );
  }
}
