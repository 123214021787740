export default class ConstructionCompanyDocs {
  constructor(
    public id = "",
    public companyId = "",
    public status = "",
    public code = "",
    public description = "",
    public obs = "",
    public createdAt = "",
    public updatedAt = "",
    public path = "",
    public history = []
  ) {}
}
