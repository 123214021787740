import {
  Home, Building, FileUp, LogOut, UserPlus2, PiggyBank, Trophy, MessageCircleQuestionIcon,
  NotebookIcon, FolderPlus, FolderKey, Construction
} from 'lucide-react';
import '../../styles/components/_sidebar.scss';
import Logo from '../../assets/images/homecorp-logo.png';
import LogoSM from '../../assets/images/homecorp-logo-small.png';
import User from '../../assets/images/avatar.png';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ViabilitySpreadsheet from '../ViabilitySpreadsheet';
import DocsEnterprises from '../DocsEnterprises';
import DocsIncorporadores from '../DocsIncorporadores';
import { AxiosHttp } from '../../http/axios';
import Loading from '../Loading';

const Sidebar: React.FC<{ setHeaderTitle: (title: string) => void }> = ({ setHeaderTitle }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const handleMouseEnter = () => setIsOpen(true);
  const handleMouseLeave = () => setIsOpen(false);

  useEffect(() => {
    const storedEmail = localStorage.getItem('userEmail');
    if (storedEmail) setEmail(storedEmail);
  }, []);

  const userName = email ? email.split('@')[0] : 'Usuário';

  return (
    <div className={`sidebar ${isOpen ? 'open' : 'closed'}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className="container">
        <div className="logo">
          <a href="https://www.homecorp.com.br/" target="_blank" rel="noopener noreferrer">
            <img src={isOpen ? Logo : LogoSM} alt="Logo" />
          </a>

        </div>

        <ul className="menu">
          <li onClick={() => { setHeaderTitle('Home'); navigate('/home'); }}>
            <Home size={20} className="menu-icon" />
            {isOpen && <span>Home</span>}
          </li>

          <li onClick={() => { setHeaderTitle('Propostas'); navigate('/propostas/visualizar'); }}>
            <PiggyBank size={20} className="menu-icon" />
            {isOpen && <span>Propostas</span>}
          </li>

          <li onClick={() => { setHeaderTitle('Empreendimentos'); navigate('/empreendimentos/visualizar'); }}>
            <Building size={20} className="menu-icon" />
            {isOpen && <span>Empreendimentos</span>}
          </li>

          <li onClick={() => { setHeaderTitle('Incorporadoras'); navigate('/Incorporadoras/visualizar'); }}>
            <Construction size={20} className="menu-icon" />
            {isOpen && <span>Incorporadoras</span>}
          </li>

          <li onClick={() => { setHeaderTitle('Usuarios'); navigate('/usuarios/visualizar'); }}>
            <UserPlus2 size={20} className="menu-icon" />
            {isOpen && <span>Usuarios</span>}
          </li>

          <li onClick={() => { setHeaderTitle('Leads'); navigate('/leads'); }}>
            <Trophy size={20} className="menu-icon" />
            {isOpen && <span>Leads</span>}
          </li>

          <li onClick={() => { setHeaderTitle('Tópicos Frequentes'); navigate('/outros/FAQ'); }}>
            <MessageCircleQuestionIcon size={20} className="menu-icon" />
            {isOpen && <span>Tópicos frequentes (FAQ)</span>}
          </li>

          <li onClick={() => { setHeaderTitle('Agenda de Visitas'); navigate('/outros/scheduling'); }}>
            <NotebookIcon size={20} className="menu-icon" />
            {isOpen && <span>Agenda de Visitas</span>}
          </li>

          <li onClick={() => { setHeaderTitle('Planilha de viabilidade'); navigate('/outros/ViabilitySpreadsheet'); }}>
            <FileUp size={20} className="menu-icon" />
            {isOpen && <span>Planilha de viabilidade</span>}
          </li>

          <li onClick={() => { setHeaderTitle('Documentos de incorporadoras'); navigate('/outros/DocsIncorporadores'); }}>
            <FolderPlus size={20} className="menu-icon" />
            {isOpen && <span>Docs de incorporadores</span>}
          </li>

          <li onClick={() => { setHeaderTitle('Documentos de empreendimentos'); navigate('/outros/DocsEnterprises'); }}>
            <FolderKey size={20} className="menu-icon" />
            {isOpen && <span>Docs de empreendimentos</span>}
          </li>
        </ul>
      </div>

      <div className="user-info">
        <img src={User} alt="User" />
        <div className="user-details">
          <span>{userName}</span>
          <span>{email}</span>
        </div>
      </div>

      <ul className="menu logout">
        <li onClick={AxiosHttp.logout}>
          <LogOut size={20} className="menu-icon-out" />
          {isOpen && <span>Sair</span>}
        </li>
      </ul>

    </div>


  );
};

export default Sidebar;
