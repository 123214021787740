import ConstructionCompanyDocs from "../entities/constructionCompanyDocs";

export default class ConstructionCompanyDocsDTO {
  public static fromJson(json: any): ConstructionCompanyDocs {
    return new ConstructionCompanyDocs(
      json.id,
      json.companyId,
      json.status,
      json.code,
      json.description,
      json.obs,
      json.createdAt,
      json.updatedAt,
      json.path,
      json.history
    );
  }
}
