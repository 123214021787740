export const PENDING = "pending";
export const CANCELED = "canceled";
export const NO_SHOW = "no_show";
export const COMPLETED = "completed";
export const RESCHEDULED = "rescheduled";
export const UNREACHABLE = "unreachable";

export const PENDING_LABEL = "Pendente";
export const CANCELED_LABEL = "Cancelado";
export const NO_SHOW_LABEL = "Ausente";
export const COMPLETED_LABEL = "Concluído";
export const RESCHEDULED_LABEL = "Reagendado";
export const UNREACHABLE_LABEL = "Inacessível";