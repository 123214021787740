import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/components/_propostadetalhes.scss";
import {
  ChevronLeft,
  SendIcon,
  CircleCheck,
  Edit2,
  Trash2,
  Upload,
  Ban,
  Download,
  X,
} from "lucide-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tooltip from "../tootip/Tootip";
import ProposalProvider from "../../infra/provider/proposalProvider";
import { Proposal } from "../../domain/entities/proposal";
import { Formatter } from "../../utils/formatter";
import ProposalDocument from "../../domain/entities/proposalDocument";
import ProposalStatus from "../../domain/entities/valueObjects/proposalStatus";
import PreApprovedValue from "../../domain/entities/valueObjects/preApprovedValue";
import { PreApprovedType } from "../../domain/entities/valueObjects/preApprovedType";
import UploadImageProvider from "../../infra/provider/uploadImageProvider";

export function ProposalDetails() {
  const [proposal, setProposal] = useState<Proposal>(new Proposal());
  const isFetching = useRef(false);
  const proposalProvider = ProposalProvider.getInstance();
  const uploadImageProvider = UploadImageProvider.getInstance();

  const { id } = useParams();
  const [activeTab, setActiveTab] = useState("personal")
  const getProposalDetails = async () => {
    if (isFetching.current) return;
    try {
      isFetching.current = true;
      const response = await proposalProvider.getProposalById(id!);
      setProposal(response);
    } catch (error) {
      toast.error("Erro ao buscar detalhes da proposta.");
    }
    isFetching.current = false;
  };

  useEffect(() => {
    getProposalDetails();
    setActiveTab("dados-pessois");
  }, [id]);

  //const [observations, setObservations] = useState<Observation[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [observation, setObservation] = useState(proposal.observation);
  const [lastUpdated, setLastUpdated] = useState("");
  //const [tempObservation, setTempObservation] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenItem, setIsModalItemOpen] = useState(false);
  const [isModalOpenUpload, setIsModalUploadOpen] = useState(false);
  const [email, setEmail] = useState("");
  //const [tempObservation, setTempObservation] = useState('');
  //const [editingIndex, setEditingIndex] = useState(null);
  //const [editingIndex, setEditingIndex] = useState<number | null>(null);

  const [formData, setFormData] = useState({
    value: "",
    type: "",
    date: "",
  });

  interface Observation {
    body: string;
    createdAt: string;
  }

  const [observations, setObservations] = useState<Observation[]>([]);
  const [tempObservation, setTempObservation] = useState("");
  const [isModalOpenObs, setIsModalOpenObs] = useState(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);

  const handleAddObservation = () => {
    if (tempObservation.trim() === "") {
      toast.error("A observação não pode ser vazia!");
      return;
    }

    const newObservation: Observation = {
      body: tempObservation,
      createdAt: new Date().toISOString(),
    };
    setObservations([...observations, newObservation]);
    setTempObservation("");
    setIsModalOpenObs(false);
    toast.success("Observação adicionada com sucesso!");
  };

  const handleDeleteObservation = (index: number) => {
    const updatedObservations = observations.filter((_, i) => i !== index);
    setObservations(updatedObservations);
    toast.success("Observação removida com sucesso!");
  };

  const handleCancelModalObs = () => {
    setTempObservation("");
    setIsModalOpenObs(false);
  };

  // const handleEditObservation = (index: number) => {
  //   setEditingIndex(index);
  //   setTempObservation(observations[index].text);
  //   setIsModalOpenObs(true);
  //   toast.success("Observação editada aaacom sucesso!");
  // };

  const handleSaveEdit = () => {
    if (editingIndex !== null) {
      setTempObservation("");
      setIsModalOpenObs(false);
      setEditingIndex(null);
    }
  };

  const handleChangeStatus = async (status: string) => {
    toast.loading("Atualizando status da proposta...");
    try {
      await proposalProvider.updateProposalStatus(
        proposal.id,
        new ProposalStatus(status),
        ""
      );
      getProposalDetails();
      toast.dismiss();
      toast.success("Status da proposta atualizado com sucesso!");
    } catch (error) {
      toast.dismiss();
      toast.error("Erro ao atualizar status da proposta.");
    }
  };

  const handleUploadDocument = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!proposalDocument) {
      toast.error("Por favor, selecione um arquivo.");
      return;
    }
    if (proposalDocumentTitle.trim() === "") {
      toast.error("Por favor, insira um título para o documento.");
      return;
    }

    toast.loading("Enviando documento...");
    try {
      const imageUrl = await uploadImageProvider.upload(proposalDocument);

      if (!imageUrl) {
        throw new Error("URL de upload inválida.");
      }

      await proposalProvider.uploadProposalDocument(
        proposal.id,
        new ProposalDocument("", 0, proposalDocumentTitle, imageUrl)
      );

      getProposalDetails();
      toast.dismiss();
      toast.success("Documento adicionado com sucesso!");

      // Resetar estados e fechar modal
      setIsModalUploadOpen(false);
      setProposalDocument(null);
      setProjectPlanImage({ file: null, progress: 0 });
    } catch (error) {
      console.error("Erro ao enviar documento:", error);
      toast.dismiss();
      toast.error("Erro ao enviar documento.");
    }
  };


  const openDocument = async (doc: ProposalDocument) => {
    window.open(doc.path, "_blank")?.focus();
  };

  const handleSendClick = () => {
    setIsModalOpen(true);
  };

  const handleCancelModal = () => {
    setIsModalOpen(false);
    setEmail("");
  };

  const handleSendTerms = async () => {
    if (email.trim() === "") {
      toast.error("Por favor, insira um e-mail válido.");
      return;
    }

    toast.loading("Enviando termos de aceite...");
    try {
      await proposalProvider.sendProposalTerm(
        proposal.id,
        proposal.lead.phone,
        email
      );
      await getProposalDetails();
      toast.dismiss();
      toast.success("E-mail enviado com sucesso!");
    } catch (error) {
      toast.dismiss();
      toast.error("Erro ao enviar e-mail. Tente novamente");
    } finally {
      setIsModalOpen(false);
      setEmail("");
    }
  };


  const handleDeleteDocument = (doc: any) => {
    const updatedDocuments = proposal.documents.filter(d => d !== doc);
    setProposal({ ...proposal, documents: updatedDocuments });
  };

  const showToast = (message: any, type: any) => {
    toast(message, { type });
  };


  const handleAddValue = async (e: any) => {
    e.preventDefault();
    const newItem = {
      value: formData.value,
      type: formData.type,
      date: new Date().toLocaleDateString(),
    };

    try {
      console.log(Formatter.strToNumber(newItem.value));
      await proposalProvider.createPreApprovedValue(
        proposal.id,
        new PreApprovedValue(
          "",
          Formatter.strToNumber(newItem.value),
          new PreApprovedType(newItem.type)
        )
      );
      getProposalDetails();
      setIsModalItemOpen(false);
      toast.success("Valor pré-aprovado adicionado com sucesso!");
    } catch (error) {
      toast.error("Erro ao adicionar valor pré-aprovado.");
    }
  };

  const handleDeletePreApprovedValue = async (item: PreApprovedValue) => {
    toast.loading("Deletando valor pré-aprovado...");
    try {
      await proposalProvider.deletePreApprovedValue(proposal.id, item.id);
      getProposalDetails();
      toast.dismiss();
      toast.success("Valor pré-aprovado deletado com sucesso!");
    } catch (error) {
      toast.dismiss();
      toast.error("Erro ao deletar valor pré-aprovado.");
    }
  };

  const handleCancelModalItem = () => {
    if (!formData.value || !formData.type) {
      toast.info("Não se preocupe, nada foi salvo.");
    }
    setIsModalItemOpen(false);
  };

  const handleCancelModalUpload = () => {
    if (!formData.value || !formData.type) {
      toast.info("Não se preocupe, nada foi salvo.");
    }
    setIsModalUploadOpen(false);
  };

  const handleEdit = () => {
    setTempObservation(observation);
    setIsEditing(true);
  };


  const handleSave = () => {
    setObservation(tempObservation);
    setLastUpdated(new Date().toLocaleString("pt-BR"));
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate("/propostas/visualizar");
  };

  // const handleCancelModalObs = () => {
  //   setIsModalOpenObs(false);
  //   setTempObservation("");
  //   toast.info("Não se preocupe, nenhuma observação foi salva!");
  // };

  const [proposalDocument, setProposalDocument] = useState<File | null>(null);
  const [proposalDocumentTitle, setProposalDocumentTitle] =
    useState<string>("");

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setProposalDocument(e.target.files[0]);
    }
  };

  const AccordionItemDetails: React.FC<{
    title: string;
    children: React.ReactNode;
  }> = ({ title, children }) => {
    const [isOpen, setIsOpen] = React.useState(false);

    return (
      <div className="accordion-item-details">
        <div
          className={`accordion-header-details ${isOpen ? "active" : ""}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>{title}</span>
          <span className={`accordion-icon-details ${isOpen ? "open" : ""}`}>▾</span>
        </div>
        <div
          className={`accordion-content-details ${isOpen ? "active" : ""}`}
        >
          {children}
        </div>
      </div>
    );
  };

  const [projectPlanImage, setProjectPlanImage] = useState<{
    file: File | null;
    progress: number;
    url?: string;
  }>({ file: null, progress: 0 });

  const uploadProjectPlanImage = async (file: File) => {
    try {
      setProjectPlanImage((prevImage) => ({ ...prevImage, progress: 0 }));
      const imageUrl = await uploadImageProvider.upload(file);

      if (imageUrl) {
        setProjectPlanImage((prevImage) => ({
          ...prevImage,
          progress: 100,
          url: imageUrl,
        }));
      } else {
        throw new Error("Erro ao salvar o arquivo.");
      }
    } catch (error) {
      console.error("Erro ao fazer upload:", error);
      toast.error(`Erro ao fazer upload de ${file.name}. Tente novamente.`);
    }
  };


  const MAX_FILE_SIZE_MB = 10;

  const handleProjectPlanFileChange = (files: FileList) => {
    Array.from(files).forEach((file) => {
      if (file.size / 1024 / 1024 > MAX_FILE_SIZE_MB) {
        toast.error(`O arquivo ${file.name} excede o tamanho máximo de 10 MB.`);
        return;
      }

      if (!['application/pdf', 'image/png', 'image/jpeg'].includes(file.type)) {
        toast.error(`Formato de arquivo não suportado: ${file.type}`);
        return;
      }

      setProjectPlanImage({ file, progress: 0 });
      uploadProjectPlanImage(file);
      setProposalDocument(file);
    });
  };


  const handleDropProjectPlanImage = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.dataTransfer.files) {
      handleProjectPlanFileChange(e.dataTransfer.files);
    }
  };

  const handleDeleteProjectPlanImage = () => {
    setProjectPlanImage({ file: null, progress: 0 });
  };

  return (
    <div className="proposal-container-details">
      {/* Header Section */}
      <section className="header-section-details">
        <div className="back-title-details">
          <ChevronLeft
            size={16}
            onClick={handleBackClick}
            className="back-arrow"
            style={{ cursor: "pointer" }}
          />
          <h1>Detalhes da proposta:</h1>
        </div>
        <div className="status-actions-details">
          <select
            value={proposal.status.getFormatted()}
            onChange={(e) => handleChangeStatus(e.target.value)}
          >
            <option value="">{proposal.status.getFormatted()}</option>
            {proposal.status.ALL_STATUS.filter(
              (status) => status !== proposal.status.getStatus()
            ).map((status) => (
              <option key={status} value={status}>
                {proposal.status.proposalStatusMap[status]}
              </option>
            ))}
          </select>
          <Tooltip text="Enviar proposta">
            <button
              className="primary-button-details"
              onClick={handleSendClick}
            >
              <SendIcon />
            </button>
          </Tooltip>
        </div>
      </section>

      {/* Tabs Navigation */}
      <div className="tabs-navigation">
        <button
          className={`tab-button ${activeTab === "dados-pessois" ? "active" : ""}`}
          onClick={() => setActiveTab("dados-pessois")}
        >
          Dados Pessoais
        </button>
        <button
          className={`tab-button ${activeTab === "forma-pagamento" ? "active" : ""}`}
          onClick={() => setActiveTab("forma-pagamento")}
        >
          Forma de pagamento
        </button>
        <button
          className={`tab-button ${activeTab === "imoveis" ? "active" : ""}`}
          onClick={() => setActiveTab("imoveis")}
        >
          Imóvel
        </button>
        <button
          className={`tab-button ${activeTab === "info-add" ? "active" : ""}`}
          onClick={() => setActiveTab("info-add")}
        >
          Informações adicionais
        </button>
        <button
          className={`tab-button ${activeTab === "valores-pre" ? "active" : ""}`}
          onClick={() => setActiveTab("valores-pre")}
        >
          Valores pré-aprovados
        </button>
        <button
          className={`tab-button ${activeTab === "docs" ? "active" : ""}`}
          onClick={() => setActiveTab("docs")}
        >
          Documentos
        </button>
        <button
          className={`tab-button ${activeTab === "historico" ? "active" : ""}`}
          onClick={() => setActiveTab("historico")}
        >
          Histórico
        </button>
        <button
          className={`tab-button ${activeTab === "observations" ? "active" : ""}`}
          onClick={() => setActiveTab("observations")}
        >
          Observações
        </button>
      </div>

      {/* Tabs Content */}
      <div className="tabs-content">
        {/* Personal and Financial Data */}
        {activeTab === "dados-pessois" && (
          <div className="tab-panel">
            {/* <section className="two-columns-details"> */}
            <div className="data-box-details">
              <h2 className="data-box-details-h2">Dados pessoais:</h2>
              <div className="grid-details">
                <div className="form-group-details">
                  <label>Nome:</label>
                  <input type="text" value={proposal.lead.name} readOnly />
                  <div className="divisor-proposal-details"></div>
                </div>
                <div className="form-group-details">
                  <label>Celular:</label>
                  <input type="text" value={proposal.lead.phone} readOnly />
                  <div className="divisor-proposal-details"></div>
                </div>
                <div className="form-group-details">
                  <label>CPF:</label>
                  <input type="text" value={proposal.lead.document} readOnly />
                  <div className="divisor-proposal-details"></div>
                </div>
                <div className="form-group-details">
                  <label>Nome da mãe:</label>
                  <input type="text" value={proposal.lead.motherName} readOnly />
                  <div className="divisor-proposal-details"></div>
                </div>
                <div className="form-group-details">
                  <label>Data de nascimento:</label>
                  <input
                    type="text"
                    value={Formatter.timestampToDateBR(proposal.lead.birthDate)}
                    readOnly
                  />
                </div>
                <div className="form-group-details">
                  <label>E-mail:</label>
                  <input type="text" value={proposal.lead.email} readOnly />
                </div>
              </div>
            </div>

            <div className="data-box-details">
              <h2 className="data-box-details-h2">Informações patrimoniais:</h2>
              <div className="form-group-details">
                <label>Renda mensal:</label>
                <input
                  type="text"
                  value={Formatter.formatNumberToBRCurrency(
                    proposal.lead.monthlyIncome
                  )}
                  readOnly
                />
                <div className="divisor-proposal-details"></div>
              </div>
              <div className="form-group-details">
                <label>Patrimônio:</label>
                <input
                  type="text"
                  value={Formatter.formatNumberToBRCurrency(
                    proposal.lead.patrimony
                  )}
                  readOnly
                />
                <div className="divisor-proposal-details"></div>
              </div>
            </div>
            {/* </section> */}
          </div>
        )}

        {activeTab === "forma-pagamento" && (
          <div className="tab-panel">
            {/* Payment Section */}
            <h2 className="data-box-details-h2">Forma de pagamento:</h2>
            <section className="payment-section-details">
              <div className="data-box-details-full">
                {proposal.paymentSignalMethods.map((signal, index) => (
                  <div className="payment-grid-details" key={index}>
                    <div className="form-group-details">
                      <label className="label">Forma de pagamento:</label>
                      <span>{signal.method.getFormatted()}</span>
                    </div>
                    <div className="form-group-details">
                      <label className="label">Valor:</label>
                      <span>{Formatter.formatNumberToBRCurrency(signal.amount)}</span>
                    </div>
                    <div className="form-group-details">
                      <label className="label">Data do pagamento:</label>
                      <span>{Formatter.timestampToDateBR(signal.date)}</span>
                    </div>
                    <div className="form-group-details">
                      <label className="label">Parcelamento:</label>
                      <span>{signal.installments}x</span>
                    </div>
                    <div className="form-group-details">
                      <label className="label">Titular do cartão:</label>
                      <span>{signal.ownership?.getFormatted()}</span>
                    </div>
                    {signal.ownership?.getMethod() === "third" && (
                      <div className="form-group-details">
                        <label className="label">CPF do terceiro:</label>
                        <span>{signal.thirdPartyDocument}</span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </section>
          </div>
        )}

        {activeTab === "imoveis" && (
          <div className="tab-panel">
            {/* Property Section */}
            <h2 className="data-box-details-h2">Imóvel:</h2>
            <section className="property-section-details">
              <div className="data-box-details-full">
                <div className="property-content-details">
                  <div className="property-info-details">
                    <div className="property-row">
                      <div className="form-group-details">
                        <label>Nome do imóvel:</label>
                        <input
                          type="text"
                          value={proposal.enterprise.name}
                          readOnly
                        />
                      </div>
                      <div className="form-group-details">
                        <label>Valor da Unidade:</label>
                        <input
                          type="text"
                          value={proposal.unitPrice.getValueFormatted()}
                          readOnly
                        />
                      </div>
                      <div className="form-group-details">
                        <label>Unidade escolhida:</label>
                        <input
                          type="text"
                          value={proposal.enterprise.description}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="property-row">
                      <div className="form-group-details">
                        <label>Endereço:</label>
                        <input
                          type="text"
                          value={proposal.enterprise.address.street}
                          readOnly
                        />
                      </div>
                      <div className="form-group-details">
                        <label>Numero:</label>
                        <input
                          type="text"
                          value={proposal.enterprise.address.number}
                          readOnly
                        />
                      </div>
                      <div className="form-group-details">
                        <label>Bairro:</label>
                        <input
                          type="text"
                          value={proposal.enterprise.address.neighborhood}
                          readOnly
                        />
                      </div>
                      <div className="form-group-details">
                        <label>Cidade:</label>
                        <input
                          type="text"
                          value={proposal.enterprise.address.city}
                          readOnly
                        />
                      </div>
                      <div className="form-group-details">
                        <label>Estado:</label>
                        <input
                          type="text"
                          value={proposal.enterprise.address.uf}
                          readOnly
                        />
                      </div>

                    </div>
                  </div>
                  <div className="property-image-details">
                    <img
                      src={proposal.enterprise.images[0]?.path || ""}
                      alt="Imóvel"
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}

        {activeTab === "info-add" && (
          <div className="tab-panel">
            {/* Additional Information */}
            <div className="financial-form">
              <h2 className="data-box-details-h2">Informações adicionais:</h2>
              <AccordionItemDetails title="Informações pessoais">
                <div className="groups">
                  {/* Step 01: CPF */}
                  <p>
                    <strong>CPF:</strong> {proposal.lead.document}
                  </p>
                  <div className="divisor-accordion"></div>
                  {/* Step 02: Dados pessoais */}
                  <p>
                    <strong>Nome:</strong> {proposal.lead.name}
                  </p>
                  <p>
                    <strong>Email:</strong> {proposal.lead.email}
                  </p>
                  <p>
                    <strong>Data de nascimento:</strong>{" "}
                    {Formatter.timestampToDateBR(proposal.lead.birthDate)}
                  </p>
                  <p>
                    <strong>Nome da mãe:</strong> {proposal.lead.motherName}
                  </p>
                  <p>
                    <strong>Celular:</strong> {proposal.lead.phone}
                  </p>
                </div>
              </AccordionItemDetails>
              <AccordionItemDetails title="Renda mensal e patrimônios">
                {/* Step 03: Renda e Patrimonio */}
                <div className="groups">
                  <p>
                    <strong>Renda mensal:</strong>{" "}
                    {Formatter.formatNumberToBRCurrency(proposal.lead.monthlyIncome)}
                  </p>
                  <div className="divisor-accordion"></div>
                  <p>
                    <strong>Patrimonio:</strong>{" "}
                    {Formatter.formatNumberToBRCurrency(proposal.lead.patrimony)}
                  </p>
                </div>
              </AccordionItemDetails>

              <AccordionItemDetails title="Informações de veículos">
                {/* Step 04: Informações de veiculos */}
                <div className="groups">
                  <p>
                    <strong>Possui veículo:</strong>{" "}
                    {proposal.vehicleQuiz ? "Sim" : "Não"}
                  </p>
                  {proposal.vehicleQuiz && proposal.vehicleQuiz.length > 0 && (
                    <>
                      {proposal.vehicleQuiz.map((vehicle, index) => (
                        <div key={index}>
                          <div className="divisor-accordion"></div>
                          <p>
                            <strong>Valor do veículo:</strong>{" "}
                            {Formatter.formatNumberToBRCurrency(vehicle.value)}
                          </p>
                          <p>
                            <strong>Fabricante:</strong> {vehicle.manufacturer}
                          </p>
                          <p>
                            <strong>Modelo:</strong> {vehicle.model}
                          </p>
                          <p>
                            <strong>Ano:</strong> {vehicle.year}
                          </p>
                          <p>
                            <strong>KM:</strong> {vehicle.km}
                          </p>
                          <p>
                            <strong>O veículo é próprio?</strong>{" "}
                            {vehicle.isOwned ? "Sim" : "Não"}
                          </p>
                          {!vehicle.isOwned && (
                            <>
                              <p>
                                <strong>CPF do terceiro:</strong>{" "}
                                {vehicle.thirdPartyDocument}
                              </p>
                            </>
                          )}
                          <p>
                            <strong>
                              Será usado como parte do pagamento do imóvel?
                            </strong>{" "}
                            {vehicle.useAsPayment ? "Sim" : "Não"}
                          </p>
                          <p>
                            <strong>O veículo é financiado?</strong>{" "}
                            {vehicle.isFinanced ? "Sim" : "Não"}
                          </p>
                          <p>
                            <strong>Deseja refinanciar?</strong>{" "}
                            {vehicle.wantsRefinance ? "Sim" : "Não"}
                          </p>
                          {vehicle.wantsRefinance && (
                            <>
                              <p>
                                <strong>Valor do refinanciamento:</strong>{" "}
                                {Formatter.formatNumberToBRCurrency(
                                  vehicle.refinanceValue
                                )}
                              </p>
                            </>
                          )}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </AccordionItemDetails>

              <AccordionItemDetails title="Informações de imóveis">
                {/* Step 05: Informações de imóveis */}
                <div className="groups">
                  <p>
                    <strong>Possui imóvel?</strong>{" "}
                    {proposal.propertyQuiz ? "Sim" : "Não"}
                  </p>
                  {proposal.propertyQuiz.map((property, index) => (
                    <div key={index}>
                      <div className="divisor-accordion"></div>
                      <p>
                        <strong>Valor do imóvel:</strong>{" "}
                        {Formatter.formatNumberToBRCurrency(
                          property.value
                        )}
                      </p>
                      <p>
                        <strong>O imóvel é próprio?</strong>{" "}
                        {property.isOwned ? "Sim" : "Não"}
                      </p>
                      <div className="divisor-accordion"></div>
                      {!property.isOwned && (
                        <>
                          <p>
                            <strong>CPF do terceiro:</strong>{" "}
                            {property.thirdPartyDocument}
                          </p>
                        </>
                      )}
                      <p>
                        <strong>O imóvel é financiado?</strong>{" "}
                        {property.isFinanced ? "Sim" : "Não"}
                      </p>
                      {!property.isFinanced && (
                        <>
                          <p>
                            <strong>Deseja refinanciar?</strong>{" "}
                            {property.wantsRefinance ? "Sim" : "Não"}
                          </p>
                        </>
                      )}

                      <div className="divisor-accordion"></div>
                      {property.wantsRefinance && (
                        <>
                          <p>
                            <strong>Valor do refinanciamento:</strong>{" "}
                            {Formatter.formatNumberToBRCurrency(
                              property.refinanceValue
                            )}
                          </p>
                        </>
                      )}
                      <p>
                        <strong>Será usado como parte do pagamento do imóvel?</strong>{" "}
                        {property.useAsPayment ? "Sim" : "Não"}
                      </p>
                    </div>
                  ))}
                </div>
              </AccordionItemDetails>

              <AccordionItemDetails title="Informações de FGTS">
                {/* Step 06: Informações do FGTS */}
                <div className="groups">
                  <p>
                    <strong>Possui FGTS?</strong> {proposal.fgtsQuiz ? "Sim" : "Não"}
                  </p>
                  {proposal.fgtsQuiz && (
                    <>
                      <div className="divisor-accordion"></div>
                      <p>
                        <strong>Saldo disponível:</strong>{" "}
                        {Formatter.formatNumberToBRCurrency(
                          proposal.fgtsQuiz!.balance
                        )}
                      </p>
                      <p>
                        <strong>Possui saque aniversário?</strong>{" "}
                        {proposal.fgtsQuiz!.hasAnniversaryWithdrawal ? "Sim" : "Não"}
                      </p>
                      {proposal.fgtsQuiz!.hasAnniversaryWithdrawal && (
                        <>
                          <div className="divisor-accordion"></div>
                          <p>
                            <strong>
                              Tem interesse em usar como parte do pagamento?
                            </strong>{" "}
                            {proposal.fgtsQuiz!.useAsPayment ? "Sim" : "Não"}
                          </p>
                          <p>
                            <strong>
                              Irá usar para amortização na entrega das chaves?
                            </strong>{" "}
                            {proposal.fgtsQuiz!.useForAmortization ? "Sim" : "Não"}
                          </p>
                          <div className="divisor-accordion"></div>
                          <p>
                            <strong>
                              Outra pessoa que possa realizar em conjunto essas
                              operações?
                            </strong>{" "}
                            {proposal.fgtsQuiz!.hasWithdrawalTwo
                              ? "Sim"
                              : "Não"}
                          </p>
                          {proposal.fgtsQuiz!.hasWithdrawalTwo && (
                            <>
                              <p>
                                <strong>CPF do terceiro:</strong>{" "}
                                {proposal.fgtsQuiz!.thirdPartyDocument}
                              </p>
                            </>
                          )}
                          {proposal.fgtsQuiz!.hasWithdrawalTwo && (
                            <>
                              <p>
                                <strong>Saldo disponível:</strong>{" "}
                                {Formatter.formatNumberToBRCurrency(
                                  proposal.fgtsQuiz!.balanceTwo
                                )}
                              </p>
                            </>
                          )}

                          <p>
                            <strong>Possui saque aniversário?</strong>{" "}
                            {proposal.fgtsQuiz!.hasAnniversaryWithdrawalTwo
                              ? "Sim"
                              : "Não"}
                          </p>
                          <p>
                            <strong>
                              Tem interesse em usar como parte do pagamento?
                            </strong>{" "}
                            {proposal.fgtsQuiz!.useAsPaymentTwo ? "Sim" : "Não"}
                          </p>
                        </>
                      )}
                    </>
                  )}
                </div>
              </AccordionItemDetails>

              <AccordionItemDetails title="Informações de empréstimo consignado">
                {/* Step 07: Informações de empréstimo consignado */}
                <div className="groups">
                  <p>
                    <strong>Possui empréstimo consignado?</strong>{" "}
                    {proposal.loanQuiz ? "Sim" : "Não"}
                  </p>
                  {proposal.loanQuiz && (
                    <>
                      <div className="divisor-accordion"></div>
                      <p>
                        <strong>É próprio?</strong> {proposal.loanQuiz!.isOwned}
                      </p>
                      <p>
                        <strong>Número da matrícula:</strong>{" "}
                        {proposal.loanQuiz!.registrationNumber}
                      </p>
                      <p>
                        <strong>ID da Averbadora:</strong>{" "}
                        {proposal.loanQuiz!.averbId}
                      </p>
                      <div className="divisor-accordion"></div>
                      <p>
                        <strong>É funcionário público?</strong>{" "}
                        {proposal.loanQuiz!.isPublicServant}
                      </p>
                      {proposal.loanQuiz!.isPublicServant && (
                        <>
                          <p>
                            <strong>Em qual esfera atua?</strong>{" "}
                            {proposal.loanQuiz!.sphereOfAction}
                          </p>
                          <p>
                            <strong>Qual órgão?</strong> {proposal.loanQuiz!.agency}
                          </p>
                        </>
                      )}
                    </>
                  )}
                </div>
              </AccordionItemDetails>

              <AccordionItemDetails title="Informações patrimôniais">
                {/* Step 08: Informações de empréstimo consignado */}
                <div className="groups">
                  <p>
                    <strong>É empresário(a)?</strong>{" "}
                    {proposal.businessQuiz ? "Sim" : "Não"}
                  </p>
                  <div className="divisor-accordion"></div>
                  {proposal.businessQuiz && (
                    <>
                      <p>
                        <strong>CNPJ:</strong> {proposal.businessQuiz.cnpj}
                      </p>
                      <p>
                        <strong>Tipo de empresa:</strong>{" "}
                        {proposal.businessQuiz.companyType}
                      </p>
                      <p>
                        <strong>Faturamento mensal dos últimos 3 meses:</strong>{" "}
                        {Formatter.formatNumberToBRCurrency(
                          proposal.businessQuiz.lastThreeMonthsRevenue
                        )}
                      </p>
                      <p>
                        <strong>Faturamento em cartões:</strong>{" "}
                        {Formatter.formatNumberToBRCurrency(
                          proposal.businessQuiz.cardsRevenue
                        )}
                      </p>
                      <p>
                        <strong>Adquirente atual:</strong>{" "}
                        {proposal.businessQuiz.currentAcquirer}
                      </p>
                      <p>
                        <strong>Taxa de débito:</strong>{" "}
                        {proposal.businessQuiz.debitRate
                          ? Formatter.formatPercentage(
                            proposal.businessQuiz.debitRate
                          )
                          : "0"}
                        %
                      </p>
                      <p>
                        <strong>Taxa de PIX:</strong>{" "}
                        {proposal.businessQuiz.debitRateAtSight
                          ? Formatter.formatPercentage(
                            proposal.businessQuiz.debitRateAtSight
                          )
                          : "0"}
                        %
                      </p>
                      <p>
                        <strong>Taxa de crédito à vista:</strong>{" "}
                        {proposal.businessQuiz.creditRateAtSight
                          ? Formatter.formatPercentage(
                            proposal.businessQuiz.creditRateAtSight
                          )
                          : "0"}
                        %
                      </p>
                      <p>
                        <strong>Taxa de crédito parcelado (2 a 6 parcelas):</strong>{" "}
                        {proposal.businessQuiz.creditRateInstallments
                          ? Formatter.formatPercentage(
                            proposal.businessQuiz.creditRateInstallments
                          )
                          : "0"}
                        $
                      </p>
                      <p>
                        <strong>Taxa de crédito parcelado (7 a 12 parcelas):</strong>{" "}
                        {proposal.businessQuiz.creditRateInstallmentsTwo
                          ? Formatter.formatPercentage(
                            proposal.businessQuiz.creditRateInstallmentsTwo
                          )
                          : "0"}
                        %
                      </p>
                      <div className="divisor-accordion"></div>
                      <p>
                        <strong>Possui algum tipo de endividamento?</strong>{" "}
                        {proposal.businessQuiz.hasDebt ? "Sim" : "Não"}
                      </p>
                      {proposal.businessQuiz.hasDebt && (
                        <>
                          <p>
                            <strong>Valor da dívida:</strong>{" "}
                            {Formatter.formatNumberToBRCurrency(
                              proposal.businessQuiz.debtValue
                            )}
                          </p>
                          <p>
                            <strong>Cheque especial?</strong>{" "}
                            {proposal.businessQuiz.hasSpecialCheck ? "Sim" : "Não"}
                          </p>
                          {proposal.businessQuiz.hasDebt && (
                            <p>
                              <strong>Valor do cheque especial:</strong>{" "}
                              {Formatter.formatNumberToBRCurrency(proposal.businessQuiz.specialCheckValue)}
                            </p>
                          )}
                          <p>
                            <strong>Conta garantida?</strong>{" "}
                            {proposal.businessQuiz.hasGuaranteedAccount
                              ? "Sim"
                              : "Não"}
                          </p>
                          {proposal.businessQuiz.hasGuaranteedAccount && (
                            <p>
                              <strong>Valor da conta garantida:</strong>{" "}
                              {Formatter.formatNumberToBRCurrency(proposal.businessQuiz.guaranteedAccountValue)}
                            </p>
                          )}
                          <p>
                            <strong>Outros empréstimos?</strong>{" "}
                            {proposal.businessQuiz.hasOtherLoans ? "Sim" : "Não"}
                          </p>
                          {proposal.businessQuiz.hasOtherLoans && (
                            <p>
                              <strong>Valor dos outros empréstimos:</strong>{" "}
                              {Formatter.formatNumberToBRCurrency(proposal.businessQuiz.otherLoansValue)}
                            </p>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </AccordionItemDetails>

              <AccordionItemDetails title="Outras informações">
                {/* Step 09: Informações Patrimoniais */}
                <div className="groups">
                  <p>
                    <strong>É profissional liberal?</strong>{" "}
                    {proposal.freelancerQuiz ? "Sim" : "Não"}
                  </p>
                  <div className="divisor-accordion"></div>
                  {proposal.freelancerQuiz && (
                    <>
                      <p>
                        <strong>Profissão:</strong>{" "}
                        {proposal.freelancerQuiz.profession}
                      </p>
                      <p>
                        <strong>Possui algum tipo de endividamento?</strong>{" "}
                        {proposal.freelancerQuiz.hasDebt ? "Sim" : "Não"}
                      </p>

                      {proposal.freelancerQuiz.hasDebt && (
                        <>
                          <p>
                            <strong>Valor da dívida:</strong>{" "}
                            {Formatter.formatNumberToBRCurrency(
                              proposal.freelancerQuiz.debtValue
                            )}
                          </p>
                          <div className="divisor-accordion"></div>
                          <p>
                            <strong>Cheque especial?</strong>{" "}
                            {proposal.freelancerQuiz.hasOverdraft ? "Sim" : "Não"}
                          </p>
                          {proposal.freelancerQuiz.hasOverdraft && (
                            <p>
                              <strong>Valor do cheque especial:</strong>{" "}
                              {Formatter.formatNumberToBRCurrency(proposal.freelancerQuiz.overdraftValue)}
                            </p>
                          )}
                          <p>
                            <strong>Conta garantida?</strong>{" "}
                            {proposal.freelancerQuiz.hasGuaranteedAccount
                              ? "Sim"
                              : "Não"}
                          </p>
                          {proposal.freelancerQuiz.hasGuaranteedAccount && (
                            <p>
                              <strong>Valor da conta garantida:</strong>{" "}
                              {Formatter.formatNumberToBRCurrency(proposal.freelancerQuiz.guaranteedAccountValue)}
                            </p>
                          )}
                          <p>
                            <strong>Outros empréstimos?</strong>{" "}
                            {proposal.freelancerQuiz.hasOthers ? "Sim" : "Não"}
                          </p>
                          {proposal.freelancerQuiz.hasOthers && (
                            <p>
                              <strong>Valor dos outros empréstimos:</strong>{" "}
                              {Formatter.formatNumberToBRCurrency(proposal.freelancerQuiz.othersValue)}
                            </p>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </AccordionItemDetails>
            </div>
          </div>
        )}

        {activeTab === "valores-pre" && (
          <div className="tab-panel">
            <section className="values-section-details">
              <div className="section-header-details">
                <h2 className="data-box-details-h2">Valores pré-aprovados:</h2>
                <button
                  className="add-button-details"
                  onClick={() => setIsModalItemOpen(true)}
                >
                  + Adicionar item
                </button>
              </div>
              <div className="data-box-details-full">
                <table>
                  <thead>
                    <tr>
                      <th>Valor</th>
                      <th>Tipo</th>
                      <th>Data</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {proposal.preApprovedValues.map((item, index) => (
                      <tr key={index}>
                        <td>{Formatter.formatNumberToBRCurrency(item.amount)}</td>
                        <td>{item.type.getFormatted()}</td>
                        <td>{Formatter.timestampToDateBR(item.createdAt)}</td>
                        <td className="actions-details">
                          {/* <button className="icon-button-details">
                      <Edit2 />
                    </button> */}
                          <Tooltip text="Deletar">
                            <button
                              className="icon-button-details"
                              onClick={() => handleDeletePreApprovedValue(item)}
                            >
                              <Trash2 />
                            </button>
                          </Tooltip>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {isModalOpenItem && (
                <div className="modal-overlay-add-item">
                  <div className="modal-add-item">
                    <div className="modal-header-add-item">
                      <h2 className="data-box-details-h2">Adicionar valor pré-aprovado</h2>
                      <button
                        onClick={handleCancelModalItem}
                        className="close-button-add-item"
                      >
                        <X size={20} />
                      </button>
                    </div>
                    <form onSubmit={handleAddValue}>
                      <div className="form-group-add-item">
                        <label>Valor</label>
                        <input
                          type="text"
                          placeholder="R$"
                          value={Formatter.formatStringToBRCurrency(formData.value)}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              value: Formatter.clearSymbols(e.target.value),
                            })
                          }
                        />
                      </div>
                      <div className="divisor-enterprise"></div>
                      <div className="form-group-add-item">
                        <label>Tipo</label>
                        <select
                          value={formData.type}
                          onChange={(e) =>
                            setFormData({ ...formData, type: e.target.value })
                          }
                        >
                          <option value="">Selecione...</option>
                          <option value="cnpj">CNPJ</option>
                          <option value="properties">Imóveis</option>
                          <option value="vehicles">Veículos</option>
                        </select>
                      </div>
                      <div className="modal-buttons-send-email">
                        <button
                          type="button"
                          onClick={handleCancelModalItem}
                          className="cancel-button-send-email"
                        >
                          Cancelar
                        </button>
                        <button type="submit" className="send-button-send-email">
                          Adicionar
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </section>
          </div>
        )}

        {activeTab === "docs" && (
          <div className="tab-panel">
            {/* Documents */}
            <section className="documents-section-details">
              <div className="section-header-details">
                <h2 className="data-box-details-h2">Documentos:</h2>
                <button
                  className="add-button-details"
                  onClick={() => setIsModalUploadOpen(true)}
                >
                  + Adicionar documento
                </button>
              </div>
              <div className="data-box-details-full">
                <table>
                  <thead>
                    <tr>
                      <th>Tipo</th>
                      <th>Data de envio</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {proposal.documents.map((doc, index) => (
                      <tr key={index}>
                        <td>{doc.name}</td>
                        <td>{Formatter.timestampToDateBR(doc.createdAt)}</td>
                        <td className="actions-details">
                          <Tooltip text="Download">
                            <button
                              className="icon-button-details"
                              onClick={() => {
                                openDocument(doc);
                                showToast('Documento baixado com sucesso!', 'success');
                              }}
                            >
                              <Download />
                            </button>
                          </Tooltip>
                          <Tooltip text="Deletar">
                            <button
                              className="icon-button-details"
                              onClick={() => {
                                handleDeleteDocument(doc);
                                showToast('Documento excluído com sucesso!', 'success');
                              }}
                            >
                              <Trash2 />
                            </button>
                          </Tooltip>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {isModalOpenUpload && (
                <div className="modal-overlay-add-item">
                  <div className="modal-add-item">
                    <div className="modal-header-add-item">
                      <h2 className="data-box-details-h2">Adicionar documento:</h2>
                      <button
                        onClick={handleCancelModalUpload}
                        className="close-button-add-item"
                      >
                        <X size={20} />
                      </button>
                    </div>
                    <form onSubmit={handleUploadDocument}>
                      <div className="form-group-add-item">
                        <label>Nome do documento:</label>
                        <input
                          type="text"
                          value={proposalDocumentTitle}
                          onChange={(e) => setProposalDocumentTitle(e.target.value)}
                        />
                      </div>
                      <div className="divisor-enterprise"></div>
                      <div className="form-group-add-item">
                        <h2 className="data-box-details-h2">Upload de Arquivo:</h2>
                        <div
                          className="upload-area"
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={handleDropProjectPlanImage}
                          onClick={() => document.getElementById("file-input")?.click()}
                        >
                          <div className="upload-icon"></div>
                          <Upload size={24} />
                          <p className="upload-text">
                            Arraste e solte seu documento aqui, ou clique para selecionar diretamente do seu dispositivo.
                          </p>
                        </div>

                        <input
                          id="file-input"
                          type="file"
                          accept="application/pdf, image/png, image/jpeg"
                          multiple
                          onChange={(e) => e.target.files && handleProjectPlanFileChange(e.target.files)}
                          style={{ display: "none" }}
                        />


                        <div className="upload-info">
                          <p>Formatos suportados: PDF, PNG e JPEG</p>
                          <p>Tamanho máximo do documento: 10 Mb</p>
                        </div>
                        <div className="divisor"></div>
                        {projectPlanImage.file && (
                          <div className="image-preview-container">
                            <div className="image-preview">
                              <button
                                className="delete-button"
                                onClick={() => handleDeleteProjectPlanImage()}
                              >
                                &times;
                              </button>
                              <div className="details">
                                <div className="image-thumbnail">
                                  <img
                                    src={URL.createObjectURL(projectPlanImage.file)}
                                    alt={`Preview ${projectPlanImage.file.name}`}
                                    className="preview-image"
                                  />
                                </div>
                                <div>

                                  <p className="file-name">
                                    {projectPlanImage.file.name}
                                  </p>
                                  <p className="file-size">
                                    {(projectPlanImage.file.size / 1024 / 1024).toFixed(
                                      2
                                    )}{" "}
                                    Mb
                                  </p>
                                </div>
                              </div>

                              <div className="progress-container">
                                <div className="progress-bar-img">
                                  <div
                                    className="progress-img"
                                    style={{ width: `${projectPlanImage.progress}%` }}
                                  ></div>
                                </div>
                                <span className="progress-percentage">
                                  {projectPlanImage.progress}%
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="divisor-enterprise"></div>
                      <div className="modal-buttons-send-email">
                        <button
                          type="button"
                          onClick={handleCancelModalUpload}
                          className="cancel-button-send-email"
                        >
                          Cancelar
                        </button>
                        <button type="submit" className="send-button-send-email">
                          Adicionar
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </section>
          </div>
        )}

        {activeTab === "historico" && (
          <div className="tab-panel">
            {/* History */}
            <h2 className="data-box-details-h2">Histórico:</h2>
            <section className="history-section-details">
              <div className="history-data-box-details">
                <table>
                  <thead>
                    <tr>
                      <th>Data</th>
                      <th>Horário</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {proposal.history
                      .filter((item) => item.isMain)
                      .map((item, index) => (
                        <tr key={index}>
                          <td>
                            {Formatter.timestampToDateBR(item.createdAt, false)}
                          </td>
                          <td>{Formatter.timestampToTime(item.createdAt)}</td>
                          <td
                            className={`status ${item.description
                              .toLowerCase()
                              .replace(" ", "-")}`}
                          >
                            {item.description}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        )}

        {activeTab === "observations" && (
          <div className="tab-panel">
            {/* Observations */}
            <section className="observations-section-details">
              <div className="section-header-details">
                <h2 className="data-box-details-h2">Observações:</h2>
                <button
                  className="add-button-details"
                  onClick={() => setIsModalOpenObs(true)}
                >
                  + Adicionar observação
                </button>
              </div>

              <div className="data-box-details-full">
                {observations.map((observation, index) => (
                  <div key={index} className="observation-item-details">
                    <div className="observation-content-details">
                      <p>{observation.body}</p>
                      <small>
                        Observação feita em: {new Date().toLocaleString("pt-BR")}
                      </small>
                    </div>
                    <Tooltip text="Deletar">
                      <button
                        className="icon-button-details-edit"
                        onClick={() => handleDeleteObservation(index)}
                      >
                        <Trash2 />
                      </button>
                    </Tooltip>
                  </div>
                ))}
              </div>

              {isModalOpenObs && (
                <div className="modal-obs">
                  <div className="modal-obs-content">
                    <h2 className="data-box-details-h2">Escreva sua observação:</h2>
                    <textarea
                      value={tempObservation}
                      onChange={(e) => setTempObservation(e.target.value)}
                      className="modal-obs-textarea"
                      placeholder="Digite aqui..."
                    ></textarea>
                    <div className="divisor-enterprise"></div>
                    <div className="modal-obs-actions">
                      <button onClick={handleCancelModalObs}>Cancelar</button>
                      <button onClick={handleAddObservation}>Adicionar</button>
                    </div>
                    {/* Ícone de fechar */}
                    <div className="modal-close" onClick={handleCancelModalObs}></div>
                  </div>
                </div>
              )}
            </section>
          </div>
        )}

        {isModalOpen && (
          <div className="modal-overlay-send-email">
            <div className="modal-content-send-email">
              <h2>Deseja enviar um e-mail para o cliente?</h2>
              <p>O cliente receberá um e-mail contendo todas as informações da proposta.<br>
              </br>Confira se o endereço de e-mail abaixo está correto e clique no botão para enviar.</p>
              <input
                type="email"
                placeholder="Digite um email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="email-input-send-email"
              />
              <div className="modal-buttons-send-email">
                <button
                  onClick={handleCancelModal}
                  className="cancel-button-send-email"
                >
                  Cancelar
                </button>
                <button
                  onClick={handleSendTerms}
                  className="send-button-send-email"
                >
                  Sim, enviar
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <ToastContainer position="top-right" />
    </div>
  );
}

export default ProposalDetails;
