import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/components/_empreedimentos.scss";
import Tooltip from "../tootip/Tootip";
import { FilterX, Pencil, CheckCircle, XCircle } from "lucide-react";
import { Link } from 'react-router-dom';
import Loading from '../Loading';
import { ToastContainer, toast } from 'react-toastify';
import ConstructionCompanyProvider from "../../infra/provider/constructionCompanyProvider";
import ZipCode from "../../domain/entities/valueObjects/zipCode";
import ConstructionCompany from "../../domain/entities/constructionCompany";
import { Address } from "../../domain/entities/address";
import InputMask from "react-input-mask";

interface Incorporadora {
    id: string;
    createdAt: string;
    cnpj: string;
    razaoSocial: string;
    responsibleName: string;
    email: string;
    phone: string;
    cpf: string;
    address: Address;
    status: "active" | "inactive";
}

const Incorporadoras = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [incorporadoras, setIncorporadoras] = useState<Incorporadora[]>([]);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedIncorporadora, setSelectedIncorporadora] = useState<Incorporadora | null>(null);
    const [editForm, setEditForm] = useState<Incorporadora | null>(null);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const constructionCompanyProvider = ConstructionCompanyProvider.getInstance();

    const getConstructionCompanies = useCallback(async () => {
        setIsLoading(true);

        const storedData = await constructionCompanyProvider.getConstructionCompaniesAsync();
        if (storedData && storedData.length > 0) {
            const updatedData = storedData.map((company) => ({
                id: company.id,
                createdAt: company.createdAt,
                cnpj: company.document,
                razaoSocial: company.name,
                responsibleName: company.responsibleName,
                email: company.email,
                phone: company.phone,
                cpf: company.responsibleDocument,
                address: company.address,
                status: company.status as "active" | "inactive"
            }));
            setIncorporadoras(updatedData);
        }
        setIsLoading(false);
    }, [constructionCompanyProvider]);

    useEffect(() => {
        getConstructionCompanies();
    }, [getConstructionCompanies]);

    useEffect(() => {
        if (selectedIncorporadora) {
            setEditForm(selectedIncorporadora);
        }
    }, [selectedIncorporadora]);

    const handleSearchTermChange = (term: string) => {
        setSearchTerm(term);
    };

    const handleClearFilters = () => {
        setSearchTerm("");
    };

    const handleEdit = (incorporadora: Incorporadora) => {
        setSelectedIncorporadora(incorporadora);
        setIsEditModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsEditModalOpen(false);
        setSelectedIncorporadora(null);
        setEditForm(null);
    };

    const handleInputChange = (field: string, value: string, isAddress = false) => {
        if (!editForm) return;

        if (isAddress) {
            setEditForm({
                ...editForm,
                address: {
                    ...editForm.address,
                    [field]: value
                }
            });
        } else {
            setEditForm({
                ...editForm,
                [field]: value
            });
        }
    };

    const handleUpdate = async () => {
        if (!editForm) return;

        try {
            const updatedCompany = new ConstructionCompany(
                editForm.id,
                editForm.razaoSocial,
                editForm.cnpj,
                editForm.cpf,
                editForm.responsibleName,
                editForm.email,
                editForm.phone,
                editForm.address,
                editForm.createdAt,
                editForm.status
            );
            await constructionCompanyProvider.updateConstructionCompany(updatedCompany);
            showToast("Incorporadora editada com sucesso!", "success");
            await getConstructionCompanies();
        } catch (error) {
            showToast("Não foi possivel editar a Incorporadora!", "error");
        }
        finally {
            setIsLoading(false);
            handleCloseModal();
        }
    };

    const showToast = (message: string, type: "success" | "error") => {
        if (type === "success") {
            toast.success(message);
        } else {
            toast.error(message);
        }
    };

    const handleToggleStatus = async (id: string, currentStatus: "active" | "inactive") => {
        try {
            const newStatus = currentStatus === "active" ? "inactive" : "active" as "active" | "inactive";
            await constructionCompanyProvider.updateConstructionCompanyStatus(id, newStatus);
            showToast(
                newStatus === "active"
                    ? "Incorporadora ativada com sucesso!"
                    : "Incorporadora desativada com sucesso!",
                "success"
            );
        } catch (error) {
            showToast("Não foi possivel alterar o status", "error");
        }
        finally {
            await getConstructionCompanies();
        }
    };

    const filteredIncorporadoras = incorporadoras.filter(inc =>
        inc.razaoSocial.toLowerCase().includes(searchTerm.toLowerCase()) ||
        inc.cnpj.includes(searchTerm) ||
        inc.id.includes(searchTerm) ||
        inc.responsibleName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const formatDate = (dateString: string) => {
        if (dateString === "") return "";

        return new Date(Number(dateString) * 1000).toLocaleDateString('pt-BR');
    };

    const formatCNPJ = (cnpj: string) => {
        if (cnpj === "") return "";
        return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
    };

    const formatPhoneNumber = (phone: string) => {
        const cleaned = ('' + phone).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
        return phone;
    };

    const handleCepBlur = () => {
        const cep = editForm?.address?.zipCode?.toString();
        if (cep?.length === 9) {
            fetch(`https://viacep.com.br/ws/${cep.replace(/\D/g, "")}/json/`)
                .then(response => response.json())
                .then(data => {
                    if (!data.erro) {
                        setEditForm(prevForm => prevForm ? ({
                            ...prevForm,
                            address: {
                                ...prevForm.address,
                                city: data.localidade,
                                uf: data.uf,
                                neighborhood: data.bairro,
                                street: data.logradouro,
                                zipCode: data.cep,
                                number: ''
                            }
                        }) : null);
                    } else {
                        toast.error("CEP inválido!");
                    }
                })
                .catch(() => toast.error("Erro ao buscar CEP!"));
        }
    };

    return (
        <div className="data-table-container">
            <button
                className="add-button-plan-viab"
                onClick={() => navigate("/incorporadoras/novo")}
            >
                + Criar nova incorporadora
            </button>
            <div className="filters-container">
                <div className="search-container">
                    <input
                        type="text"
                        placeholder="Busque por um nome, CPF, ID etc..."
                        value={searchTerm}
                        onChange={(e) => handleSearchTermChange(e.target.value)}
                    />
                </div>
                <Tooltip text="Limpar filtros">
                    <button className="clear-filters-button-enterprise" onClick={handleClearFilters}>
                        <FilterX size={16} />
                    </button>
                </Tooltip>
            </div>

            <div className="table-wrapper">
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Data de criação</th>
                            <th>CNPJ</th>
                            <th>Razão Social</th>
                            <th>Nome do responsável</th>
                            <th>Email</th>
                            <th>Telefone</th>
                            <th>Status</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredIncorporadoras?.length > 0 && filteredIncorporadoras.map((inc) => (
                            <tr key={inc.id} className={inc.status === "inactive" ? 'inactive-row' : ''}>
                                <td>{inc.id}</td>
                                <td>{formatDate(inc.createdAt)}</td>
                                <td>{formatCNPJ(inc.cnpj)}</td>
                                <td>{inc.razaoSocial}</td>
                                <td>{inc.responsibleName}</td>
                                <td>{inc.email}</td>
                                <td>{formatPhoneNumber(inc.phone)}</td>
                                <td>{inc.status === "active" ? 'Ativo' : 'Inativo'}</td>
                                <td>
                                    <div className="action-buttons">
                                        <Tooltip text="Editar">
                                            <button className="icon-button" onClick={() => handleEdit(inc)}>
                                                <Pencil size={20} />
                                            </button>
                                        </Tooltip>
                                        {inc.status === "active" ? (
                                            <Tooltip text="Inativar">
                                                <button
                                                    onClick={() => handleToggleStatus(inc.id, inc.status)}
                                                    className="action-button deactivate-button"
                                                >
                                                    <XCircle size={20} />
                                                </button>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip text="Ativar">
                                                <button
                                                    onClick={() => handleToggleStatus(inc.id, inc.status)}
                                                    className="action-button activate-button"
                                                >
                                                    <CheckCircle size={20} />
                                                </button>
                                            </Tooltip>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Modal de Edição */}
            {/* Modal de Edição */}
            {isEditModalOpen && editForm && (
                <div className="modal-overlay-planta">
                    <div className="modal-planta-popup">
                        <div className="modal-header-planta">
                            <h2 className="data-box-details-title-h2">Editar Incorporadora</h2>
                            <button
                                className="close-button-planta"
                                onClick={handleCloseModal}
                            >
                                ✕
                            </button>
                        </div>
                        <p>Edite os dados abaixo para atualizar a incorporadora.</p>
                        <div className="divisor-enterprise"></div>
                        <form className="modal-form-planta" onSubmit={handleUpdate}>
                            <div className="form-row-planta">
                                <div>
                                    <label>CNPJ:</label>
                                    <InputMask
                                        mask="99.999.999/9999-99"
                                        type="text"
                                        value={editForm.cnpj}
                                        onChange={(e) => handleInputChange('cnpj', e.target.value)}
                                        placeholder="Digite o CNPJ"
                                        disabled
                                    />
                                </div>
                                <div>
                                    <label>Razão Social:</label>
                                    <input
                                        type="text"
                                        value={editForm.razaoSocial}
                                        onChange={(e) => handleInputChange('razaoSocial', e.target.value)}
                                        placeholder="Digite a razão social"
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="divisor-enterprise"></div>
                            <div className="form-row-planta">
                                <div>
                                    <label>Nome do Responsável:</label>
                                    <input
                                        type="text"
                                        value={editForm.responsibleName}
                                        onChange={(e) => handleInputChange('responsibleName', e.target.value)}
                                        placeholder="Digite o nome do responsável"
                                    />
                                </div>
                                <div>
                                    <label>CPF:</label>
                                    <InputMask
                                        mask="999.999.999-99"
                                        type="text"
                                        value={editForm.cpf}
                                        onChange={(e) => handleInputChange('cpf', e.target.value)}
                                        placeholder="Digite o CPF"
                                    />
                                </div>
                            </div>
                            <div className="divisor-enterprise"></div>
                            <div className="form-row-planta">
                                <div>
                                    <label>Telefone/WhatsApp:</label>
                                    <InputMask
                                        mask="(99) 99999-9999"
                                        type="text"
                                        value={editForm.phone}
                                        onChange={(e) => handleInputChange('phone', e.target.value)}
                                        placeholder="Digite o telefone"
                                    />
                                </div>
                                <div>
                                    <label>Email corporativo:</label>
                                    <input
                                        type="email"
                                        value={editForm.email}
                                        onChange={(e) => handleInputChange('email', e.target.value)}
                                        placeholder="Digite o e-mail"
                                    />
                                </div>
                            </div>
                            <div className="divisor-enterprise"></div>

                            <div className="form-row-planta">
                                <div>
                                    <label>CEP:</label>
                                    <InputMask
                                        mask="99999-999"
                                        type="text"
                                        placeholder="CEP"
                                        value={editForm.address.zipCode.toString()}
                                        onChange={(e) => handleInputChange('zipCode', e.target.value, true)}
                                        onBlur={handleCepBlur}
                                    />
                                </div>
                            </div>
                            <div className="form-row-planta">
                                <div>
                                    <label>Rua:</label>
                                    <input
                                        type="text"
                                        placeholder="Rua"
                                        value={editForm.address.street}
                                        onChange={(e) => handleInputChange('street', e.target.value, true)}
                                    />
                                </div>
                                <div>
                                    <label>Número:</label>
                                    <input
                                        type="text"
                                        placeholder="Número"
                                        value={editForm.address.number}
                                        onChange={(e) => handleInputChange('number', e.target.value, true)}
                                    />
                                </div>
                            </div>
                            <div className="form-row-planta">
                                <div>
                                    <label>Bairro:</label>
                                    <input
                                        type="text"
                                        placeholder=""
                                        value={editForm.address.neighborhood}
                                        onChange={(e) => handleInputChange('neighborhood', e.target.value, true)}
                                    />
                                </div>
                                <div>
                                    <label>Cidade:</label>
                                    <input
                                        type="text"
                                        placeholder="Cidade"
                                        value={editForm.address.city}
                                        onChange={(e) => handleInputChange('city', e.target.value, true)}
                                    />
                                </div>
                            </div>
                            <div className="form-row-planta">
                                <div>
                                    <label>UF:</label>
                                    <input
                                        type="text"
                                        placeholder="UF"
                                        value={editForm.address.uf}
                                        onChange={(e) => handleInputChange('uf', e.target.value, true)}
                                    />
                                </div>
                                <div>
                                    <label>Complemento:</label>
                                    <input
                                        type="text"
                                        placeholder="Complemento"
                                        value={editForm.address.complement}
                                        onChange={(e) => handleInputChange('complement', e.target.value, true)}
                                    />
                                </div>
                            </div>
                            <div className="divisor-enterprise"></div>
                            <div className="form-buttons-planta">
                                <div className="modal-actions-plan-viab">
                                    <button>Cancelar</button>
                                    <button
                                        type="submit"
                                        disabled={isLoading}
                                        className={`${'primary-plan-viab'} ${isLoading ? 'loading' : ''}`}
                                    >
                                        {isLoading ? (
                                            <>
                                                <div className="spinner"></div>
                                                <span>Salvando...</span>
                                            </>
                                        ) : (
                                            "Salvar alterações"
                                        )}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            <ToastContainer />

        </div>
    );
};

export default Incorporadoras;