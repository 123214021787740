import PriceHistory from "./valueObjects/priceHistory";
import UnitStatus from "./valueObjects/unitStatus";

export default class Unit {
  constructor(
    public id = "",
    public quantity = 0,
    public price = 0,
    public description = "",
    public status: UnitStatus = new UnitStatus("unknown"), // Aqui está a instância padrão
    public projectPlanId = "",
    public priceHistory: PriceHistory[] = []
  ) {}
}
