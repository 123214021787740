import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/components/_leads.scss';
import LeadProvider from '../../infra/provider/leadProvider';
import { ListSearch } from '../../domain/entities/valueObjects/listSearch';
import { toast } from 'react-toastify';
import { Formatter } from '../../utils/formatter';
import { debounce } from 'lodash';
import Tooltip from '../tootip/Tootip';
import { FilterX } from "lucide-react";

interface TableData {
  id: string;
  createdAt: string;
  name: string;
  document: string;
  email: string;
  phone: string;
  city: string;
  uf: string;
  street: string;
  monthlyIncome: string;
  patrimony: string;
}

const Leads = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [data, setData] = useState<TableData[]>([]);
  const isFetching = useRef(false);
  const leadProvider = LeadProvider.getInstance();

  const getLeads = useCallback(
    async (
      pageNumber = 1,
      searchTerm = "",
      selectedDate = "",
    ) => {
      if (isFetching.current) return;
      isFetching.current = true;
      const search = new ListSearch({
        searchTerm: searchTerm,
        orderBy: "createdAt",
        limit: itemsPerPage,
        offset: (pageNumber - 1) * itemsPerPage,
        fromDate: selectedDate ? Formatter.dateStringToTimestamp(selectedDate) : undefined,
        toDate: selectedDate ? Formatter.dateStringToTimestamp(selectedDate) : undefined,
      });
      try {
        const response =
          await leadProvider.getLeads(search);
        const mappedData = response.leads.map((lead) => ({
          id: lead.id,
          createdAt: Formatter.timestampToDateBR(lead.createdAt),
          name: lead.name,
          document: lead.document,
          email: lead.email,
          phone: lead.phone,
          city: lead.address.city,
          uf: lead.address.uf,
          street: lead.address.street,
          monthlyIncome: Formatter.formatNumberToBRCurrency(lead.monthlyIncome),
          patrimony: Formatter.formatNumberToBRCurrency(lead.patrimony),

        }));
        setData(mappedData);
        setTotalItems(response.totalItems);
      } catch (error) {
        toast.error("Erro ao carregar visitas agendadas");
        console.error(error);
      } finally {
        isFetching.current = false;
      }
    },
    [leadProvider, itemsPerPage]
  );

  useEffect(() => {
    getLeads(currentPage);
  }, [itemsPerPage, currentPage, getLeads]);

  const clearFilters = () => {
    if (searchTerm === "" && selectedDate === "") return;
    setSearchTerm('');
    setSelectedDate('');
    setCurrentPage(1);
    getLeads(1, "", "");
  };

  const formatPhone = (phone: string): string => {
    const cleaned = phone.replace(/\D/g, '');
    if (cleaned.length === 13) {
      return `+${cleaned.slice(0, 2)} (${cleaned.slice(2, 4)}) ${cleaned.slice(4, 8)}-${cleaned.slice(8)}`;
    } else if (cleaned.length === 11) {
      return `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 7)}-${cleaned.slice(7)}`;
    }
    return phone;
  };


  const debouncedSearchTerm = useRef(
    debounce((term) => {
      getLeads(1, term, selectedDate);
    }, 600)
  ).current;

  const debouncedDate = useRef(
    debounce((date) => {
      getLeads(1, searchTerm, date);
    }, 600)
  ).current;

  const handleSearchTermChange = (searchTerm: string) => {
    setSearchTerm(searchTerm);
    debouncedSearchTerm(searchTerm);
  };

  const handleSearchDateChange = (date: string) => {
    setSelectedDate(date);
    debouncedDate(date);
  };

  return (
    <div className="data-table-container-leads">
      <div className="filters-container-leads">
        <div className="search-container-leads">
          <input
            type="text"
            placeholder="Busque por nome, CPF, ID, etc..."
            value={searchTerm}
            onChange={(e) => handleSearchTermChange(e.target.value)}
          />

        </div>
        <input
          type="date"
          value={selectedDate}
          onChange={(e) => handleSearchDateChange(e.target.value)}
          className="date-input-leads"
        />
        <Tooltip text="Limpar filtros">
          <button className="clear-filters-button-enterprise" onClick={clearFilters}>
            <FilterX size={16} />
          </button>
        </Tooltip>
      </div>

      <div className="table-wrapper-leads">
        <table className="modern-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Data de criação</th>
              <th>Nome</th>
              <th>Documento</th>
              <th>Email</th>
              <th>Telefone</th>
              <th>Endereço</th>
              <th>Renda mensal</th>
              <th>Patrimônio</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index} className="table-row">
                <td>{item.id}</td>
                <td>{item.createdAt}</td>
                <td>{item.name}</td>
                <td>{item.document}</td>
                <td>{item.email}</td>
                <td>{formatPhone(item.phone)}</td>
                <td>{`${item.street}, ${item.city} - ${item.uf}`}</td>
                <td>{item.monthlyIncome}</td>
                <td>{item.patrimony}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="table-footer-leads">
        <div className="items-per-page-leads">
          <span>Exibir por</span>
          <select
            value={itemsPerPage}
            onChange={(e) => {
              setItemsPerPage(Number(e.target.value));
              setCurrentPage(1);
            }}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
          </select>
        </div>
        <div className="pagination-leads">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            Anterior
          </button>
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <button
              key={page}
              onClick={() => setCurrentPage(page)}
              className={currentPage === page ? 'active' : ''}
            >
              {page}
            </button>
          ))}
          <button
            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
          >
            Próximo
          </button>
        </div>
      </div>
    </div>

  );
};

export default Leads;
