import React, { useEffect, useState } from 'react';
import { Line, Doughnut, Bar } from 'react-chartjs-2';
import "../../styles/components/_home.scss";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
} from 'chart.js';
import { DollarSign, Users, TrendingUp, Eye, Target, BarChart2, Wallet } from 'lucide-react';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement
);

type Period = 'year' | 'month' | 'week';

function Home() {
  const [selectedPeriod, setSelectedPeriod] = useState<Period>('year');
  const [metrics, setMetrics] = useState({
    vendas: 0,
    empreendimentos: 0,
    corretores: 0,
    visitas: 0,
    leads: 0,
    bouncerate: 0,
    valor: 0
  });

  const periodData = {
    year: {
      labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      data: [4500, 3200, 3400, 2900, 4200, 3300, 1900, 2700, 3500, 4100, 2300, 3600],
      metrics: {
        vendas: 132,
        empreendimentos: 51,
        corretores: 65,
        visitas: 2120,
        leads: 201,
        bouncerate: 75,
        valor: 925
      }
    },
    month: {
      labels: ['Semana 1', 'Semana 2', 'Semana 3', 'Semana 4'],
      data: [1200, 1400, 1100, 1600],
      metrics: {
        vendas: 42,
        empreendimentos: 15,
        corretores: 22,
        visitas: 850,
        leads: 85,
        bouncerate: 68,
        valor: 320
      }
    },
    week: {
      labels: ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb', 'Dom'],
      data: [250, 320, 280, 350, 400, 200, 150],
      metrics: {
        vendas: 12,
        empreendimentos: 5,
        corretores: 8,
        visitas: 320,
        leads: 25,
        bouncerate: 72,
        valor: 85
      }
    }
  };

  useEffect(() => {
    const animateValue = (start: number, end: number, duration: number, setValue: (value: number) => void) => {
      const startTime = performance.now();

      const update = (currentTime: number) => {
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1);

        setValue(Math.floor(start + (end - start) * progress));

        if (progress < 1) {
          requestAnimationFrame(update);
        }
      };

      requestAnimationFrame(update);
    };

    const currentMetrics = periodData[selectedPeriod].metrics;
    Object.entries(currentMetrics).forEach(([key, value]) => {
      animateValue(0, value, 2000, (newValue) => {
        setMetrics(prev => ({ ...prev, [key]: newValue }));
      });
    });
  }, [selectedPeriod]);

  const monthlyData = {
    labels: periodData[selectedPeriod].labels,
    datasets: [{
      label: 'Desempenho',
      data: periodData[selectedPeriod].data,
      fill: false,
      borderColor: '#192158',
      tension: 0.4
    }]
  };

  const categoryData = {
    labels: ['Residencial', 'Comercial', 'Industrial', 'Rural', 'Terrenos'],
    datasets: [{
      data: [35, 25, 20, 12, 8],
      backgroundColor: [
        '#192158',
        '#2A3E7B',
        '#3A4D9E',
        '#4A5DBF',
        '#10163A',
      ]
    }]
  };

  const channelData = {
    labels: ['Pendente', 'Aceito', 'Em analise', 'Rejeitada'],
    datasets: [{
      label: 'Desempenho por Canal',
      data: [65, 85, 55, 45],
      backgroundColor: '#192158',
    }]
  };

  return (
    <div className="dashboard-container">
      <header className="dashboard-header">
        <div className="period-selector">
          <button
            className={selectedPeriod === 'year' ? 'active' : ''}
            onClick={() => setSelectedPeriod('year')}
          >
            Ano
          </button>
          <button
            className={selectedPeriod === 'month' ? 'active' : ''}
            onClick={() => setSelectedPeriod('month')}
          >
            Mês
          </button>
          <button
            className={selectedPeriod === 'week' ? 'active' : ''}
            onClick={() => setSelectedPeriod('week')}
          >
            Semana
          </button>
        </div>
      </header>

      <div className="metrics-grid">
        <div className="metric-card">
          <div className="metric-icon sales">
            <DollarSign size={24} />
          </div>
          <div className="metric-content">
            <h3>{metrics.vendas}</h3>
            <p>Vendas mensais</p>
            <span className="trend positive">+9,21%</span>
          </div>
        </div>

        <div className="metric-card">
          <div className="metric-icon projects">
            <Users size={24} />
          </div>
          <div className="metric-content">
            <h3>{metrics.empreendimentos}</h3>
            <p>Empreendimentos</p>
            <span className="trend positive">+2,43%</span>
          </div>
        </div>

        <div className="metric-card">
          <div className="metric-icon agents">
            <TrendingUp size={24} />
          </div>
          <div className="metric-content">
            <h3>{metrics.corretores}</h3>
            <p>Produção dos corretores</p>
            <span className="trend positive">+3,23%</span>
          </div>
        </div>

        <div className="metric-card">
          <div className="metric-icon leads">
            <Eye size={24} />
          </div>
          <div className="metric-content">
            <h3>{metrics.leads}</h3>
            <p>Leads</p>
            <span className="trend positive">+9,22%</span>
          </div>
        </div>

        <div className="metric-card">
          <div className="metric-icon visits">
            <Target size={24} />
          </div>
          <div className="metric-content">
            <h3>{metrics.visitas}</h3>
            <p>Visitas</p>
            <span className="trend negative">-3,21%</span>
          </div>
        </div>

        {/* <div className="metric-card">
          <div className="metric-icon bounce">
            <BarChart2 size={24} />
          </div>
          <div className="metric-content">
            <h3>{metrics.bouncerate}%</h3>
            <p>Taxa de rejeição</p>
            <span className="trend negative">-2,12%</span>
          </div>
        </div> */}

        <div className="metric-card">
          <div className="metric-icon revenue">
            <Wallet size={24} />
          </div>
          <div className="metric-content">
            <h3>R$ {metrics.valor}k</h3>
            <p>Valor de vendas</p>
            <span className="trend positive">+9,34%</span>
          </div>
        </div>
      </div>

      <div className="charts-grid">
        <div className="chart-card">
          <h3>Tendências de desempenho</h3>
          <Line data={monthlyData} options={{
            responsive: true,
            plugins: {
              legend: {
                display: false
              }
            },
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }} />
        </div>

        <div className="chart-card">
          <h3>Distribuição por categoria</h3>
          <Doughnut data={categoryData} options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'right' as const
              }
            }
          }} />
        </div>

        <div className="chart-card">
          <h3>Status das propostas</h3>
          <Bar data={channelData} options={{
            indexAxis: 'y' as const,
            responsive: true,
            plugins: {
              legend: {
                display: false
              }
            }
          }} />
        </div>
      </div>
    </div>
  );
}

export default Home;