import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/components/_createcorresponding.scss";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EnterpriseProvider from "../../infra/provider/enterpriseProvider";
import Enterprise from "../../domain/entities/enterprise";
import CorrespondingEntity from "../../domain/entities/correspondingEntity";
import ReactInputMask from "react-input-mask";
import UserProvider from "../../infra/provider/userProvider";
import { Formatter } from "../../utils/formatter";

const CreateUsers = () => {
    useEffect(() => {
        getEnterprises();
    }, []);

    const initialCorrespondingState = {
        id: "",
        createdAt: "",
        namePersonResponsible: "",
        email: "",
        documentType: "",
        documentName: "",
        companyName: "",
        userType: "",
        linkedVentures: [],
        status: "",
        actions: ""
    };

    const userProvider = UserProvider.getInstance();

    const getEnterprises = async () => {
        if (isFetchEnterprise.current)
            return;

        isFetchEnterprise.current = true;
        const enterprises = await enterpriseProvider.getEnterprisesName();
        setEnterprises(enterprises);
        isFetchEnterprise.current = false;
    };

    const totalSteps = 2;
    const enterpriseProvider = EnterpriseProvider.getInstance();
    const [enterprises, setEnterprises] = useState<Enterprise[]>([]);
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const isFetchEnterprise = useRef(false);
    const formRef = useRef<HTMLFormElement>(null);
    const progressPercentage = (step / totalSteps) * 100;
    const [corresponding, setCorresponding] = useState<CorrespondingEntity>(initialCorrespondingState);
    const navigate = useNavigate();

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        nextStep();
    };

    const nextStep = async () => {
        if (step < totalSteps) setStep(step + 1);
    };

    const prevStep = () => {
        if (step > 1) setStep(step - 1);
    };

    const mapUserType = (userType: string) => {
        switch (userType.toLowerCase()) {
            case "correspondente":
                return "correspondent";
            case "suporte":
                return "support";
            case "assistente":
                return "assistant";
            case "admin":
                return "admin";
            default:
                return userType;
        }
    };

    const handleFinalize = async () => {
        setLoading(true);
        try {
            const formattedCorresponding = {
                ...corresponding,
                documentName: Formatter.clearString(corresponding.documentName),
                userType: mapUserType(corresponding.userType),
            };

            setCorresponding(formattedCorresponding);
            await userProvider.createUser(formattedCorresponding);
            toast.success("Usuário criado com sucesso!");
            navigate('/usuarios/visualizar');
        } catch (error) {
            toast.error("Não foi possivel criar o usuário.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div
            className={`form-container-proposta ${step === 3 ? "step-3-active" : ""}`}>
            <div className="form-header">
                <button className="back-button-arrow" onClick={prevStep}>
                    &lt;
                </button>
                <span className="step-indicator">
                    {step}/{totalSteps}
                </span>
            </div>
            <div className="progress-bar">
                <div
                    className="progress-bar-fill"
                    style={{ width: `${progressPercentage}%` }}
                ></div>
            </div>

            <form ref={formRef} onSubmit={handleSubmit}>
                {step === 1 && (
                    <>
                        <h2>Preencha as informações abaixo:</h2>
                        <div className="form-group">
                            <label>Tipo de usuário:</label>
                            <select
                                value={corresponding.userType}
                                onChange={(e) =>
                                    setCorresponding((prevState) => ({
                                        ...prevState,
                                        userType: e.target.value,
                                    }))
                                }
                                required>
                                <option value="">Escolha uma opção</option>
                                <option value="suporte">Suporte</option>
                                <option value="admin">Admin</option>
                                <option value="assistente">Assistente</option>
                                <option value="correspondente">Correspondente</option>
                            </select>
                            <div className="divisor"></div>
                        </div>
                    </>
                )}

                {step === 2 && (
                    <>
                        <h2>Preencha as informações pessoais:</h2>
                        <div className="form-group">
                            <label>Nome do responsável:</label>
                            <input
                                type="text"
                                placeholder="Digite o nome do responsável"
                                required
                                value={corresponding.namePersonResponsible}
                                onChange={(e) => setCorresponding(prevState => ({ ...prevState, namePersonResponsible: e.target.value }))}
                            />
                            <div className="divisor"></div>
                        </div>

                        <div className="form-group">
                            <label>Login (email):</label>
                            <input
                                type="email"
                                placeholder="Digite o email"
                                required
                                value={corresponding.email}
                                onChange={(e) => setCorresponding(prevState => ({ ...prevState, email: e.target.value }))}
                            />
                            <div className="divisor"></div>
                            <div className="form-group">
                                <label>Selecione o documento:</label>
                                <div className="radio-group">
                                    <label>
                                        <input
                                            type="radio"
                                            name="tipoDocumento"
                                            value="cpf"
                                            required
                                            checked={corresponding.documentType === "cpf"}
                                            onChange={(e) => setCorresponding({ ...corresponding, documentType: e.target.value })}
                                        />
                                        CPF
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            name="tipoDocumento"
                                            value="cnpj"
                                            required
                                            checked={corresponding.documentType === "cnpj"}
                                            onChange={(e) => setCorresponding({ ...corresponding, documentType: e.target.value })}
                                        />
                                        CNPJ
                                    </label>
                                </div>
                                <div className="divisor"></div>
                                <label>Digite o documento selecionado acima:</label>
                                {corresponding.documentType === "cpf" ? (
                                    <ReactInputMask
                                        mask="999.999.999-99"
                                        value={corresponding.documentName}
                                        onChange={(e) => setCorresponding({ ...corresponding, documentName: e.target.value })}
                                    >
                                        {(inputProps) => <input {...inputProps} type="text" placeholder="Digite o CPF" required />}
                                    </ReactInputMask>
                                ) : (
                                    <ReactInputMask
                                        mask="99.999.999/9999-99"
                                        value={corresponding.documentName}
                                        onChange={(e) => setCorresponding({ ...corresponding, documentName: e.target.value })}
                                    >
                                        {(inputProps) => <input {...inputProps} type="text" placeholder="Digite o CNPJ" required />}
                                    </ReactInputMask>
                                )}
                                <div className="divisor"></div>
                                {corresponding.userType === "correspondente" && (
                                    <div className="form-group">
                                        <label>Nome da empresa:</label>
                                        <input
                                            type="text"
                                            placeholder="Digite o nome da empresa"
                                            required
                                            value={corresponding.companyName}
                                            onChange={(e) => setCorresponding(prevState => ({ ...prevState, companyName: e.target.value }))}
                                        />
                                        <div className="divisor"></div>
                                    </div>
                                )}

                                {corresponding.userType === "correspondente" && (
                                    <div className="form-group">
                                        <label>Empreendimentos vinculados:</label>
                                        <select
                                            required
                                            value={corresponding.linkedVentures[0]}
                                            onChange={(e) =>
                                                setCorresponding((prevState) => ({
                                                    ...prevState,
                                                    linkedVentures: [Number(e.target.value)],
                                                }))
                                            }
                                        >
                                            <option value="" disabled>Selecione um empreendimento:</option>
                                            {enterprises.map((enterprise) => (
                                                <option key={enterprise.id} value={enterprise.id}>
                                                    {enterprise.name}
                                                </option>
                                            ))}
                                        </select>
                                        <div className="divisor"></div>
                                        <div className="form-group">
                                            <label>Senha:</label>
                                            <input
                                                type="password"
                                                placeholder="************"
                                                required
                                                disabled
                                            />
                                            <p className="corresponding-message-pass-obs">
                                                Atenção:
                                            </p>
                                            <p className="corresponding-message-pass">
                                                Será gerada automaticamente e enviada por e-mail. Ao realizar o primeiro acesso, será solicitado que você troque a senha para garantir a segurança de sua conta.
                                            </p>
                                            <div className="divisor"></div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </>
                )}

                <div className="form-footer">
                    {step !== 2 ? (
                        <>
                            <button type="button" className="back-button" onClick={prevStep}>
                                Voltar
                            </button>
                            <button type="submit" className="next-button" disabled={loading}>
                                {loading ? "Carregando..." : "Avançar"}
                            </button>
                        </>
                    ) : (
                        <>
                            <button type="button" className="back-button" onClick={prevStep}>
                                Voltar
                            </button>
                            <button type="button" className="next-button" disabled={loading} onClick={handleFinalize}>
                                {loading ? "Carregando..." : "Finalizar"}
                            </button>
                        </>
                    )}
                </div>
            </form>
            < ToastContainer />
        </div >
    );
};

export default CreateUsers;
