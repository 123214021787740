import React, { useCallback, useEffect, useState } from 'react';
import { Construction, Download, Upload, NotebookPen, CheckCircle2, X, MapPinHouse } from 'lucide-react';
import '../styles/components/_docsenterprise.scss';
import { ToastContainer, toast } from "react-toastify";
import Unit from "../domain/entities/unit";
import Tooltip from './tootip/Tootip';
import EnterpriseProvider from '../infra/provider/enterpriseProvider';
import EnterpriseDocuments from '../domain/entities/valueObjects/enterpriseDocuments';
import constructionCompanyProvider from '../infra/provider/constructionCompanyProvider';
import enterpriseProvider from '../infra/provider/enterpriseProvider';
interface Enterprise {
    id: number;
    name: string;
}
interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: (observation: string) => void;
    currentObservation: string;
}
interface AddDocumentModalProps {
    isOpen: boolean;
    onClose: () => void;
    onAdd: (name: string) => void;
    selectedEnterprise: string;
}

const AddDocumentModal: React.FC<AddDocumentModalProps> = ({ isOpen, onClose, onAdd, selectedEnterprise }) => {
    const [documentName, setDocumentName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [currentDoc, setCurrentDoc] = useState('');
    const enterpriseProvider = EnterpriseProvider.getInstance();

    if (!isOpen) return null;

    const handleSubmit = async () => {
        handleCleanDocuments();
        setIsLoading(true);
        try {
            await enterpriseProvider.postEnterpriseDocumentById(selectedEnterprise, 'OT', documentName, currentDoc);
            toast.success("Documento adicionado com sucesso!");
        } catch (error) {
            toast.error("Erro ao adicionar documento.");
        }
        finally {
            onClose();
            setIsLoading(false);
        }
    };

    const handleUpload = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.onchange = async (e) => {
            const file = (e.target as HTMLInputElement).files?.[0];
            if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64String = reader.result?.toString().split(',')[1];
                    if (base64String) {
                        setCurrentDoc(base64String);
                        toast.success("Documento enviado com sucesso!");
                    }
                };
                reader.readAsDataURL(file);
            }
        };
        input.click();

        console.log(currentDoc);
    };

    const handleCleanDocuments = () => {
        setDocumentName('');
        setCurrentDoc('');
    }

    return (
        <div className="modal-overlay-unidade">
            <div className="modal-unidade">
                <div className="modal-header-unidade">
                    <h2>Adicionar novo documento</h2>
                    <button className="close-button-unidade" onClick={onClose}>✕</button>
                </div>
                <p>Digite o nome do novo documento.</p>
                <div className="divisor-enterprise"></div>
                <div className='form-group'>
                    <input
                        value={documentName}
                        onChange={(e) => setDocumentName(e.target.value)}
                        placeholder="Nome do documento"
                    />
                    <button
                        onClick={() => handleUpload()}
                        className="upload-button-incorp"
                        title="Upload Documento"
                    >
                        <Upload size={10} />
                        <span className="upload-button-text"> Upload do documento</span>
                    </button>
                </div>

                <div className="form-buttons-unidade">
                    <button
                        type="button"
                        className="cancel-button-unidade"
                        onClick={onClose}
                    >
                        Cancelar
                    </button>
                    <button
                        type="submit"
                        className={`create-button-unidade ${isLoading ? "loading" : ""}`}
                        disabled={isLoading || !documentName.trim()}
                        onClick={handleSubmit}
                    >
                        {isLoading ? "Salvando..." : "Adicionar"}
                    </button>
                </div>
            </div>
        </div>
    );
};

const ObservationModal: React.FC<ModalProps> = ({ isOpen, onClose, onSave, currentObservation }) => {
    const [observation, setObservation] = useState(currentObservation);
    const [isLoading, setIsLoading] = useState(false);

    if (!isOpen) return null;

    const handleSave = () => {
        setIsLoading(true);
        onSave(observation);
        setIsLoading(false);
        onClose();
    };

    return (
        <div className="modal-overlay-unidade">
            <div className="modal-unidade">
                <div className="modal-header-unidade">
                    <h2>Adicionar observação:</h2>
                    <button className="close-button-unidade" onClick={onClose}>✕</button>
                </div>
                <p>Adicione uma observação para justificar a invalidação do documento.</p>
                <div className="divisor-enterprise"></div>
                <div className='form-group'>
                    <input
                        value={observation}
                        onChange={(e) => setObservation(e.target.value)}
                        placeholder="Digite o motivo da invalidação do documento."
                    />
                </div>

                <div className="form-buttons-unidade">
                    <button
                        type="button"
                        className="cancel-button-unidade"
                        onClick={onClose}
                    >
                        Cancelar
                    </button>
                    <button
                        type="submit"
                        className={`create-button-unidade ${isLoading ? "loading" : ""}`}
                        disabled={isLoading}
                        onClick={handleSave}
                    >
                        {isLoading ? "Salvando..." : "Salvar"}
                    </button>
                </div>
            </div>
        </div>
    );
};

function DocsEnterprises() {
    const [selectedEnterprise, setSelectedEnterprise] = useState<string>('');
    const [activeTab, setActiveTab] = useState<string>('tab1');
    const [modalOpen, setModalOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [selectedDoc, setSelectedDoc] = useState<EnterpriseDocuments | null>(null);
    const [documents, setDocuments] = useState<{
        preAnalise: EnterpriseDocuments[];
        anexos: EnterpriseDocuments[];
        condicoesHabilit: EnterpriseDocuments[];
        liberacaoParcela: EnterpriseDocuments[];
        recebimentoUltimaParcela: EnterpriseDocuments[];
        outros: EnterpriseDocuments[];
    }>({
        preAnalise: [],
        anexos: [],
        condicoesHabilit: [],
        liberacaoParcela: [],
        recebimentoUltimaParcela: [],
        outros: []
    });
    const enterpriseProvider = EnterpriseProvider.getInstance();
    const [enterprises, setEnterprises] = useState<Enterprise[]>([]);

    const getEnterprises = useCallback(
        async () => {
            const response = await enterpriseProvider.getAllEnterprises();
            setEnterprises(response as unknown as Enterprise[]);
        },
        [enterpriseProvider]
    );

    const getEnterpriseDocuments = async () => {
        handleCleanDocuments();
        if (selectedEnterprise) {
            try {
                const response = await enterpriseProvider.getEnterpriseDocuments(selectedEnterprise);

                const preAnalise = response.filter(doc => doc.code.includes('PA'));
                const anexos = response.filter(doc => doc.code.includes('ACF'));
                const condicoesHabilit = response.filter(doc => doc.code.includes('CH'));
                const liberacaoParcela = response.filter(doc => doc.code.includes('CLP'));
                const recebimentoUltimaParcela = response.filter(doc => doc.code.includes('CRUP'));
                const outros = response.filter(doc => !doc.code.includes('PA') && !doc.code.includes('ACF') && !doc.code.includes('CH') && !doc.code.includes('CLP') && !doc.code.includes('CRUP'));

                setDocuments({
                    preAnalise: preAnalise,
                    anexos: anexos,
                    condicoesHabilit: condicoesHabilit,
                    liberacaoParcela: liberacaoParcela,
                    recebimentoUltimaParcela: recebimentoUltimaParcela,
                    outros: outros
                });
                toast.success('Documentos do empreendimento carregados com sucesso!');
            } catch (error) {
                toast.error('Erro ao buscar documentos do empreendimento:');
            }
        }
    };

    useEffect(() => {
        getEnterprises();
    }, [getEnterprises]);

    const handleEnterpriseChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        handleCleanDocuments();
        const enterpriseId = e.target.value;
        setSelectedEnterprise(enterpriseId);
        try {
            const response = await enterpriseProvider.getEnterpriseDocuments(enterpriseId);

            const preAnalise = response.filter(doc => doc.code.includes('PA'));
            const anexos = response.filter(doc => doc.code.includes('ACF'));
            const condicoesHabilit = response.filter(doc => doc.code.includes('CH'));
            const liberacaoParcela = response.filter(doc => doc.code.includes('CLP'));
            const recebimentoUltimaParcela = response.filter(doc => doc.code.includes('CRUP'));
            const outros = response.filter(doc => !doc.code.includes('PA') && !doc.code.includes('ACF') && !doc.code.includes('CH') && !doc.code.includes('CLP') && !doc.code.includes('CRUP'));

            setDocuments({
                preAnalise: preAnalise,
                anexos: anexos,
                condicoesHabilit: condicoesHabilit,
                liberacaoParcela: liberacaoParcela,
                recebimentoUltimaParcela: recebimentoUltimaParcela,
                outros: outros
            });
            toast.success('Documentos do empreendimento carregados com sucesso!');
        } catch (error) {
            toast.error('Erro ao buscar documentos do empreendimento:');
        }
    };

    const handleCleanDocuments = () => {
        setDocuments({
            preAnalise: [],
            anexos: [],
            condicoesHabilit: [],
            liberacaoParcela: [],
            recebimentoUltimaParcela: [],
            outros: []
        });
    };

    const translateStatus = (status: EnterpriseDocuments['status']) => {
        switch (status) {
            case 'Não enviado':
                return 'not_sent';
            case 'Enviado':
                return 'sent';
            case 'Aprovado':
                return 'approved';
            case 'Reprovado':
                return 'reproved';
            default:
                return 'unknown';
        }
    };

    const handleStatusChange = async (documentId: number, newStatus: EnterpriseDocuments['status']) => {
        try {
            const activeDocuments = getActiveDocuments().find(doc => doc.id === documentId);
            const documentCode = activeDocuments?.code;
            const file = activeDocuments?.path;
            if (file === null) {
                toast.info("O documento precisa ser enviado antes de atualizar o status.");
                return;
            }
            if (documentCode)
                await enterpriseProvider.putEnterpriseDocumentById(selectedEnterprise, documentCode, translateStatus(newStatus));

            toast.success("Status do documento atualizado com sucesso!");
        } catch (error) {
            toast.error("Não foi possivel atualizar o status.");
        }
    };

    const handleDownload = (documentId: number) => {
        const link = document.createElement('a');
        const activeDocuments = getActiveDocuments().find(doc => doc.id === documentId);

        if (activeDocuments?.path) {
            link.href = activeDocuments?.path;
            link.download = activeDocuments?.path;
            link.click();
            toast.success("Documento baixado com sucesso!");
        } else {
            toast.error("Não existe um documento para download.");
        }
    };

    const handleUpload = (documentId: number) => {
        const input = document.createElement('input');
        input.type = 'file';
        input.onchange = (e) => {
            const file = (e.target as HTMLInputElement).files?.[0];
            if (file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64String = reader.result?.toString().split(',')[1];
                    if (base64String) {
                        handleSubmit(documentId, base64String);
                    }
                };
                reader.readAsDataURL(file);
            }
        };
        input.click();
    };

    const handleSubmit = async (documentId: number, base64String: string) => {
        try {
            const document = getActiveDocuments().find(doc => doc.id === documentId);
            if (!document) return;

            await enterpriseProvider.postEnterpriseDocumentById(selectedEnterprise, document.code, document.name, base64String);
            toast.success("Documento adicionado com sucesso!");
        } catch (error) {
            toast.error("Erro ao adicionar documento.");
        }
        finally {
            await handleCleanDocuments();
            await getEnterpriseDocuments();
        }
    };

    const handleObservationClick = (doc: EnterpriseDocuments) => {
        if (doc.status === 'Reprovado') {
            setSelectedDoc(doc);
            setModalOpen(true);
        }
    };

    const handleAddDocument = (name: string) => {
        const newDoc: EnterpriseDocuments = {
            id: Date.now(),
            code: 'PA',
            description: 'Teste',
            name,
            status: 'Não enviado',
            uploaded: false
        };
        setDocuments(prev => ({
            ...prev,
            outros: [...prev.outros, newDoc]
        }));
        toast.success("Documento adicionado com sucesso!");
    };

    const saveObservation = (observation: string) => {
        if (selectedDoc) {
            const activeDocuments = getActiveDocuments();
            const newDocs = activeDocuments.map(doc =>
                doc.id === selectedDoc.id ? { ...doc, observation } : doc
            );
            updateDocuments(newDocs);
            setModalOpen(false);
        }
    };

    const getActiveDocuments = () => {
        switch (activeTab) {
            case 'tab1': return documents.preAnalise;
            case 'tab2': return documents.anexos;
            case 'tab3': return documents.condicoesHabilit;
            case 'tab4': return documents.liberacaoParcela;
            case 'tab5': return documents.recebimentoUltimaParcela;
            case 'tab6': return documents.outros;
            default: return [];
        }
    };

    const updateDocuments = (newDocs: EnterpriseDocuments[]) => {
        setDocuments(prev => {
            const newState = { ...prev };
            switch (activeTab) {
                case 'tab1': newState.preAnalise = newDocs; break;
                case 'tab2': newState.anexos = newDocs; break;
                case 'tab3': newState.condicoesHabilit = newDocs; break;
                case 'tab4': newState.liberacaoParcela = newDocs; break;
                case 'tab5': newState.recebimentoUltimaParcela = newDocs; break;
                case 'tab6': newState.outros = newDocs; break;
            }
            return newState;
        });
    };

    const renderTable = (documents: EnterpriseDocuments[]) => (
        <>
            {activeTab === 'tab6' && (
                <div className="add-document-button-container" style={{ marginBottom: '1rem', textAlign: 'right' }}>
                    <button
                        onClick={() => setAddModalOpen(true)}
                        className="add-button-plan-viab">
                        + Adicionar documento
                    </button>
                </div>
            )}
            <table className="docs-table-docs">
                <thead>
                    <tr>
                        <th>Situação</th>
                        <th>Nome do Documento</th>
                        <th>Status</th>
                        <th>Observação</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {documents.map((doc) => (
                        <tr key={doc.id}>
                            <td>
                                <div className="icon-name-docs">
                                    {doc.uploaded ? (
                                        <CheckCircle2 size={20} className="check-icon-docs" />
                                    ) : (
                                        <X size={20} className="x-icon-docs" />
                                    )}
                                </div>

                            </td>
                            <td className="document-name-docs">
                                {doc.description}
                            </td>
                            <td>
                                <select
                                    value={doc.status}
                                    onChange={(e) => handleStatusChange(doc.id, e.target.value as EnterpriseDocuments['status'])}
                                    className="status-select-docs"
                                >
                                    <option value="Não enviado">Não enviado</option>
                                    <option value="Enviado">Enviado</option>
                                    <option value="Aprovado">Aprovado</option>
                                    <option value="Reprovado">Reprovado</option>
                                    <option value="Não se aplica">Não se aplica</option>
                                </select>
                            </td>
                            <td className="observation-cell-docs">
                                {doc.observation || '-'}
                            </td>
                            <td className="actions-cell-docs">
                                <Tooltip text="Upload">
                                    <button
                                        onClick={() => handleUpload(doc.id)}
                                        className="action-button-docs"
                                        title="Upload"
                                    >
                                        <Upload size={20} />
                                    </button>
                                </Tooltip>
                                <Tooltip text="Download"> <button
                                    onClick={() => handleDownload(doc.id)}
                                    className="action-button-docs"
                                    title="Download"
                                >
                                    <Download size={20} />
                                </button>
                                </Tooltip>
                                <Tooltip text="Observação">
                                    <button
                                        onClick={() => handleObservationClick(doc)}
                                        className={`action-button-docs ${doc.status !== 'Reprovado' ? 'disabled' : ''}`}
                                        disabled={doc.status !== 'Reprovado'}
                                        title="Observação"
                                    >
                                        <NotebookPen size={20} />
                                    </button>
                                </Tooltip>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );

    return (
        <div className="container-docsenterprises-docs">
            <div className="select-container-docs">
                <label htmlFor="enterprise-select" className="select-label-docs">
                    Selecione um empreendimento para visualizar os documentos:
                </label>
                <select
                    id="enterprise-select-docs"
                    className="enterprise-select-docs"
                    value={selectedEnterprise}
                    onChange={(e) => handleEnterpriseChange(e)}
                >
                    <option value="" disabled selected>Selecione um empreendimento</option>
                    {enterprises.map((enterprise) => (
                        <option key={enterprise.id} value={enterprise.id}>
                            {enterprise.name}
                        </option>
                    ))}
                </select>
            </div>

            {
                !selectedEnterprise ? (
                    <div className="empty-state-docs">
                        <MapPinHouse size={150} className="empty-icon-docs" />
                        <p className="empty-text-docs">
                            Selecione um empreendimento para visualizar os resultados.
                        </p>
                    </div>
                ) : (
                    <>
                        <div className="tabs-navigation">
                            <button
                                className={`tab-button ${activeTab === 'tab1' ? 'active' : ''}`}
                                onClick={() => setActiveTab('tab1')}
                            >
                                Pré-análise
                            </button>
                            <button
                                className={`tab-button ${activeTab === 'tab2' ? 'active' : ''}`}
                                onClick={() => setActiveTab('tab2')}
                            >
                                Anexos ao contrato
                            </button>
                            <button
                                className={`tab-button ${activeTab === 'tab3' ? 'active' : ''}`}
                                onClick={() => setActiveTab('tab3')}
                            >
                                Documentos de condições de habilitação
                            </button>
                            <button
                                className={`tab-button ${activeTab === 'tab4' ? 'active' : ''}`}
                                onClick={() => setActiveTab('tab4')}
                            >
                                Documentos de condições para liberação de parcelas
                            </button>
                            <button
                                className={`tab-button ${activeTab === 'tab5' ? 'active' : ''}`}
                                onClick={() => setActiveTab('tab5')}
                            >
                                Documentos de condições para recebimento da ultima parcela
                            </button>
                            <button
                                className={`tab-button ${activeTab === 'tab6' ? 'active' : ''}`}
                                onClick={() => setActiveTab('tab6')}
                            >
                                Outros
                            </button>
                        </div>
                        {renderTable(getActiveDocuments())}
                    </>
                )
            }

            <ObservationModal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                onSave={saveObservation}
                currentObservation={selectedDoc?.observation || ''}
            />

            <AddDocumentModal
                isOpen={addModalOpen}
                onClose={() => setAddModalOpen(false)}
                onAdd={handleAddDocument}
                selectedEnterprise={selectedEnterprise}
            />

            <ToastContainer />
        </div >
    );
}

export default DocsEnterprises;