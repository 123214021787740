const BASE_URL = process.env.REACT_APP_BASE_URL;

export default class ApiRoutes {
  // Auth
  static signup = `${BASE_URL}/signup`;
  static signin = `${BASE_URL}/signin`;
  static forgotPassword = `${BASE_URL}/forgot`;
  static resetPassword = `${BASE_URL}/reset-password`;
  static changePassword = `${BASE_URL}/change-password`;
  static refreshToken = `${BASE_URL}/refresh-token`;

  // Common questions - FAQ
  static commonQuestions = `${BASE_URL}/common-question`;

  // User
  static user = `${BASE_URL}/user`;
  static me = `${BASE_URL}/user/me`;
  static mePhoto = `${BASE_URL}/user/me/photo`;

  // Api Key
  static apiKey = `${BASE_URL}/user/api-key`;

  // Customer
  static customer = `${BASE_URL}/customer`;

  // Enterprise categories
  static category = `${BASE_URL}/category`;

  // Enterprise release types
  static releaseType = `${BASE_URL}/release-type`;

  // Enterprise construction companies
  static constructionCompany = `${BASE_URL}/construction-company`;
  static constructionCompanyStatus = `${BASE_URL}/construction-company/{id}/update-status`;
  static constructionCompanyDocument = `${BASE_URL}/construction-company/{id}/document`;

  // Leads
  static lead = `${BASE_URL}/lead`;
  static leadCreateUser = `${BASE_URL}/lead/{id}/create-user`;

  // Enterprises
  static enterprise = `${BASE_URL}/enterprise`;
  static enterpriseSearch = `${BASE_URL}/enterprise/search`;
  static enterpriseStatusUpdate = `${BASE_URL}/enterprise/{id}/update-status`;
  static enterpriseUnitPercentage = `${BASE_URL}/enterprise/{id}/unit/update-value`;
  static enterpriseReleaseTypeUpdate = `${BASE_URL}/enterprise/{id}/update-release-type`;
  static enterpriseConstructionStep = `${BASE_URL}/enterprise/{id}/construction-step`;
  static enterpriseConstructionSubStep = `${BASE_URL}/step/{constructionStepId}`;
  static enterpriseImage = `${BASE_URL}/enterprise/{id}/image`;
  static enterpriseCompanyDocument = `${BASE_URL}/enterprise/{id}/document`;

  // Project Plans
  static projectPlan = `${BASE_URL}/project-plan`;
  static projectPlanImage = `${BASE_URL}/project-plan/{id}/image`;
  static enterpriseProjectPlan = `${BASE_URL}/enterprise/{id}/project-plan`;

  static unit = `${BASE_URL}/unit`;
  static enterpriseUnit = `${BASE_URL}/enterprise/{id}/unit`;
  static unitUpdateStatus = `${BASE_URL}/unit/{id}/update-status`;

  // Proposals
  static proposal = `${BASE_URL}/proposal`;
  static sendProposalTerm = `${BASE_URL}/proposal/{id}/term/send`;
  static updateProposalStatus = `${BASE_URL}/proposal/{id}/status`;
  static preApprovedValue = `${BASE_URL}/proposal/{id}/pre-approved-values`;
  static proposalDocument = `${BASE_URL}/proposal/{id}/document`;
  static proposalSumary = `${BASE_URL}/proposal/{id}/sumarry`;
  static proposalPreApprovedSales = `${BASE_URL}/proposal/{id}/pre-approved-sales`;
  static proposalProposals = `${BASE_URL}/proposal/{id}/proposals`;

  //Spreadsheet
  static spreadsheet = `${BASE_URL}/spreadsheet`;

  static proposalObservation = `${BASE_URL}/proposal/{id}/observation`;

  // Data user
  static dataUser = `${BASE_URL}/data-user`;

  // Tenant
  static tenant = `${BASE_URL}/tenant`;

  // Schedule Visit
  static scheduleVisit = `${BASE_URL}/schedule-visit`;

  // category
  static propertyCategory = `${BASE_URL}/category`;

  // Uploader
  static uploader =
    process.env.REACT_APP_UPLOADER_URL || "https://uploader.homecorp.com.br";
}
