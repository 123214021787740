import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/components/_corresponding.scss";
import EnterpriseProvider from "../../infra/provider/enterpriseProvider";
import { ListSearch } from "../../domain/entities/valueObjects/listSearch";
import { debounce } from "lodash";
import { Formatter } from "../../utils/formatter";
import { ToastContainer, toast } from "react-toastify";
import { Edit2, CheckCircle, XCircle, FilterX, KeyRound } from "lucide-react";
import Tooltip from "../tootip/Tootip";
import CorrespondingEntity from "../../domain/entities/correspondingEntity";
import Enterprise from "../../domain/entities/enterprise";
import UserProvider from "../../infra/provider/userProvider";

const Users = () => {
    const initialCorrespondingState = {
        id: "",
        createdAt: "",
        namePersonResponsible: "",
        email: "",
        documentType: "",
        documentName: "",
        companyName: "",
        userType: "",
        linkedVentures: [],
        status: "",
        actions: ""
    };

    const [searchTerm, setSearchTerm] = useState("");
    const [selectedStatus, setSelectedStatus] = useState("");
    const [selectedDate, setSelectedDate] = useState("");
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const isFetching = useRef(false);
    const [dateRangeStart, setDateRangeStart] = useState("");
    const [dateRangeEnd, setDateRangeEnd] = useState("");
    const enterpriseProvider = EnterpriseProvider.getInstance();
    const [isLoading, setIsLoading] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
    const [selectedData, setSelectedData] = useState<CorrespondingEntity | null>(null);
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [repeatNewPassword, setRepeatNewPassword] = useState("");
    const [error, setError] = useState("");
    const [enterprises, setEnterprises] = useState<Enterprise[]>([]);
    const [data, setData] = useState<CorrespondingEntity[]>([]);
    const userProvider = UserProvider.getInstance();

    const getUsers = useCallback(async () => {
        try {
            const response = await userProvider.getUsers();
            setData(response);
        } catch (error) {
            toast.error("Erro ao buscar propostas");
        }
    }, [userProvider]);

    useEffect(() => {
        getUsers();
    }, [getUsers]);

    const [corresponding, setCorresponding] = useState<CorrespondingEntity>(initialCorrespondingState);

    const getEnterprises = useCallback(
        async (
            pageNumber = 1,
            searchTerm = "",
            selectedStatus = "",
            startDate?: string,
            endDate?: string
        ) => {
            if (isFetching.current) return;
            isFetching.current = true;

            const search = new ListSearch({
                searchTerm: searchTerm,
                status: selectedStatus,
                orderBy: "createdAt",
                limit: itemsPerPage,
                offset: (pageNumber - 1) * itemsPerPage,
                fromDate: startDate ? Formatter.dateStringToTimestamp(startDate) : undefined,
                toDate: endDate ? Formatter.dateStringToTimestamp(endDate) : undefined,
            });
            const response = await enterpriseProvider.getEnterprises(search);
        },
        [itemsPerPage, enterpriseProvider]
    );

    // useEffect(() => {
    //     getEnterprises(currentPage, searchTerm, selectedDate, selectedStatus);
    //     getUsers();
    // }, [itemsPerPage, currentPage, getEnterprises, searchTerm, selectedDate, selectedStatus, getUsers]);

    const handleFiltersChange = (
        searchTerm: string,
        status: string,
    ) => {
        getEnterprises(1, searchTerm, selectedDate, status);
    };

    const handleSavePassword = () => {
        if (newPassword !== repeatNewPassword) {
            setError("As senhas novas não coincidem.");
            toast.error("As senhas novas não coincidem.");
            return;
        }

        setError("");
        toast.success("Senha alterada com sucesso!");
        setIsPasswordModalOpen(false);
        setCurrentPassword("");
        setNewPassword("");
        setRepeatNewPassword("");
    };

    const handleCancel = () => {
        toast.info("Não se preocupe, sua senha não foi alterada.");
        setIsPasswordModalOpen(false);
        setCurrentPassword("");
        setNewPassword("");
        setRepeatNewPassword("");
    };


    const handleActivate = (id: string) => {
        setData((prevData) =>
            prevData.map((item) =>
                item.id === id ? { ...item, status: "Ativo" } : item
            )
        );
        toast.success(`Correspondente ativado com sucesso!`);
    };

    const mapStatus = (status: string) => {
        switch (status.toLowerCase()) {
            case "active":
                return "inactive";
            case "inactive":
                return "active";
            default:
                return status;
        }
    };

    const handleDeactivate = async (id: string, status: string) => {
        setIsLoading(true);
        try {
            await userProvider.updateUserStatus(id, mapStatus(status));
            toast.success(`Status atualizado com sucesso!`);
            setIsEditModalOpen(false);
        } catch (error) {
            console.error(error);
            toast.error("Erro ao atualizar status.");
        } finally {
            setIsEditModalOpen(false);
            setIsLoading(false);
            getUsers();
        }
    };

    const debouncedSearchTerm = useRef(
        debounce((term: string) => {
            getEnterprises(1, term, `${dateRangeStart},${dateRangeEnd}`, selectedStatus);
        }, 600)
    ).current;

    const handleSearchTermChange = (term: string) => {
        setSearchTerm(term);
        debouncedSearchTerm(term);
    };

    const handleClearFilters = () => {
        setSearchTerm("");
        setSelectedStatus("");
        setDateRangeStart("");
        setDateRangeEnd("");
        setCurrentPage(1);
        getEnterprises(1, "", "", "");
    };

    const handleEdit = (id: string) => {
        const item = data.find((item) => item.id === id);
        if (item) {
            setSelectedData(item);
            setIsEditModalOpen(true);  // Abre o modal de edição
        }
    };

    const handlePasswordChange = () => {
        setIsPasswordModalOpen(true);  // Use setIsPasswordModalOpen to open the modal
    };

    const mapUserType = (userType: string) => {
        switch (userType.toLowerCase()) {
            case "correspondente":
                return "correspondent";
            case "suporte":
                return "support";
            case "assistente":
                return "assistant";
            case "admin":
                return "admin";
            default:
                return userType;
        }
    };

    const handleUpdateUser = async () => {
        setIsLoading(true);
        try {
            const data = new CorrespondingEntity();
            data.id = selectedData!.id;
            data.createdAt = selectedData!.createdAt;
            data.namePersonResponsible = selectedData!.namePersonResponsible;
            data.email = selectedData!.email;
            data.documentType = selectedData!.documentType;
            data.documentName = Formatter.clearString(selectedData!.documentName);
            data.companyName = selectedData!.companyName;
            data.userType = mapUserType(selectedData!.userType);
            data.linkedVentures = selectedData!.linkedVentures;
            data.status = selectedData!.status;
            data.actions = selectedData!.actions;

            await userProvider.updateUser(data);
            toast.success(`Usuário atualizado com sucesso!`);
            setIsEditModalOpen(false);
        } catch (error) {
            console.error(error);
            toast.error("Erro ao atualizar usuário");
        } finally {
            setIsEditModalOpen(false);
            setIsLoading(false);
        }
    };

    const translateUserType = (userType: string) => {
        switch (userType.toLowerCase()) {
            case "correspondent":
                return "Correspondente";
            case "support":
                return "Suporte";
            case "assistant":
                return "Assistente";
            case "admin":
                return "Admin";
            default:
                return userType;
        }
    };
    const translateStatus = (status: string) => {
        switch (status.toLowerCase()) {
            case "active":
                return "Ativo";
            case "inactive":
                return "Inativo";
            default:
                return status;
        }
    };

    const totalPages = Math.ceil(totalItems / itemsPerPage);

    return (
        <div className="data-table-container">
            <button
                className="add-button-plan-viab"
                onClick={() => navigate("/usuarios/novo")}>
                + Criar novo usuário
            </button>
            <div className="filters-container">
                <div className="search-container">
                    <input
                        type="text"
                        placeholder="Busque por um nome, ID ou status."
                        value={searchTerm}
                        onChange={(e) => handleSearchTermChange(e.target.value)}
                    />
                </div>
                <Tooltip text="Limpar filtros">
                    <button className="clear-filters-button-enterprise" onClick={handleClearFilters}>
                        <FilterX size={16} />
                    </button>
                </Tooltip>
            </div>

            <div className="table-wrapper">
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Data do cadastro</th>
                            {/* <th>Nome do responsável</th> */}
                            <th>Email</th>
                            {/* <th>Documento</th> */}
                            {/* <th>Nome da empresa</th> */}
                            <th>Tipo de usuário</th>
                            {/* <th>Empreendimentos vinculados</th> */}
                            <th>Status</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((item, index) => (
                            <tr
                                key={index}>
                                <td>{item.id}</td>
                                <td>{item.createdAt}</td>
                                {/* <td>{item.namePersonResponsible}</td> */}
                                <td>{item.email}</td>
                                {/* <td>{`${item.documentType}: ${item.documentName}`}</td> */}
                                {/* <td>{item.companyName}</td> */}
                                <td>{translateUserType(item.userType)}</td>
                                {/* <td>{item.linkedVentures}</td> */}
                                <td>{translateStatus(item.status)}</td>
                                <td>
                                    {/* <Tooltip text="Editar">
                                        <button
                                            onClick={() => handleEdit(item.id)}  // Abre o modal de edição
                                            className="action-button edit-button"
                                        >
                                            <Edit2 size={20} />
                                        </button>
                                    </Tooltip> */}
                                    <Tooltip text="Alterar senha">
                                        <button
                                            onClick={handlePasswordChange}  // Abre o modal de senha
                                            className="action-button password-button"
                                        >
                                            <KeyRound size={20} />
                                        </button>
                                    </Tooltip>

                                    {item.status === "inactive" ? (
                                        <Tooltip text="Inativar">
                                            <button
                                                onClick={() => handleDeactivate(item.id, item.status)}
                                                className="action-button deactivate-button"
                                            >
                                                <XCircle size={20} />
                                            </button>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip text="Ativar">
                                            <button
                                                onClick={() => handleDeactivate(item.id, item.status)}
                                                className="action-button activate-button"
                                            >
                                                <CheckCircle size={20} />
                                            </button>
                                        </Tooltip>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="table-footer">
                <div className="items-per-page">
                    <span>Exibir por</span>
                    <select
                        value={itemsPerPage}
                        onChange={(e) => {
                            setItemsPerPage(parseInt(e.target.value));
                            setCurrentPage(1);
                        }}
                    >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                    </select>
                </div>
                <div className="pagination">
                    <button
                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                    >
                        Anterior
                    </button>
                    {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                        <button
                            key={page}
                            onClick={() => setCurrentPage(page)}
                            className={currentPage === page ? "active" : ""}
                        >
                            {page}
                        </button>
                    ))}
                    <button
                        onClick={() =>
                            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                        }
                        disabled={currentPage === totalPages}
                    >
                        Próximo
                    </button>
                </div>

                {/* Modal de Edição */}
                {/* {isEditModalOpen && selectedData && (
                    <div className="modal-overlay-planta">
                        <div className="modal-planta-popup">
                            <div className="modal-header-planta">
                                <h2>Editar dados do usuário:</h2>
                                <button
                                    className="close-button-planta"
                                    onClick={() => setIsEditModalOpen(false)}
                                >
                                    ✕
                                </button>
                            </div>
                            <form
                                className="modal-form-planta"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    setData((prevData) =>
                                        prevData.map((item) =>
                                            item.id === selectedData.id ? { ...selectedData } : item
                                        )
                                    );
                                    handleUpdateUser();
                                }}
                            >
                                <div className="form-row-planta">
                                    <div>
                                        <label>ID:</label>
                                        <input type="text" value={selectedData.id} disabled />
                                    </div>
                                    <div>
                                        <label>Data do cadastro:</label>
                                        <input
                                            type="text"
                                            disabled
                                            value={selectedData.createdAt}
                                        />
                                    </div>
                                </div>
                                <div className="divisor-enterprise"></div>

                                <div className="form-row-planta">
                                    <div>
                                        <label>Nome do responsável:</label>
                                        <input
                                            type="text"
                                            value={selectedData.namePersonResponsible}
                                            onChange={(e) =>
                                                setSelectedData({
                                                    ...selectedData,
                                                    namePersonResponsible: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label>Email:</label>
                                        <input type="email" disabled value={selectedData.email} />
                                    </div>
                                </div>
                                <div className="divisor-enterprise"></div>

                                <div className="form-row-planta">
                                    <div>
                                        <label>Tipo de documento:</label>
                                        <select
                                            className="form-group-add-item"
                                            value={selectedData.documentType}
                                            onChange={(e) =>
                                                setSelectedData({ ...selectedData, documentType: e.target.value })
                                            }>
                                            <option value="CPF">CPF</option>
                                            <option value="CNPJ">CNPJ</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label>Número do documento:</label>
                                        <input
                                            type="text"
                                            value={selectedData.documentName}
                                            onChange={(e) =>
                                                setSelectedData({
                                                    ...selectedData,
                                                    documentName: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="divisor-enterprise"></div>
                                <div className="form-row-planta">
                                    <div>
                                        <label>Tipo de usuário:</label>
                                        <select
                                            className="form-group-add-item"
                                            value={selectedData.userType}
                                            onChange={(e) =>
                                                setSelectedData({ ...selectedData, userType: e.target.value })
                                            }>
                                            <option value="suporte">Suporte</option>
                                            <option value="admin">Admin</option>
                                            <option value="assistente">Assistente</option>
                                            <option value="correspondente">Correspondente</option>
                                        </select>
                                    </div>
                                    <div>
                                        <label>Status:</label>
                                        <select
                                            className="form-group-add-item"
                                            value={selectedData.status}
                                            onChange={(e) =>
                                                setSelectedData({ ...selectedData, status: e.target.value })
                                            }
                                        >
                                            <option value="Ativo">Ativo</option>
                                            <option value="Inativo">Inativo</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="divisor-enterprise"></div>

                                <div className="form-row-planta">
                                    {selectedData.userType === "correspondente" && (
                                        <div>
                                            <label>Nome da empresa:</label>
                                            <input
                                                type="text"
                                                value={selectedData.companyName}
                                                onChange={(e) =>
                                                    setSelectedData({
                                                        ...selectedData,
                                                        companyName: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                    )}

                                </div>
                                {selectedData.userType === "correspondente" && (
                                    <div className="form-row-planta">
                                        <div>
                                            <label>Empreendimentos vinculados:</label>
                                            <select
                                                required
                                                value={corresponding.linkedVentures[0]}
                                                onChange={(e) =>
                                                    setCorresponding((prevState) => ({
                                                        ...prevState,
                                                        linkedVentures: [Number(e.target.value)],
                                                    }))
                                                }
                                            >
                                                <option value="" disabled>Selecione um empreendimento:</option>
                                                {enterprises.map((enterprise) => (
                                                    <option key={enterprise.id} value={enterprise.id}>
                                                        {enterprise.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                )}


                                <div className="form-buttons-planta">
                                    <button
                                        type="button"
                                        className="cancel-button-planta"
                                        onClick={() => setIsEditModalOpen(false)}
                                    >
                                        Cancelar
                                    </button>
                                    <button
                                        type="submit"
                                        className={`create-button-planta ${isLoading ? "loading" : ""}`}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? (
                                            <span className="spinner">Salvando...</span>
                                        ) : (
                                            "Salvar alterações"
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )} */}

                {/* Modal de Alteração de Senha */}
                {isPasswordModalOpen && (
                    <div className="modal-overlay-password">
                        <div className="modal-password">
                            {/* Cabeçalho e conteúdo do modal de senha */}
                            <div className="modal-header-password">
                                <h2 className="data-box-details-title-h2">Alterar senha</h2>
                                <button
                                    className="close-button-password"
                                    onClick={() => setIsPasswordModalOpen(false)}  // Fecha o modal de senha
                                >
                                    ✕
                                </button>
                            </div>
                            <form onSubmit={handleSavePassword}>
                                {/* Formulário para alterar senha */}
                                <div className="form-group-password">
                                    <label>Senha atual:</label>
                                    <input
                                        type="password"
                                        placeholder="Digite sua senha atual"
                                        value={currentPassword}
                                        onChange={(e) => setCurrentPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group-password">
                                    <label>Nova senha</label>
                                    <input
                                        type="password"
                                        placeholder="Digite sua nova senha"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group-password">
                                    <label>Repetir nova senha</label>
                                    <input
                                        type="password"
                                        placeholder="Digite sua nova senha novamente"
                                        value={repeatNewPassword}
                                        onChange={(e) => setRepeatNewPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                {error && <p className="error-message">{error}</p>}
                                <div className="form-buttons-planta">
                                    <button
                                        type="button"
                                        className="cancel-button-planta"
                                        onClick={() => setIsPasswordModalOpen(false)}
                                    >
                                        Cancelar
                                    </button>
                                    <button
                                        type="submit"
                                        className={`create-button-planta ${isLoading ? "loading" : ""}`}
                                        disabled={!currentPassword || !newPassword || !repeatNewPassword}
                                    >
                                        {isLoading ? "Salvando..." : "Redefinir senha"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )}

            </div>
            <ToastContainer />
        </div>
    );
};

export default Users;