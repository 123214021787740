import ListSearchDTO from "../../domain/dto/listSearchDTO";
import ScheduleVisitDTO from "../../domain/dto/scheduleVisitDTO";
import ScheduleVisitsDTO from "../../domain/dto/scheduleVisitsDTO";
import ScheduleVisit from "../../domain/entities/scheduleVisit";
import { ScheduleVisits } from "../../domain/entities/scheduleVisits";
import { ListSearch } from "../../domain/entities/valueObjects/listSearch";
import Http from "../../http/http";
import HttpRequest from "../../http/httpRequest";
import ApiRoutes from "../../http/routes";

export default class ScheduleVisitRepository {
  private static instance: ScheduleVisitRepository | null = null;

  constructor(private http: Http) {}

  static getInstance(http: Http): ScheduleVisitRepository {
    if (!ScheduleVisitRepository.instance) {
      ScheduleVisitRepository.instance = new ScheduleVisitRepository(http);
    }
    return ScheduleVisitRepository.instance;
  }

  async getScheduledVisits(listSearch: ListSearch): Promise<ScheduleVisits> {
    const request = new HttpRequest(
      ApiRoutes.scheduleVisit,
      undefined,
      ListSearchDTO.toJson(listSearch)
    );

    const response = await this.http.get<ScheduleVisits>(request);
    return ScheduleVisitsDTO.fromJson(response);
  }

  async getScheduledVisitById(scheduleVisitId: string): Promise<ScheduleVisit> {
    const request = new HttpRequest(
      `${ApiRoutes.scheduleVisit}/${scheduleVisitId}`
    );

    const response = await this.http.get<ScheduleVisit>(request);
    return ScheduleVisitDTO.fromJson(response);
  }
  async updateScheduledVisitStatusById(
    id: string,
    newStatus: string,
    obs: string
  ) {
    const request = new HttpRequest(`${ApiRoutes.scheduleVisit}/${id}`, {
      status: newStatus,
      correspondentObs: obs,
    });

    await this.http.put<ScheduleVisit>(request);
  }
}
