import ProjectPlanDTO from "../../domain/dto/projectPlanDTO";
import ProjectPlan from "../../domain/entities/projectPlan";
import Http from "../../http/http";
import HttpRequest from "../../http/httpRequest";
import ApiRoutes from "../../http/routes";

export default class ProjectPlanRepository {
  private static instance: ProjectPlanRepository | null = null;

  constructor(private http: Http) {}

  static getInstance(http: Http): ProjectPlanRepository {
    if (!ProjectPlanRepository.instance) {
      ProjectPlanRepository.instance = new ProjectPlanRepository(http);
    }
    return ProjectPlanRepository.instance;
  }

  async createProjectPlan(enterpriseId: string, projectPlan: ProjectPlan) {
    const request = new HttpRequest(
      ApiRoutes.enterpriseProjectPlan.replace("{id}", enterpriseId),
      projectPlan
    );

    return await this.http.post<ProjectPlan>(request);
  }

  async updateProjectPlan(projectPlan: ProjectPlan) {
    const request = new HttpRequest(
      `${ApiRoutes.projectPlan}/${projectPlan.id}`,
      projectPlan
    );

    return await this.http.put<void>(request);
  }

  async deleteProjectPlan(projectPlanId: string) {
    const request = new HttpRequest(
      `${ApiRoutes.projectPlan}/${projectPlanId}`
    );

    return await this.http.delete(request);
  }

  async getProjectPlans(enterpriseId: string): Promise<ProjectPlan[]> {
    const request = new HttpRequest(
      ApiRoutes.enterpriseProjectPlan.replace("{id}", enterpriseId)
    );

    const response = await this.http.get<ProjectPlan[]>(request);
    return response.map((projectPlan) => ProjectPlanDTO.fromJson(projectPlan));
  }

  async addProjectPlanImage(projectPlanId: string, image: string | File) {
    const body =
      typeof image === "string" ? { imageLink: image } : { imageBase64: image };
    const request = new HttpRequest(
      `${ApiRoutes.projectPlanImage.replace("{id}", projectPlanId)}/image`,
      body
    );

    return await this.http.post<{ imageLink: string }>(request);
  }

  async deleteProjectPlanImage(projectPlanId: string, imageId: string) {
    const request = new HttpRequest(
      `${ApiRoutes.projectPlanImage.replace("{id}", projectPlanId)}/${imageId}`
    );

    return await this.http.delete(request);
  }
}
