import Unit from "../entities/unit";
import UnitStatus from "../entities/valueObjects/unitStatus";

export default class UnitDTO {
  public static fromJson(json: any): Unit {
    return new Unit(
      json.id,
      json.quantity,
      json.price,
      json.description,
      new UnitStatus(json.status),
      json.projectPlanId,
      json.priceHistory
    );
  }
}
