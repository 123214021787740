import ConstructionCompany from "../../domain/entities/constructionCompany";
import ConstructionCompanyDocs from "../../domain/entities/constructionCompanyDocs";
import { AxiosHttp } from "../../http/axios";
import ConstructionCompanyRepository from "../repository/constructionCompanyRepository";

export default class ConstructionCompanyProvider {
  private static instance: ConstructionCompanyProvider;
  private constructionCompanyRepository: ConstructionCompanyRepository;
  private constructionCompanies: ConstructionCompany[] = [];

  private constructor() {
    this.constructionCompanyRepository =
      ConstructionCompanyRepository.getInstance(AxiosHttp.getInstance());
    this.loadConstructionCompanies();
  }

  static getInstance(): ConstructionCompanyProvider {
    if (!ConstructionCompanyProvider.instance) {
      ConstructionCompanyProvider.instance = new ConstructionCompanyProvider();
    }
    return ConstructionCompanyProvider.instance;
  }

  private async loadConstructionCompanies() {
    this.constructionCompanies =
      await this.constructionCompanyRepository.getConstructionCompanies();
  }

  async createConstructionCompany(constructionCompany: ConstructionCompany) {
    const response =
      await this.constructionCompanyRepository.createConstructionCompany(
        constructionCompany
      );
    this.constructionCompanies.push(response);
    return response;
  }

  async updateConstructionCompany(constructionCompany: ConstructionCompany) {
    const response =
      await this.constructionCompanyRepository.updateConstructionCompany(
        constructionCompany
      );
    this.constructionCompanies.push(response);
    return response;
  }
  async updateConstructionCompanyStatus(id: string, status: string) {
    const response =
      await this.constructionCompanyRepository.updateConstructionCompanyStatus(
        id,
        status
      );
    this.constructionCompanies.push(response);
    return response;
  }

  async getConstructionCompaniesAsync(): Promise<ConstructionCompany[]> {
    return this.constructionCompanyRepository.getConstructionCompanies();
  }

  async getConstructionCompanyDocumentById(
    id: string
  ): Promise<ConstructionCompanyDocs[]> {
    return this.constructionCompanyRepository.getConstructionCompanyDocumentById(
      id
    );
  }

  async putConstructionCompanyDocumentById(
    id: string,
    code: string,
    status: string
  ) {
    return this.constructionCompanyRepository.putConstructionCompanyDocumentById(
      id,
      code,
      status
    );
  }

  async postConstructionCompanyDocumentById(
    id: string,
    code: string,
    description: string,
    documentLink: string | null
  ) {
    return this.constructionCompanyRepository.postConstructionCompanyDocumentById(
      id,
      code,
      description,
      documentLink
    );
  }

  getConstructionCompanies(): ConstructionCompany[] {
    return this.constructionCompanies;
  }

  getConstructionCompanyById(id: string): ConstructionCompany | undefined {
    return this.constructionCompanies.find(
      (constructionCompany) =>
        constructionCompany.id.toString() === id.toString()
    );
  }
}
