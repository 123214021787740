// src/components/Cronograma.js
import React, { useState, useEffect, useRef } from "react";
import "../../styles/components/_propostas.scss";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Proposal } from "../../domain/entities/proposal";
import LeadProvider from "../../infra/provider/leadProvider";
import UserProvider from "../../infra/provider/userProvider";
import { Formatter } from "../../utils/formatter";
import ProposalProvider from "../../infra/provider/proposalProvider";
import Lead from "../../domain/entities/lead";
import ZipCode from "../../domain/entities/valueObjects/zipCode";
import { Address } from "../../domain/entities/address";
import VehicleQuiz from "../../domain/entities/valueObjects/vehicleQuiz";
import PropertyQuiz from "../../domain/entities/valueObjects/ownPropertyQuiz";
import FgtsQuiz from "../../domain/entities/valueObjects/fgtsQuiz";
import LoanQuiz from "../../domain/entities/valueObjects/loanQuiz";
import BusinessQuiz from "../../domain/entities/valueObjects/businessQuiz";
import FreelancerQuiz from "../../domain/entities/valueObjects/freelancerQuiz";
import EnterpriseProvider from "../../infra/provider/enterpriseProvider";
import { ListSearch } from "../../domain/entities/valueObjects/listSearch";
import Enterprise from "../../domain/entities/enterprise";
import PaymentSignal from "../../domain/entities/valueObjects/paymentSignal";
import { Validator } from "../../utils/validator";
import SignalMethod from "../../domain/entities/valueObjects/signalMethod";
import Ownership from "../../domain/entities/valueObjects/ownership";
import GenericType from "../../domain/entities/genericType";

const CreateProposal = () => {
  useEffect(() => {
    getEnterprises();
  }, []);

  const leadProvider = LeadProvider.getInstance();
  const userProvider = UserProvider.getInstance();
  const proposalProvider = ProposalProvider.getInstance();
  const enterpriseProvider = EnterpriseProvider.getInstance();
  const [proposal, setProposal] = useState<Proposal>(new Proposal());
  const [isAlreadyLead, setIsAlreadyLead] = useState(false);
  const [isAlreadyUser, setIsAlreadyUser] = useState(false);
  const [hasAddress, setHasAddress] = useState(false);
  const [enterprises, setEnterprises] = useState<Enterprise[]>([]);
  const [step, setStep] = useState(1);
  const totalSteps = 12;
  const [cpf, setCpf] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingCep, setLoadingCep] = useState(false);
  const isFetchEnterprise = useRef(false);
  const formRef = useRef<HTMLFormElement>(null);
  const navigate = useNavigate();
  const [razaoSocial, setRazaoSocial] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [cfpIsValid, setCfpIsValid] = useState(false);

  const getEnterprises = async () => {
    if (isFetchEnterprise.current) return;
    isFetchEnterprise.current = true
    const enterprises = await enterpriseProvider.getEnterprisesName();
    setEnterprises(enterprises);
    isFetchEnterprise.current = false;
  };

  const fetchRazaoSocial = async () => {
    const cnpj = proposal.businessQuiz?.cnpj ? proposal.businessQuiz.cnpj.replace(/\D/g, "") : "";
    if (cnpj.length === 14) {
      try {
        const response = await fetch(`https://publica.cnpj.ws/cnpj/${cnpj}`);
        const data = await response.json();
        if (data.razao_social) {
          setRazaoSocial(data.razao_social);
        } else {
          setRazaoSocial("Razão social não encontrada.");
        }
      } catch (error) {
        setRazaoSocial("Erro ao buscar razão social.");
      }
    } else {
      setRazaoSocial("");
    }
  };

  const handleSearch = async () => {
    setLoading(true);
    await fetchRazaoSocial();
    setLoading(false);
  };

  const updateVehicleQuiz = (index: number, key: keyof VehicleQuiz, value: any) => {
    setProposal((prevProposal) => {
      const updatedVehicleQuiz = prevProposal.vehicleQuiz.map((vehicle, i) =>
        i === index ? { ...vehicle, [key]: value } : vehicle
      );
      return {
        ...prevProposal,
        vehicleQuiz: updatedVehicleQuiz,
      };
    });
  };

  const validateCpf = (cpf: string) => {
    if (!Validator.isValidCPF(cpf)) {
      setCfpIsValid(false);
      toast.error("CPF inválido");
      return;
    }
    else {
      setCfpIsValid(true)
    }
  };

  const updatePaymentSignalMethod = (
    index: number,
    key: keyof PaymentSignal,
    value: any
  ) => {
    if (key === 'ownership') {
      setOwnership(value.getMethod());
    }
    else if (key === 'method') {
      setPaymentMethod(value.getMethod());
    }

    setProposal((prevProposal) => {
      const updatedPaymentSignalMethods = prevProposal.paymentSignalMethods.map(
        (method, i) => (i === index ? { ...method, [key]: value } : method)
      );
      return {
        ...prevProposal,
        paymentSignalMethods: updatedPaymentSignalMethods,
      };
    });
  };

  const getEnterprise = async (id: string) => {
    try {
      setSelectedEnterprise(id);
      const enterprise = await enterpriseProvider.getEnterpriseById(id);
      setProposal((prevProposal) => ({
        ...prevProposal,
        enterprise,
      }));
    } catch (error) {
      toast.error("Erro ao buscar a empresa");
    }
  };

  const setUnit = async (unitId: string) => {
    setSelectedUnit(unitId);
    const unit = proposal.enterprise.units.find((unit) => unit.id == unitId);
    if (!unit) return;
    setProposal({ ...proposal, unit: unit });
  };

  const checkIfPaymentMethodIsCard = (method: SignalMethod) => {
    return (
      method.getMethod() === "creditCard" || method.getMethod() === "debitCard"
    );
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    nextStep();
  };

  const nextStep = async () => {
    if (!cfpIsValid)
      return toast.error("CPF inválido");

    if (formRef.current && !formRef.current.reportValidity()) {
      return;
    }
    if (step === 1) {
      if (!Validator.isValidCPF(cpf)) {
        toast.error("CPF inválido");
        return;
      }
      setLoading(true);
      const cpfClean = Formatter.clearSymbolsAndLetters(cpf);
      try {
        const lead = await leadProvider.getLeadByDocument(cpfClean);
        if (lead) {
          setIsAlreadyLead(true);
          setProposal({ ...proposal, lead });
          if (lead.address.zipCode.getCodeWithoutSymbols() !== "") {
            setHasAddress(true);
          }
        }
      } catch (error) {
        setIsAlreadyLead(false);
        try {
          const profile = await userProvider.getUserByDocument(cpfClean);
          if (profile) {
            setIsAlreadyUser(true);
            setProposal({
              ...proposal,
              lead: new Lead({
                name: profile.name,
                email: profile.user.email,
                phone: profile.phone,
                document: profile.document,
                address: profile.address,
              }),
            });
            if (profile.address.zipCode.getCodeWithoutSymbols() !== "") {
              setHasAddress(true);
            }
          }
        } catch (error) {
          setProposal({
            ...proposal,
            lead: new Lead({ document: cpfClean }),
          });
          setIsAlreadyUser(false);
        }
      }
    }
    setLoading(false);
    if (step < totalSteps) setStep(step + 1);
  };

  const prevStep = () => {
    if (step > 1) setStep(step - 1);
  };

  const handleFinalizar = async () => {
    let proposalToCreate = proposal;
    if (!isAlreadyLead) {
      try {
        const lead = await leadProvider.createLead(proposal.lead);
        if (!lead) throw new Error("Erro ao criar o lead");
        proposalToCreate = { ...proposal, lead };
        setIsAlreadyLead(true);
        toast.success("Lead criado com sucesso!");
      } catch (error) {
        toast.error("Erro ao criar o lead");
        return;
      }
    }
    try {
      await proposalProvider.createProposal(proposalToCreate);
      toast.success("Proposta criada com sucesso!");
      setTimeout(() => {
        navigate("/propostas/visualizar");
      }, 2000);
    } catch (error) {
      toast.error("Erro ao criar a proposta");
    }
  };

  const handleCpfChange = async (e: any) => {
    const value = e.target.value.replace(/\D/g, "");
    const formattedCpf = value
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    setCpf(formattedCpf);
    setProposal({ ...proposal, lead: { ...proposal.lead, document: value } });
    if (value.length === 11) {
      validateCpf(value);
    }
  };

  const handleCepIIChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cleanedCep = Formatter.clearSymbolsAndLetters(e.target.value);
    if (cleanedCep.length === 8) {
      fetchAddressByCep(cleanedCep);
    }
  };

  const fetchAddressByCep = async (cep: string) => {
    setLoadingCep(true);
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      const data = await response.json();

      if (data && !data.erro) {
        setProposal({
          ...proposal,
          lead: {
            ...proposal.lead,
            address: new Address(
              0,
              data.localidade,
              data.uf,
              data.bairro,
              data.logradouro,
              data.numero,
              new ZipCode(cep),
              data.complemento
            ),
          },
        });
      } else {
        toast.error("CEP não encontrado.");
      }
    } catch (error) {
      //console.error("Erro ao buscar o endereço:", error);
      toast.error("Ocorreu um erro ao buscar o endereço, tente novamente!");
    } finally {
      setLoadingCep(false);
    }
  };

  const today = new Date();
  const minDate = new Date(today.getFullYear() - 100, today.getMonth(), today.getDate());
  const maxDate = today;
  const [address, setAddress] = useState({
    city: "",
    uf: "",
    neighborhood: "",
    street: "",
    number: "",
    zipCode: "",
    complement: "",
  });
  const formatDate = (date: any) => {
    return date.toISOString().split('T')[0];
  };

  const AccordionItemCreate: React.FC<{
    title: string;
    children: React.ReactNode;
  }> = ({ title, children }) => {
    const [isOpen, setIsOpen] = React.useState(false);

    return (
      <div className="accordion-item-create">
        <div className="accordion-header-create" onClick={() => setIsOpen(!isOpen)}>
          <span>{title}</span>
          <span className={`accordion-icon-create ${isOpen ? "open" : ""}`}>▾</span>
        </div>
        {isOpen && <div className="accordion-content-create">{children}</div>}
      </div>
    );
  };

  const getMinAgeDate = (age: number): string => {
    const today = new Date();
    today.setFullYear(today.getFullYear() - age);
    return today.toISOString().split('T')[0];
  };

  const calculateAge = (birthDate: string): number => {
    const birth = new Date(birthDate);
    const today = new Date();
    let age = today.getFullYear() - birth.getFullYear();
    const month = today.getMonth();
    if (month < birth.getMonth() || (month === birth.getMonth() && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  };

  const initialVehicleState = {
    isOwned: null,
    thirdPartyDocument: "",
  };

  const progressPercentage = (step / totalSteps) * 100;

  const [selectedEnterprise, setSelectedEnterprise] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedPaymentMethod, setPaymentMethod] = useState("");
  const [selectedOwnership, setOwnership] = useState("");

  //API Fabricante e modelo:
  const [manufacturers, setManufacturers] = useState<GenericType[]>([]);
  const [models, setModels] = useState<GenericType[]>([]);

  useEffect(() => {
    fetch("https://parallelum.com.br/fipe/api/v1/carros/marcas")
      .then((response) => response.json())
      .then((data) => setManufacturers(data))
      .catch((error) => console.error("Erro ao carregar fabricantes:", error));
  }, []);

  const handleManufacturerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedManufacturerName = e.target.value;
    const selectedManufacturerCode = manufacturers?.find(f => f.nome === selectedManufacturerName)?.codigo;

    if (selectedManufacturerCode) {
      fetch(
        `https://parallelum.com.br/fipe/api/v1/carros/marcas/${selectedManufacturerCode}/modelos`
      )
        .then((response) => response.json())
        .then((data) => {
          console.log("Resposta da API de Modelos:", data);
          setModels(data.modelos || []);
        })
        .catch((error) => console.error("Erro ao carregar modelos:", error));
    } else {
      setModels([]);
    }
  };

  return (
    <div
      className={`form-container-proposta ${step === 12 ? "step-12-active" : ""}`}
    >
      <div className="form-header">
        <button className="back-button-arrow" onClick={prevStep}>
          &lt;
        </button>
        <span className="step-indicator">
          {step}/{totalSteps}
        </span>
      </div>
      <div className="progress-bar">
        <div
          className="progress-bar-fill"
          style={{ width: `${progressPercentage}%` }}
        ></div>
      </div>
      <form ref={formRef} onSubmit={handleSubmit}>
        {step === 1 && (
          <>
            <h2>Preencha o CPF:</h2>
            <div className="form-group">
              <label>CPF:</label>
              <input
                type="text"
                placeholder="Digite o CPF"
                required
                value={cpf}
                onChange={handleCpfChange}
                maxLength={14}
              />
              <div className="divisor"></div>
            </div>
          </>
        )}

        {step === 2 && (
          <>
            <h2>Preencha as informações pessoais:</h2>
            <div className="form-group">
              <label>Nome:</label>
              <input
                type="text"
                required
                disabled={isAlreadyLead || isAlreadyUser}
                placeholder="Digite um nome"
                value={proposal.lead.name}
                onChange={(e) =>
                  setProposal({
                    ...proposal,
                    lead: { ...proposal.lead, name: e.target.value },
                  })
                }
              />
              <div className="divisor"></div>
            </div>
            <div className="form-group">
              <label>Email:</label>
              <input
                type="email"
                required
                placeholder="Digite o email"
                value={proposal.lead.email}
                onChange={(e) => {
                  setProposal({
                    ...proposal,
                    lead: { ...proposal.lead, email: e.target.value },
                  });
                }}
              />
              <div className="divisor"></div>
            </div>
            <div className="form-group">
              <label>Data de nascimento:</label>
              <input
                type="date"
                required
                placeholder="Digite a data de nascimento"
                disabled={isAlreadyLead}
                value={
                  proposal.lead.birthDate
                    ? Formatter.timeStampToInputDate(proposal.lead.birthDate)
                    : ""
                }
                onChange={(e) => {
                  const birthDate = e.target.value;
                  const age = calculateAge(birthDate);
                  if (age >= 18) {
                    setProposal({
                      ...proposal,
                      lead: {
                        ...proposal.lead,
                        birthDate: Formatter.dateStringToTimestamp(birthDate),
                      },
                    });
                  } else {
                    toast.error("Você deve ter 18 anos ou mais.");
                  }
                }}
              />
              <div className="divisor"></div>
            </div>
            <div className="form-group">
              <label>Nome da mãe:</label>
              <input
                type="text"
                required
                placeholder="Digite o nome da mãe"
                value={proposal.lead.motherName}
                disabled={isAlreadyLead}
                onChange={(e) =>
                  setProposal({
                    ...proposal,
                    lead: { ...proposal.lead, motherName: e.target.value },
                  })
                }
              />
              <div className="divisor"></div>
            </div>
            <div className="form-group">
              <label>Celular:</label>
              <InputMask
                mask="(99) 99999-9999"
                value={proposal.lead.phone}
                required
                onChange={(e) =>
                  setProposal({
                    ...proposal,
                    lead: { ...proposal.lead, phone: e.target.value },
                  })
                }
              >
                {(inputProps) => (
                  <input
                    {...inputProps}
                    type="text"
                    required
                    placeholder="Digite o celular"
                  />
                )}
              </InputMask>
            </div>
            <div className="divisor"></div>

            {/* CEP */}
            <div className="form-group">
              <label>CEP:</label>
              <InputMask
                mask="99999-999"
                required
                value={proposal.lead.address.zipCode.getCodeWithoutSymbols()}
                onChange={handleCepIIChange}
                disabled={hasAddress}
              >
                {(inputProps: any) => (
                  <div style={{ position: "relative" }}>
                    <input
                      {...inputProps}
                      type="text"
                      required
                      disabled={hasAddress || loadingCep}
                      placeholder="Digite o CEP"
                    />
                    {loadingCep && (
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          fontSize: "18px",
                          color: "#888",
                        }}
                      >
                        <FaSpinner className="fa-spin" />
                      </div>
                    )}
                  </div>
                )}
              </InputMask>
            </div>
            <div className="divisor"></div>
            {/* Logradouro */}
            <div className="form-group">
              <label>Logradouro:</label>
              <input
                type="text"
                required
                disabled={hasAddress}
                placeholder="Digite o logradouro"
                value={proposal.lead.address.street}
                onChange={(e) =>
                  setProposal({
                    ...proposal,
                    lead: {
                      ...proposal.lead,
                      address: {
                        ...proposal.lead.address,
                        street: e.target.value,
                      },
                    },
                  })
                }
              />
            </div>
            <div className="divisor"></div>
            {/* Número */}
            <div className="form-row">
              <div className="form-group">
                <label>Número:</label>
                <input
                  type="text"
                  required
                  disabled={hasAddress}
                  placeholder="Digite o número"
                  value={proposal.lead.address.number}
                  onChange={(e) => {
                    setProposal({
                      ...proposal,
                      lead: {
                        ...proposal.lead,
                        address: {
                          ...proposal.lead.address,
                          number: e.target.value,
                        },
                      },
                    });
                  }}
                />
              </div>

              {/* Bairro */}
              <div className="form-group">
                <label>Bairro:</label>
                <input
                  type="text"
                  required
                  disabled={hasAddress}
                  placeholder="Digite o bairro"
                  value={proposal.lead.address.neighborhood}
                  onChange={(e) => {
                    setProposal({
                      ...proposal,
                      lead: {
                        ...proposal.lead,
                        address: {
                          ...proposal.lead.address,
                          neighborhood: e.target.value,
                        },
                      },
                    });
                  }}
                />
              </div>
            </div>
            <div className="divisor"></div>
            {/* Cidade */}
            <div className="form-row">
              <div className="form-group">
                <label>Cidade:</label>
                <input
                  type="text"
                  required
                  disabled={hasAddress}
                  placeholder="Digite a cidade"
                  value={proposal.lead.address.city}
                  readOnly
                />
              </div>

              {/* Estado */}
              <div className="form-group">
                <label>Estado:</label>
                <input
                  type="text"
                  required
                  disabled={hasAddress}
                  placeholder="Digite o estado"
                  value={proposal.lead.address.uf}
                  readOnly
                />
              </div>
            </div>
            <div className="divisor"></div>
            <div className="form-group">
              <label htmlFor="complemento">
                Complemento <i>(Opcional):</i>
              </label>
              <input
                id="complemento"
                type="text"
                value={address.complement}
                onChange={(e) =>
                  setAddress((prev) => ({
                    ...prev,
                    complement: e.target.value,
                  }))
                }
              />
            </div>
            <div className="divisor"></div>
          </>
        )}

        {step === 3 && (
          <>
            <h2>Preencha as informações patrimoniais</h2>
            <div className="form-group">
              <label>Renda Mensal:</label>
              <input
                type="text"
                placeholder="R$"
                required
                disabled={isAlreadyLead}
                value={Formatter.formatNumberToBRCurrency(
                  proposal.lead.monthlyIncome
                )}
                onChange={(e) => {
                  setProposal({
                    ...proposal,
                    lead: {
                      ...proposal.lead,
                      monthlyIncome: Formatter.strToNumber(e.target.value),
                    },
                  });
                }}
              />
              <div className="divisor"></div>
            </div>
            <div className="form-group">
              <label>Valor total do patrimônio:</label>
              <input
                type="text"
                placeholder="R$"
                required
                disabled={isAlreadyLead}
                value={Formatter.formatNumberToBRCurrency(
                  proposal.lead.patrimony
                )}
                onChange={(e) => {
                  setProposal({
                    ...proposal,
                    lead: {
                      ...proposal.lead,
                      patrimony: Formatter.strToNumber(e.target.value),
                    },
                  });
                }}
              />
              <div className="divisor"></div>
            </div>
          </>
        )}

        {step === 4 && (
          <>
            <h2>Preencha as informações patrimoniais</h2>
            <div className="form-group">
              <label>Possui veículo?</label>
              <div className="radio-group">
                <label>
                  <input
                    type="radio"
                    name="veiculo"
                    checked={proposal.vehicleQuiz.length > 0}
                    value="Sim"
                    onChange={() => {
                      setProposal({
                        ...proposal,
                        vehicleQuiz: [new VehicleQuiz()],
                      });
                    }}
                  />{" "}
                  Sim
                </label>
                <label>
                  <input
                    type="radio"
                    name="veiculo"
                    checked={proposal.vehicleQuiz.length === 0}
                    value="Não"
                    onChange={() => {
                      setProposal({
                        ...proposal,
                        vehicleQuiz: [],
                      });
                    }}
                  />{" "}
                  Não
                </label>
              </div>
              <div className="divisor"></div>
            </div>

            {proposal.vehicleQuiz.length > 0 && (
              <>
                {proposal.vehicleQuiz.map((vehicle, index) => (
                  <div className="new-fields" key={index}>
                    {/* Campos do primeiro veículo */}
                    <div className="form-group">
                      <label>Valor do veículo:</label>
                      <input
                        type="text"
                        required
                        placeholder="R$"
                        value={Formatter.formatNumberToBRCurrency(
                          vehicle.value
                        )}
                        onChange={(e) =>
                          updateVehicleQuiz(
                            index,
                            "value",
                            Formatter.strToNumber(e.target.value)
                          )
                        }
                      />
                      <div className="divisor"></div>
                    </div>
                    <div className="form-row">
                      <div className="form-group">
                        <label>Fabricante:</label>
                        <select
                          required
                          value={vehicle.manufacturer}
                          onChange={(e) => {
                            handleManufacturerChange(e);
                            updateVehicleQuiz(
                              index,
                              "manufacturer",
                              e.target.value
                            );
                          }}
                        >
                          <option value="">Selecione o fabricante:</option>
                          {manufacturers.map((manufacturer) => (
                            <option key={manufacturer.codigo} value={manufacturer.nome}>
                              {manufacturer.nome}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group">
                        <label>Modelo:</label>
                        <select
                          required
                          value={vehicle.model}
                          onChange={(e) => updateVehicleQuiz(index, "model", e.target.value)} // Atualiza o modelo no estado
                        >
                          <option value="">Selecione o modelo</option>
                          {models.map((model) => (
                            <option key={model.codigo} value={model.nome}>
                              {model.nome}
                            </option>
                          ))}
                        </select>

                      </div>

                    </div>
                    <div className="divisor"></div>
                    <div className="form-row">
                      <div className="form-group">
                        <label>Ano:</label>
                        <select
                          required
                          value={vehicle.year}
                          onChange={(e) =>
                            updateVehicleQuiz(index, "year", e.target.value)
                          }
                        >
                          <option value="">Selecione o ano</option>
                          {Array.from({ length: 70 }, (_, i) => {
                            const currentYear = new Date().getFullYear();
                            const month = new Date().getMonth();
                            const year = (month >= 8 ? currentYear + 1 : currentYear) - i;

                            if (year < currentYear - 69) return null;

                            return (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      <div className="form-group">
                        <label>KM:</label>
                        <input
                          type="number"
                          required
                          placeholder="KM"
                          value={vehicle.km}
                          pattern="\d*"
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*$/.test(value)) {
                              updateVehicleQuiz(index, "km", value);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="divisor"></div>
                    <div className="form-group">
                      <label>O veículo é próprio?</label>
                      <div className="radio-group">
                        <label>
                          <input
                            type="radio"
                            name={`veiculoProprio-${index}`}
                            value="Sim"
                            required
                            checked={vehicle.isOwned === true}
                            onChange={() => {
                              updateVehicleQuiz(index, "isOwned", true);
                            }}
                          />
                          Sim
                        </label>
                        <label>
                          <input
                            type="radio"
                            name={`veiculoProprio-${index}`}
                            value="Não"
                            required
                            checked={vehicle.isOwned === false}
                            onChange={() => {
                              updateVehicleQuiz(index, "isOwned", false);
                            }}
                          />
                          Não
                        </label>
                      </div>
                      <div className="divisor"></div>
                    </div>

                    {/* Exibe o campo de CPF apenas se o veículo não for próprio */}
                    {vehicle.isOwned === false && (
                      <div className="cpf-group">
                        <div className="form-group">
                          <label>Informe o CPF do terceiro:</label>
                          <InputMask
                            mask="999.999.999-99"
                            value={vehicle.thirdPartyDocument || ""}
                            onChange={(e) =>
                              updateVehicleQuiz(index, "thirdPartyDocument", e.target.value)
                            }
                            onBlur={(e) =>
                              validateCpf(e.target.value)
                            }
                            required
                            placeholder="000.000.000-00"
                          >
                            {(inputProps) => <input {...inputProps} type="text" />}
                          </InputMask>
                          <div className="divisor"></div>
                        </div>
                      </div>
                    )}



                    <div className="form-group">
                      <label>
                        Será usado como parte do pagamento do imóvel?
                      </label>
                      <div className="radio-group">
                        <label>
                          <input
                            type="radio"
                            name={`usoPagamento-${index}`}
                            value="Sim"
                            required
                            checked={vehicle.useAsPayment === true}
                            onChange={() =>
                              updateVehicleQuiz(index, "useAsPayment", true)
                            }
                          />{" "}
                          Sim
                        </label>
                        <label>
                          <input
                            type="radio"
                            name={`usoPagamento-${index}`}
                            value="Não"
                            required
                            checked={vehicle.useAsPayment === false}
                            onChange={() =>
                              updateVehicleQuiz(index, "useAsPayment", false)
                            }
                          />{" "}
                          Não
                        </label>
                      </div>
                      <div className="divisor"></div>
                    </div>
                    <div className="form-group">
                      <label>O veículo é financiado?</label>
                      <div className="radio-group">
                        <label>
                          <input
                            type="radio"
                            name={`veiculoFinanciado-${index}`}
                            value="Sim"
                            required
                            checked={vehicle.isFinanced === true}
                            onChange={() =>
                              updateVehicleQuiz(index, "isFinanced", true)
                            }
                          />{" "}
                          Sim
                        </label>
                        <label>
                          <input
                            type="radio"
                            name={`veiculoFinanciado-${index}`}
                            value="Não"
                            required
                            checked={vehicle.isFinanced === false}
                            onChange={() =>
                              updateVehicleQuiz(index, "isFinanced", false)
                            }
                          />{" "}
                          Não
                        </label>
                      </div>
                      <div className="divisor"></div>
                    </div>

                    {vehicle.isFinanced === true && (
                      <div className="form-group">
                        <label>Deseja refinanciar?</label>
                        <div className="radio-group">
                          <label>
                            <input
                              type="radio"
                              name={`refinanciar-${index}`}
                              value="Sim"
                              required
                              checked={vehicle.wantsRefinance === true}
                              onChange={() =>
                                updateVehicleQuiz(index, "wantsRefinance", true)
                              }
                            />{" "}
                            Sim
                          </label>
                          <label>
                            <input
                              type="radio"
                              name={`refinanciar-${index}`}
                              value="Não"
                              required
                              checked={vehicle.wantsRefinance === false}
                              onChange={() =>
                                updateVehicleQuiz(
                                  index,
                                  "wantsRefinance",
                                  false
                                )
                              }
                            />{" "}
                            Não
                          </label>
                        </div>
                        <div className="divisor"></div>
                      </div>
                    )}
                    {vehicle.wantsRefinance === true && (
                      <div className="form-group">
                        <label>Valor do refinanciamento:</label>
                        <input
                          type="text"
                          required
                          placeholder="R$"
                          value={Formatter.formatNumberToBRCurrency(
                            vehicle.refinanceValue
                          )}
                          onChange={(e) =>
                            updateVehicleQuiz(
                              index,
                              "refinanceValue",
                              Formatter.strToNumber(e.target.value)
                            )
                          }
                        />
                        <div className="divisor"></div>
                      </div>
                    )}
                    {/* Pergunta sobre adicionar mais veículos */}
                    <div className="form-group">
                      <label>Adicionar mais um veículo?</label>
                      <div className="radio-group">
                        <label>
                          <input
                            type="radio"
                            name={`maisVeiculos-${index}`}
                            value="Sim"
                            checked={proposal.vehicleQuiz.length - 1 !== index}
                            onChange={() => {
                              setProposal({
                                ...proposal,
                                vehicleQuiz: [
                                  ...proposal.vehicleQuiz,
                                  new VehicleQuiz(),
                                ],
                              });
                            }}
                          />{" "}
                          Sim
                        </label>
                        <label>
                          <input
                            type="radio"
                            name={`maisVeiculos-${index}`}
                            value="Não"
                            checked={proposal.vehicleQuiz.length - 1 === index}
                            onChange={() => {
                              if (proposal.vehicleQuiz.length > 1) {
                                setProposal({
                                  ...proposal,
                                  vehicleQuiz: proposal.vehicleQuiz.slice(
                                    0,
                                    -1
                                  ),
                                });
                              }
                            }}
                          />{" "}
                          Não
                        </label>
                      </div>
                      <div className="divisor"></div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </>
        )}

        {step === 5 && (
          <>
            <h2>Preencha as informações patrimoniais:</h2>
            <div className="form-group">
              <label>Possui imóvel?</label>
              <div className="radio-group">
                <label>
                  <input
                    type="radio"
                    name="imovel"
                    required
                    checked={proposal.propertyQuiz.length > 0}
                    value="Sim"
                    onChange={() => {
                      setProposal({
                        ...proposal,
                        propertyQuiz: [new PropertyQuiz()],
                      });
                    }}
                  />{" "}
                  Sim
                </label>
                <label>
                  <input
                    type="radio"
                    name="imovel"
                    required
                    checked={proposal.propertyQuiz.length === 0}
                    value="Não"
                    onChange={() => {
                      setProposal({
                        ...proposal,
                        propertyQuiz: [],
                      });
                    }}
                  />{" "}
                  Não
                </label>
              </div>
              <div className="divisor"></div>
            </div>

            {proposal.propertyQuiz.length > 0 && (
              <>
                {proposal.propertyQuiz.map((property, index) => (
                  <div key={index} className="new-fields">
                    <div className="form-group">
                      <label>Valor do Imóvel:</label>
                      <input
                        type="text"
                        placeholder="R$"
                        required
                        value={Formatter.formatNumberToBRCurrency(property.value)}
                        onChange={(e) => {
                          const updatedPropertyQuiz = [...proposal.propertyQuiz];
                          updatedPropertyQuiz[index] = {
                            ...property,
                            value: Formatter.strToNumber(e.target.value),
                          };
                          setProposal({
                            ...proposal,
                            propertyQuiz: updatedPropertyQuiz,
                          });
                        }}
                      />
                      <div className="divisor"></div>
                    </div>
                    <div className="form-group">
                      <label>O imóvel é próprio?</label>
                      <div className="radio-group">
                        <label>
                          <input
                            type="radio"
                            name={`imovelProprio-${index}`}
                            required
                            checked={property.isOwned === true}
                            value="Sim"
                            onChange={() => {
                              const updatedPropertyQuiz = [...proposal.propertyQuiz];
                              updatedPropertyQuiz[index] = {
                                ...property,
                                isOwned: true,
                              };
                              setProposal({
                                ...proposal,
                                propertyQuiz: updatedPropertyQuiz,
                              });
                            }}
                          />{" "}
                          Sim
                        </label>
                        <label>
                          <input
                            type="radio"
                            name={`imovelProprio-${index}`}
                            required
                            checked={property.isOwned === false}
                            value="Não"
                            onChange={() => {
                              const updatedPropertyQuiz = [...proposal.propertyQuiz];
                              updatedPropertyQuiz[index] = {
                                ...property,
                                isOwned: false,
                              };
                              setProposal({
                                ...proposal,
                                propertyQuiz: updatedPropertyQuiz,
                              });
                            }}
                          />{" "}
                          Não
                        </label>
                      </div>
                      <div className="divisor"></div>
                    </div>
                    {property.isOwned === false && (
                      <div className="form-group">
                        <label>Informe o CPF do terceiro:</label>
                        <InputMask
                          type="text"
                          name={`cpf-${index}`}
                          required
                          mask="999.999.999-99"
                          value={property.thirdPartyDocument}
                          onChange={(e) => {
                            const updatedPropertyQuiz = [...proposal.propertyQuiz];
                            updatedPropertyQuiz[index] = {
                              ...property,
                              thirdPartyDocument: e.target.value,
                            };
                            setProposal({
                              ...proposal,
                              propertyQuiz: updatedPropertyQuiz,
                            });
                          }}
                          onBlur={(e) =>
                            validateCpf(e.target.value)
                          }
                          placeholder="000.000.000-00"
                          className="cpf-input"
                        />
                        <div className="divisor"></div>
                      </div>
                    )}
                    <div className="form-group">
                      <label>O imóvel é financiado?</label>
                      <div className="radio-group">
                        <label>
                          <input
                            type="radio"
                            name={`imovelFinanciado-${index}`}
                            required
                            checked={property.isFinanced === true}
                            value="Sim"
                            onChange={() => {
                              const updatedPropertyQuiz = [...proposal.propertyQuiz];
                              updatedPropertyQuiz[index] = {
                                ...property,
                                isFinanced: true,
                              };
                              setProposal({
                                ...proposal,
                                propertyQuiz: updatedPropertyQuiz,
                              });
                            }}
                          />{" "}
                          Sim
                        </label>
                        <label>
                          <input
                            type="radio"
                            name={`imovelFinanciado-${index}`}
                            required
                            checked={property.isFinanced === false}
                            value="Não"
                            onChange={() => {
                              const updatedPropertyQuiz = [...proposal.propertyQuiz];
                              updatedPropertyQuiz[index] = {
                                ...property,
                                isFinanced: false,
                              };
                              setProposal({
                                ...proposal,
                                propertyQuiz: updatedPropertyQuiz,
                              });
                            }}
                          />{" "}
                          Não
                        </label>
                      </div>
                      <div className="divisor"></div>
                    </div>
                    {property.isFinanced === true && (
                      <div className="form-group">
                        <label>Deseja refinanciar?</label>
                        <div className="radio-group">
                          <label>
                            <input
                              type="radio"
                              name={`refinanciar-${index}`}
                              value="Sim"
                              required
                              checked={property.wantsRefinance === true}
                              onChange={() => {
                                const updatedPropertyQuiz = [...proposal.propertyQuiz];
                                updatedPropertyQuiz[index] = {
                                  ...property,
                                  wantsRefinance: true,
                                };
                                setProposal({
                                  ...proposal,
                                  propertyQuiz: updatedPropertyQuiz,
                                });
                              }}
                            />{" "}
                            Sim
                          </label>
                          <label>
                            <input
                              type="radio"
                              name={`refinanciar-${index}`}
                              value="Não"
                              required
                              checked={property.wantsRefinance === false}
                              onChange={() => {
                                const updatedPropertyQuiz = [...proposal.propertyQuiz];
                                updatedPropertyQuiz[index] = {
                                  ...property,
                                  wantsRefinance: false,
                                };
                                setProposal({
                                  ...proposal,
                                  propertyQuiz: updatedPropertyQuiz,
                                });
                              }}
                            />{" "}
                            Não
                          </label>
                        </div>
                        <div className="divisor"></div>
                      </div>
                    )}
                    {property.wantsRefinance === true && (
                      <div className="form-group">
                        <label>Valor do refinanciamento:</label>
                        <input
                          type="text"
                          required
                          placeholder="R$"
                          value={Formatter.formatNumberToBRCurrency(
                            property.refinanceValue
                          )}
                          onChange={(e) => {
                            const updatedPropertyQuiz = [...proposal.propertyQuiz];
                            updatedPropertyQuiz[index] = {
                              ...property,
                              refinanceValue: Formatter.strToNumber(e.target.value),
                            };
                            setProposal({
                              ...proposal,
                              propertyQuiz: updatedPropertyQuiz,
                            });
                          }}
                        />
                        <div className="divisor"></div>
                      </div>
                    )}
                    <div className="form-group">
                      <label>Será usado como parte do pagamento do imóvel?</label>
                      <div className="radio-group">
                        <label>
                          <input
                            type="radio"
                            name={`usoPagamento-${index}`}
                            value="Sim"
                            required
                            checked={property.useAsPayment === true}
                            onChange={() => {
                              const updatedPropertyQuiz = [...proposal.propertyQuiz];
                              updatedPropertyQuiz[index] = {
                                ...property,
                                useAsPayment: true,
                              };
                              setProposal({
                                ...proposal,
                                propertyQuiz: updatedPropertyQuiz,
                              });
                            }}
                          />{" "}
                          Sim
                        </label>
                        <label>
                          <input
                            type="radio"
                            name={`usoPagamento-${index}`}
                            value="Não"
                            required
                            checked={property.useAsPayment === false}
                            onChange={() => {
                              const updatedPropertyQuiz = [...proposal.propertyQuiz];
                              updatedPropertyQuiz[index] = {
                                ...property,
                                useAsPayment: false,
                              };
                              setProposal({
                                ...proposal,
                                propertyQuiz: updatedPropertyQuiz,
                              });
                            }}
                          />{" "}
                          Não
                        </label>
                      </div>
                      <div className="divisor"></div>
                    </div>
                    {/* Adicionar botão para mais imóveis */}
                    <div className="form-group">
                      <label>Adicionar mais um imóvel?</label>
                      <div className="radio-group">
                        <label>
                          <input
                            type="radio"
                            name={`maisImoveis-${index}`}
                            value="Sim"
                            checked={proposal.propertyQuiz.length - 1 !== index}
                            onChange={() => {
                              setProposal({
                                ...proposal,
                                propertyQuiz: [...proposal.propertyQuiz, new PropertyQuiz()],
                              });
                            }}
                          />{" "}
                          Sim
                        </label>
                        <label>
                          <input
                            type="radio"
                            name={`maisImoveis-${index}`}
                            value="Não"
                            checked={proposal.propertyQuiz.length - 1 === index}
                            onChange={() => {
                              if (proposal.propertyQuiz.length > 1) {
                                setProposal({
                                  ...proposal,
                                  propertyQuiz: proposal.propertyQuiz.slice(0, -1),
                                });
                              }
                            }}
                          />{" "}
                          Não
                        </label>
                      </div>
                      <div className="divisor"></div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </>
        )}

        {step === 6 && (
          <>
            <h2>Preencha as informações patrimoniais:</h2>
            <div className="form-group">
              <label>Possui FGTS?</label>
              <div className="radio-group">
                <label>
                  <input
                    type="radio"
                    name="fgts"
                    checked={proposal.fgtsQuiz !== null}
                    value="Sim"
                    onChange={() => {
                      setProposal({
                        ...proposal,
                        fgtsQuiz: new FgtsQuiz(),
                      });
                    }}
                  />{" "}
                  Sim
                </label>
                <label>
                  <input
                    type="radio"
                    name="fgts"
                    checked={proposal.fgtsQuiz === null}
                    value="Não"
                    onChange={() => {
                      setProposal({
                        ...proposal,
                        fgtsQuiz: null,
                      });
                    }}
                  />{" "}
                  Não
                </label>
              </div>
              <div className="divisor"></div>
            </div>

            {proposal.fgtsQuiz && (
              <div className="new-fields">
                <div className="form-group">
                  <label>Saldo disponível:</label>
                  <input
                    type="text"
                    placeholder="R$"
                    required
                    value={Formatter.formatNumberToBRCurrency(proposal.fgtsQuiz.balance)}
                    onChange={(e) => {
                      setProposal({
                        ...proposal,
                        fgtsQuiz: {
                          ...proposal.fgtsQuiz!,
                          balance: Formatter.strToNumber(e.target.value),
                        },
                      });
                    }}
                  />
                </div>
                <div className="divisor"></div>
                <div className="form-group">
                  <label>Possui saque aniversário?</label>
                  <div className="radio-group">
                    <label>
                      <input
                        type="radio"
                        name="saque-ani"
                        required
                        checked={proposal.fgtsQuiz.hasAnniversaryWithdrawal === true}
                        value="Sim"
                        onChange={() => {
                          setProposal({
                            ...proposal,
                            fgtsQuiz: {
                              ...proposal.fgtsQuiz!,
                              hasAnniversaryWithdrawal: true,
                            },
                          });
                        }}
                      />{" "}
                      Sim
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="saque-ani"
                        required
                        checked={proposal.fgtsQuiz.hasAnniversaryWithdrawal === false}
                        value="Não"
                        onChange={() => {
                          setProposal({
                            ...proposal,
                            fgtsQuiz: {
                              ...proposal.fgtsQuiz!,
                              hasAnniversaryWithdrawal: false,
                            },
                          });
                        }}
                      />{" "}
                      Não
                    </label>
                  </div>
                  <div className="divisor"></div>
                </div>
                {proposal.fgtsQuiz.hasAnniversaryWithdrawal && (
                  <div className="additional-options">
                    <div className="form-group">
                      <label>Tem interesse em usar como parte do pagamento?</label>
                      <div className="radio-group">
                        <label>
                          <input
                            type="radio"
                            name="saque-ani-pag"
                            required
                            checked={proposal.fgtsQuiz.useAsPayment === true}
                            value="Sim"
                            onChange={() => {
                              setProposal({
                                ...proposal,
                                fgtsQuiz: {
                                  ...proposal.fgtsQuiz!,
                                  useAsPayment: true,
                                },
                              });
                            }}
                          />{" "}
                          Sim
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="saque-ani-pag"
                            required
                            checked={proposal.fgtsQuiz.useAsPayment === false}
                            value="Não"
                            onChange={() => {
                              setProposal({
                                ...proposal,
                                fgtsQuiz: {
                                  ...proposal.fgtsQuiz!,
                                  useAsPayment: false,
                                },
                              });
                            }}
                          />{" "}
                          Não
                        </label>
                      </div>
                      <div className="divisor"></div>
                    </div>
                    <div className="form-group">
                      <label>
                        Irá usar para amortização na entrega das chaves?
                      </label>
                      <div className="radio-group">
                        <label>
                          <input
                            type="radio"
                            name="saque-ani-amortiz"
                            required
                            checked={proposal.fgtsQuiz.useForAmortization === true}
                            value="Sim"
                            onChange={() => {
                              setProposal({
                                ...proposal,
                                fgtsQuiz: {
                                  ...proposal.fgtsQuiz!,
                                  useForAmortization: true,
                                },
                              });
                            }}
                          />{" "}
                          Sim
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="saque-ani-amortiz"
                            required
                            checked={proposal.fgtsQuiz.useForAmortization === false}
                            value="Não"
                            onChange={() => {
                              setProposal({
                                ...proposal,
                                fgtsQuiz: {
                                  ...proposal.fgtsQuiz!,
                                  useForAmortization: false,
                                },
                              });
                            }}
                          />{" "}
                          Não
                        </label>
                      </div>
                      <div className="divisor"></div>
                    </div>
                    <div className="form-group">
                      <label>
                        Outra pessoa que possa realizar em conjunto essas operações?
                      </label>
                      <div className="radio-group">
                        <label>
                          <input
                            type="radio"
                            name="outra-pessoa"
                            required
                            checked={proposal.fgtsQuiz.hasWithdrawalTwo === true}
                            value="Sim"
                            onChange={() => {
                              setProposal({
                                ...proposal,
                                fgtsQuiz: {
                                  ...proposal.fgtsQuiz!,
                                  hasWithdrawalTwo: true,
                                },
                              });
                            }}
                          />{" "}
                          Sim
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="outra-pessoa"
                            required
                            checked={proposal.fgtsQuiz.hasWithdrawalTwo === false}
                            value="Não"
                            onChange={() => {
                              setProposal({
                                ...proposal,
                                fgtsQuiz: {
                                  ...proposal.fgtsQuiz!,
                                  hasWithdrawalTwo: false,
                                },
                              });
                            }}
                          />{" "}
                          Não
                        </label>
                      </div>
                      <div className="divisor"></div>
                    </div>

                    {proposal.fgtsQuiz.hasWithdrawalTwo && (
                      <div className="outra-pessoa-details">
                        <div className="form-group">
                          <label>Saldo disponível:</label>
                          <input
                            type="text"
                            placeholder="R$"
                            required
                            value={Formatter.formatNumberToBRCurrency(proposal.fgtsQuiz.balanceTwo)}
                            onChange={(e) => {
                              setProposal({
                                ...proposal,
                                fgtsQuiz: {
                                  ...proposal.fgtsQuiz!,
                                  balanceTwo: Formatter.strToNumber(e.target.value),
                                },
                              });
                            }}
                          />
                          <div className="divisor"></div>
                        </div>

                        {proposal.fgtsQuiz.hasWithdrawalTwo && (
                          <div className="form-group">
                            <label>Informe o CPF do terceiro:</label>
                            <InputMask
                              type="text"
                              name="thirdPartyDocument"
                              required
                              mask="999.999.999-99"
                              value={proposal.fgtsQuiz.thirdPartyDocument}
                              onChange={(e) => {
                                const cpf = e.target.value;
                                setProposal({
                                  ...proposal,
                                  fgtsQuiz: {
                                    ...proposal.fgtsQuiz!,
                                    thirdPartyDocument: cpf,
                                  },
                                });
                              }}
                              placeholder="000.000.000-00"
                              className="cpf-input"
                            />
                            <div className="divisor"></div>
                          </div>
                        )}

                        <div className="form-group">
                          <label>Possui saque aniversário?</label>
                          <div className="radio-group">
                            <label>
                              <input
                                type="radio"
                                name="outro-saque"
                                required
                                checked={proposal.fgtsQuiz.hasAnniversaryWithdrawalTwo === true}
                                value="Sim"
                                onChange={() => {
                                  setProposal({
                                    ...proposal,
                                    fgtsQuiz: {
                                      ...proposal.fgtsQuiz!,
                                      hasAnniversaryWithdrawalTwo: true,
                                    },
                                  });
                                }}
                              />{" "}
                              Sim
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="outro-saque"
                                required
                                checked={proposal.fgtsQuiz.hasAnniversaryWithdrawalTwo === false}
                                value="Não"
                                onChange={() => {
                                  setProposal({
                                    ...proposal,
                                    fgtsQuiz: {
                                      ...proposal.fgtsQuiz!,
                                      hasAnniversaryWithdrawalTwo: false,
                                    },
                                  });
                                }}
                              />{" "}
                              Não
                            </label>
                          </div>
                          <div className="divisor"></div>
                        </div>

                        <div className="form-group">
                          <label>Tem interesse em usar como parte do pagamento?</label>
                          <div className="radio-group">
                            <label>
                              <input
                                type="radio"
                                name="conta-conjunta"
                                required
                                checked={proposal.fgtsQuiz.useAsPaymentTwo === true}
                                value="Sim"
                                onChange={() => {
                                  setProposal({
                                    ...proposal,
                                    fgtsQuiz: {
                                      ...proposal.fgtsQuiz!,
                                      useAsPaymentTwo: true,
                                    },
                                  });
                                }}
                              />{" "}
                              Sim
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="conta-conjunta"
                                required
                                checked={proposal.fgtsQuiz.useAsPaymentTwo === false}
                                value="Não"
                                onChange={() => {
                                  setProposal({
                                    ...proposal,
                                    fgtsQuiz: {
                                      ...proposal.fgtsQuiz!,
                                      useAsPaymentTwo: false,
                                    },
                                  });
                                }}
                              />{" "}
                              Não
                            </label>
                          </div>
                          <div className="divisor"></div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </>
        )}

        {step === 7 && (
          <>
            <h2>Preencha as informações patrimoniais:</h2>
            <div className="form-group">
              <label>Possui empréstimo consignado?</label>
              <div className="radio-group">
                <label>
                  <input
                    type="radio"
                    name="emprestimo-consig"
                    required
                    checked={proposal.loanQuiz !== null}
                    value="Sim"
                    onChange={() => {
                      setProposal({
                        ...proposal,
                        loanQuiz: new LoanQuiz(),
                      });
                    }}
                  />{" "}
                  Sim
                </label>
                <label>
                  <input
                    type="radio"
                    name="emprestimo-consig"
                    required
                    checked={proposal.loanQuiz === null}
                    value="Não"
                    onChange={() => {
                      setProposal({
                        ...proposal,
                        loanQuiz: null,
                      });
                    }}
                  />{" "}
                  Não
                </label>
              </div>
              <div className="divisor"></div>
            </div>
            {proposal.loanQuiz && (
              <>
                <div className="form-group">
                  <label>É próprio?</label>
                  <div className="radio-group">
                    <label>
                      <input
                        type="radio"
                        name="proprio"
                        required
                        checked={proposal.loanQuiz.isOwned === true}
                        value="Sim"
                        onChange={() => {
                          setProposal({
                            ...proposal,
                            loanQuiz: {
                              ...proposal.loanQuiz!,
                              isOwned: true,
                            },
                          });
                        }}
                      />{" "}
                      Sim
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="proprio"
                        required
                        checked={proposal.loanQuiz.isOwned === false}
                        value="Não"
                        onChange={() => {
                          setProposal({
                            ...proposal,
                            loanQuiz: {
                              ...proposal.loanQuiz!,
                              isOwned: false,
                            },
                          });
                        }}
                      />{" "}
                      Não
                    </label>
                  </div>
                  <div className="divisor"></div>
                </div>
                <div className="form-group">
                  <label>Número da matrícula:</label>
                  <input
                    type="text"
                    placeholder="Digite o número da matrícula"
                    required
                    value={proposal.loanQuiz!.registrationNumber}
                    onChange={(e) => {
                      setProposal({
                        ...proposal,
                        loanQuiz: {
                          ...proposal.loanQuiz!,
                          registrationNumber: e.target.value,
                        },
                      });
                    }}
                  />
                  <div className="divisor"></div>
                </div>
                <div className="form-group">
                  <label>ID da Averbadora:</label>
                  <input
                    type="text"
                    placeholder="Digite o ID da averbadora"
                    required
                    value={proposal.loanQuiz!.averbId}
                    onChange={(e) => {
                      setProposal({
                        ...proposal,
                        loanQuiz: {
                          ...proposal.loanQuiz!,
                          averbId: e.target.value,
                        },
                      });
                    }}
                  />
                </div>
                <div className="divisor"></div>
                {/* <div className="form-group">
          <label>É funcionário público?</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name="func-publico"
                required
                checked={proposal.loanQuiz.isPublicServant === true}
                value="Sim"
                onChange={() => {
                  setProposal({
                    ...proposal,
                    loanQuiz: {
                      ...proposal.loanQuiz!,
                      isPublicServant: true,
                    },
                  });
                }}
              />{" "}
              Sim
            </label>
            <label>
              <input
                type="radio"
                name="func-publico"
                required
                checked={proposal.loanQuiz.isPublicServant === false}
                value="Não"
                onChange={() => {
                  setProposal({
                    ...proposal,
                    loanQuiz: {
                      ...proposal.loanQuiz!,
                      isPublicServant: false,
                    },
                  });
                }}
              />{" "}
              Não
            </label>
          </div>
          <div className="divisor"></div>
                </div> */}
                {proposal.loanQuiz.isPublicServant && (
                  <>
                    <div className="form-group">
                      <label>Em qual esfera atua?</label>
                      <select
                        required
                        value={proposal.loanQuiz.sphereOfAction}
                        onChange={(e) => {
                          setProposal({
                            ...proposal,
                            loanQuiz: {
                              ...proposal.loanQuiz!,
                              sphereOfAction: e.target.value,
                            },
                          });
                        }}
                      >
                        <option value="" disabled>
                          Selecione a esfera:
                        </option>
                        <option value="Federal">Federal</option>
                        <option value="Estadual">Estadual</option>
                        <option value="Municipal">Municipal</option>
                        <option value="Privado">Privado</option>
                        <option value="Terceiro Setor">Terceiro Setor</option>
                      </select>
                      <div className="divisor"></div>
                    </div>
                    {["Federal", "Estadual", "Municipal"].includes(proposal.loanQuiz.sphereOfAction || "") && (
                      <div className="form-group">
                        <label>Qual órgão?</label>
                        <input
                          type="text"
                          placeholder="Digite o nome do órgão"
                          required
                          value={proposal.loanQuiz!.agency}
                          onChange={(e) => {
                            setProposal({
                              ...proposal,
                              loanQuiz: {
                                ...proposal.loanQuiz!,
                                agency: e.target.value,
                              },
                            });
                          }}
                        />
                        <div className="divisor"></div>
                      </div>
                    )}

                  </>
                )}
              </>
            )}
          </>
        )}

        {step === 8 && (
          <>
            <h2>Preencha as informações patrimoniais:</h2>
            <div className="form-group">
              <label>É empresário(a)?</label>
              <div className="radio-group">
                <label>
                  <input
                    type="radio"
                    name="empresario"
                    required
                    checked={proposal.businessQuiz !== null}
                    value="Sim"
                    onChange={() => {
                      setProposal({
                        ...proposal,
                        businessQuiz: new BusinessQuiz(),
                      });
                    }}
                  />
                  Sim
                </label>
                <label>
                  <input
                    type="radio"
                    name="empresario"
                    required
                    checked={proposal.businessQuiz === null}
                    value="Não"
                    onChange={() => {
                      setProposal({
                        ...proposal,
                        businessQuiz: null,
                      });
                    }}
                  />
                  Não
                </label>
              </div>
              <div className="divisor"></div>
            </div>

            {proposal.businessQuiz && (
              <>
                <div className="form-group">
                  <label>CNPJ:</label>
                  <div className="input-group cnpj">
                    <InputMask
                      mask="99.999.999/9999-99"
                      placeholder="Digite um CNPJ"
                      className="form-control"
                      required
                      value={proposal.businessQuiz?.cnpj || ""}
                      onChange={(e) => {
                        setProposal({
                          ...proposal,
                          businessQuiz: {
                            ...proposal.businessQuiz!,
                            cnpj: e.target.value,
                          },
                        });
                      }}
                    />
                    <button
                      className="btn-cnpj btn-primary"
                      type="button"
                      onClick={handleSearch}
                      disabled={loading} // Desativa o botão enquanto estiver carregando
                    >
                      {loading ? "Buscando..." : "Buscar"}
                    </button>
                  </div>
                  <div className="divisor"></div>
                  {razaoSocial && <p className="proposal-razao-social"><strong>Razão Social:</strong> {razaoSocial}</p>}
                  <div className="divisor"></div>
                </div>

                <div className="form-group">
                  <label>Tipo de empresa:</label>
                  <input
                    type="text"
                    placeholder="Digite o tipo de empresa"
                    required
                    value={proposal.businessQuiz!.companyType}
                    onChange={(e) => {
                      setProposal({
                        ...proposal,
                        businessQuiz: {
                          ...proposal.businessQuiz!,
                          companyType: e.target.value,
                        },
                      });
                    }}
                  />
                  <div className="divisor"></div>
                </div>

                <div className="form-group">
                  <label>Faturamento mensal dos últimos 3 meses:</label>
                  <input
                    type="text"
                    placeholder="R$"
                    value={Formatter.formatNumberToBRCurrency(
                      proposal.businessQuiz!.lastThreeMonthsRevenue
                    )}
                    onChange={(e) => {
                      setProposal({
                        ...proposal,
                        businessQuiz: {
                          ...proposal.businessQuiz!,
                          lastThreeMonthsRevenue: Formatter.strToNumber(
                            e.target.value
                          ),
                        },
                      });
                    }}
                  />
                  <div className="divisor"></div>
                </div>

                <div className="form-group">
                  <label>Faturamento em cartões:</label>
                  <input
                    type="text"
                    placeholder="R$"
                    required
                    value={Formatter.formatNumberToBRCurrency(
                      proposal.businessQuiz!.cardsRevenue
                    )}
                    onChange={(e) => {
                      setProposal({
                        ...proposal,
                        businessQuiz: {
                          ...proposal.businessQuiz!,
                          cardsRevenue: Formatter.strToNumber(e.target.value),
                        },
                      });
                    }}
                  />
                  <div className="divisor"></div>
                </div>

                <div className="form-group">
                  <label>Adquirente atual</label>
                  <input
                    type="text"
                    placeholder="Digite seu adquirente atual"
                    required
                    value={proposal.businessQuiz!.currentAcquirer}
                    onChange={(e) => {
                      setProposal({
                        ...proposal,
                        businessQuiz: {
                          ...proposal.businessQuiz!,
                          currentAcquirer: e.target.value,
                        },
                      });
                    }}
                  />
                  <div className="divisor"></div>
                </div>

                <div className="form-group">
                  <label>Taxa de débito:</label>
                  <input
                    type="text"
                    placeholder="%"
                    required
                    value={Formatter.formatPercentage(
                      proposal.businessQuiz!.debitRate
                    )}
                    onChange={(e) => {
                      setProposal({
                        ...proposal,
                        businessQuiz: {
                          ...proposal.businessQuiz!,
                          debitRate: Formatter.strToNumber(e.target.value),
                        },
                      });
                    }}
                  />
                  <div className="divisor"></div>
                </div>

                <div className="form-group">
                  <label>Taxa de PIX:</label>
                  <input
                    type="text"
                    placeholder="00,00%"
                    required
                    value={Formatter.formatPercentage(
                      proposal.businessQuiz!.debitRateAtSight
                    )}
                    onChange={(e) => {
                      setProposal({
                        ...proposal,
                        businessQuiz: {
                          ...proposal.businessQuiz!,
                          debitRateAtSight: Formatter.strToNumber(
                            e.target.value
                          ),
                        },
                      });
                    }}
                  />
                  <div className="divisor"></div>
                </div>

                <div className="form-group">
                  <label>Taxa de crédito à vista:</label>
                  <input
                    type="text"
                    placeholder="%"
                    required
                    value={Formatter.formatPercentage(
                      proposal.businessQuiz!.creditRateAtSight
                    )}
                    onChange={(e) => {
                      setProposal({
                        ...proposal,
                        businessQuiz: {
                          ...proposal.businessQuiz!,
                          creditRateAtSight: Formatter.strToNumber(
                            e.target.value
                          ),
                        },
                      });
                    }}
                  />
                  <div className="divisor"></div>
                </div>

                <div className="form-group">
                  <label>Taxa de crédito parcelado (2 a 6 parcelas):</label>
                  <input
                    type="text"
                    placeholder="%"
                    required
                    value={Formatter.formatPercentage(
                      proposal.businessQuiz!.creditRateInstallments
                    )}
                    onChange={(e) => {
                      setProposal({
                        ...proposal,
                        businessQuiz: {
                          ...proposal.businessQuiz!,
                          creditRateInstallments: Formatter.strToNumber(
                            e.target.value
                          ),
                        },
                      });
                    }}
                  />
                  <div className="divisor"></div>
                </div>

                <div className="form-group">
                  <label>Taxa de crédito parcelado (7 a 12 parcelas):</label>
                  <input
                    type="text"
                    placeholder="%"
                    required
                    value={Formatter.formatPercentage(
                      proposal.businessQuiz!.creditRateInstallmentsTwo
                    )}
                    onChange={(e) => {
                      setProposal({
                        ...proposal,
                        businessQuiz: {
                          ...proposal.businessQuiz!,
                          creditRateInstallmentsTwo: Formatter.strToNumber(
                            e.target.value
                          ),
                        },
                      });
                    }}
                  />
                  <div className="divisor"></div>
                </div>

                <div className="form-group">
                  <label>Possui algum tipo de endividamento?</label>
                  <div className="radio-group">
                    <label>
                      <input
                        type="radio"
                        name="endividamento"
                        required
                        checked={proposal.businessQuiz.hasDebt === true}
                        value="Sim"
                        onChange={() => {
                          setProposal({
                            ...proposal,
                            businessQuiz: {
                              ...proposal.businessQuiz!,
                              hasDebt: true,
                            },
                          });
                        }}
                      />
                      Sim
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="endividamento"
                        required
                        checked={proposal.businessQuiz.hasDebt === false}
                        value="Não"
                        onChange={() => {
                          setProposal({
                            ...proposal,
                            businessQuiz: {
                              ...proposal.businessQuiz!,
                              hasDebt: false,
                            },
                          });
                        }}
                      />
                      Não
                    </label>
                  </div>
                  <div className="divisor"></div>
                </div>

                {proposal.businessQuiz!.hasDebt && (
                  <>
                    <div className="form-group">
                      <label>Valor da dívida:</label>
                      <input
                        type="text"
                        placeholder="R$"
                        required
                        value={Formatter.formatNumberToBRCurrency(
                          proposal.businessQuiz!.debtValue
                        )}
                        onChange={(e) => {
                          setProposal({
                            ...proposal,
                            businessQuiz: {
                              ...proposal.businessQuiz!,
                              debtValue: Formatter.strToNumber(e.target.value),
                            },
                          });
                        }}
                      />
                      <div className="divisor"></div>
                    </div>

                    <div className="form-group">
                      <label>Cheque especial?</label>
                      <div className="radio-group">
                        <label>
                          <input
                            type="radio"
                            name="cheque-espec"
                            required
                            checked={
                              proposal.businessQuiz.hasSpecialCheck === true
                            }
                            value="Sim"
                            onChange={() => {
                              setProposal({
                                ...proposal,
                                businessQuiz: {
                                  ...proposal.businessQuiz!,
                                  hasSpecialCheck: true,
                                },
                              });
                            }}
                          />
                          Sim
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="cheque-espec"
                            required
                            checked={
                              proposal.businessQuiz.hasSpecialCheck === false
                            }
                            value="Não"
                            onChange={() => {
                              setProposal({
                                ...proposal,
                                businessQuiz: {
                                  ...proposal.businessQuiz!,
                                  hasSpecialCheck: false,
                                },
                              });
                            }}
                          />
                          Não
                        </label>
                      </div>
                      <div className="divisor"></div>
                    </div>

                    {proposal.businessQuiz!.hasSpecialCheck && (
                      <>
                        <div className="form-group">
                          <label>Valor do cheque especial:</label>
                          <input
                            type="text"
                            required
                            placeholder="R$"
                            value={Formatter.formatNumberToBRCurrency(proposal.businessQuiz!.specialCheckValue)}
                            onChange={(e) => {
                              setProposal({
                                ...proposal,
                                businessQuiz: {
                                  ...proposal.businessQuiz!,
                                  specialCheckValue: Formatter.strToNumber(e.target.value),
                                },
                              });
                            }}
                          />
                          <div className="divisor"></div>
                        </div>
                      </>
                    )}

                    <div className="form-group">
                      <label>Conta garantida?</label>
                      <div className="radio-group">
                        <label>
                          <input
                            type="radio"
                            name="conta-garantida"
                            required
                            checked={
                              proposal.businessQuiz.hasGuaranteedAccount ===
                              true
                            }
                            value="Sim"
                            onChange={() => {
                              setProposal({
                                ...proposal,
                                businessQuiz: {
                                  ...proposal.businessQuiz!,
                                  hasGuaranteedAccount: true,
                                },
                              });
                            }}
                          />
                          Sim
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="conta-garantida"
                            required
                            checked={
                              proposal.businessQuiz.hasGuaranteedAccount ===
                              false
                            }
                            value="Não"
                            onChange={() => {
                              setProposal({
                                ...proposal,
                                businessQuiz: {
                                  ...proposal.businessQuiz!,
                                  hasGuaranteedAccount: false,
                                },
                              });
                            }}
                          />
                          Não
                        </label>
                      </div>
                      <div className="divisor"></div>
                    </div>

                    {proposal.businessQuiz!.hasGuaranteedAccount && (
                      <>
                        <div className="form-group">
                          <label>Valor da conta garantida:</label>
                          <input
                            type="text"
                            required
                            placeholder="R$"
                            value={Formatter.formatNumberToBRCurrency(proposal.businessQuiz!.guaranteedAccountValue)}
                            onChange={(e) => {
                              setProposal({
                                ...proposal,
                                businessQuiz: {
                                  ...proposal.businessQuiz!,
                                  guaranteedAccountValue: Formatter.strToNumber(e.target.value),
                                },
                              });
                            }}
                          />
                          <div className="divisor"></div>
                        </div>
                      </>
                    )}

                    <div className="form-group">
                      <label>Outros empréstimos?</label>
                      <div className="radio-group">
                        <label>
                          <input
                            type="radio"
                            name="outros-emprest"
                            required
                            checked={
                              proposal.businessQuiz.hasOtherLoans === true
                            }
                            value="Sim"
                            onChange={() => {
                              setProposal({
                                ...proposal,
                                businessQuiz: {
                                  ...proposal.businessQuiz!,
                                  hasOtherLoans: true,
                                },
                              });
                            }}
                          />
                          Sim
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="outros-emprest"
                            required
                            checked={
                              proposal.businessQuiz.hasOtherLoans === false
                            }
                            value="Não"
                            onChange={() => {
                              setProposal({
                                ...proposal,
                                businessQuiz: {
                                  ...proposal.businessQuiz!,
                                  hasOtherLoans: false,
                                },
                              });
                            }}
                          />
                          Não
                        </label>
                      </div>
                      <div className="divisor"></div>
                    </div>

                    {proposal.businessQuiz!.hasOtherLoans && (
                      <>
                        <div className="form-group">
                          <label>Valor de outros empréstimos:</label>
                          <input
                            type="text"
                            required
                            placeholder="R$"
                            value={Formatter.formatNumberToBRCurrency(proposal.businessQuiz!.otherLoansValue)}
                            onChange={(e) => {
                              setProposal({
                                ...proposal,
                                businessQuiz: {
                                  ...proposal.businessQuiz!,
                                  otherLoansValue: Formatter.strToNumber(e.target.value),
                                },
                              });
                            }}
                          />
                          <div className="divisor"></div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}

        {step === 9 && (
          <>
            <h2>Preencha as informações patrimoniais:</h2>
            <div className="form-group">
              <label>É profissional liberal?</label>
              <div className="radio-group">
                <label>
                  <input
                    type="radio"
                    name="profissional-lib"
                    required
                    checked={proposal.freelancerQuiz !== null}
                    value="Sim"
                    onChange={() => {
                      setProposal({
                        ...proposal,
                        freelancerQuiz: new FreelancerQuiz(),
                      });
                    }}
                  />{" "}
                  Sim
                </label>
                <label>
                  <input
                    type="radio"
                    name="profissional-lib"
                    required
                    checked={proposal.freelancerQuiz === null}
                    value="Não"
                    onChange={() => {
                      setProposal({
                        ...proposal,
                        freelancerQuiz: null,
                      });
                    }}
                  />{" "}
                  Não
                </label>
              </div>
              <div className="divisor"></div>
            </div>

            {proposal.freelancerQuiz && (
              <>
                <div className="form-group">
                  <label>Profissão:</label>
                  <input
                    type="text"
                    placeholder="Digite uma profissão"
                    required
                    value={proposal.freelancerQuiz.profession}
                    onChange={(e) => {
                      setProposal({
                        ...proposal,
                        freelancerQuiz: {
                          ...proposal.freelancerQuiz!,
                          profession: e.target.value,
                        },
                      });
                    }}
                  />
                  <div className="divisor"></div>
                </div>

                <div className="form-group">
                  <label>Possui algum tipo de endividamento?</label>
                  <div className="radio-group">
                    <label>
                      <input
                        type="radio"
                        name="endividamento"
                        required
                        checked={proposal.freelancerQuiz.hasDebt === true}
                        value="Sim"
                        onChange={() => {
                          setProposal({
                            ...proposal,
                            freelancerQuiz: {
                              ...proposal.freelancerQuiz!,
                              hasDebt: true,
                            },
                          });
                        }}
                      />{" "}
                      Sim
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="endividamento"
                        checked={proposal.freelancerQuiz.hasDebt === false}
                        value="Não"
                        required
                        onChange={() => {
                          setProposal({
                            ...proposal,
                            freelancerQuiz: {
                              ...proposal.freelancerQuiz!,
                              hasDebt: false,
                            },
                          });
                        }}
                      />{" "}
                      Não
                    </label>
                  </div>
                  <div className="divisor"></div>
                </div>

                {proposal.freelancerQuiz.hasDebt && (
                  <>
                    <div className="form-group">
                      <label>Valor da dívida:</label>
                      <input
                        type="text"
                        placeholder="R$"
                        required
                        value={Formatter.formatNumberToBRCurrency(
                          proposal.freelancerQuiz.debtValue
                        )}
                        onChange={(e) => {
                          setProposal({
                            ...proposal,
                            freelancerQuiz: {
                              ...proposal.freelancerQuiz!,
                              debtValue: Formatter.strToNumber(e.target.value),
                            },
                          });
                        }}
                      />
                      <div className="divisor"></div>
                    </div>

                    {/* Cheque Especial */}
                    <div className="form-group">
                      <label>Cheque especial?</label>
                      <div className="radio-group">
                        <label>
                          <input
                            type="radio"
                            name="cheq-espec"
                            required
                            checked={proposal.freelancerQuiz.hasOverdraft === true}
                            value="Sim"
                            onChange={() => {
                              setProposal({
                                ...proposal,
                                freelancerQuiz: {
                                  ...proposal.freelancerQuiz!,
                                  hasOverdraft: true,
                                },
                              });
                            }}
                          />
                          Sim
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="cheq-espec"
                            required
                            checked={proposal.freelancerQuiz.hasOverdraft === false}
                            value="Não"
                            onChange={() => {
                              setProposal({
                                ...proposal,
                                freelancerQuiz: {
                                  ...proposal.freelancerQuiz!,
                                  hasOverdraft: false,
                                },
                              });
                            }}
                          />
                          Não
                        </label>
                      </div>
                      <div className="divisor"></div>
                    </div>

                    {proposal.freelancerQuiz.hasOverdraft && (
                      <div className="form-group">
                        <label>Valor do cheque especial:</label>
                        <input
                          type="text"
                          required
                          placeholder="R$"
                          value={Formatter.formatNumberToBRCurrency(proposal.freelancerQuiz.overdraftValue)} // Usando overdraftValue
                          onChange={(e) => {
                            setProposal({
                              ...proposal,
                              freelancerQuiz: {
                                ...proposal.freelancerQuiz!,
                                overdraftValue: Formatter.strToNumber(e.target.value), // Usando overdraftValue
                              },
                            });
                          }}
                        />
                        <div className="divisor"></div>
                      </div>
                    )}

                    {/* Conta Garantida */}
                    <div className="form-group">
                      <label>Conta garantida?</label>
                      <div className="radio-group">
                        <label>
                          <input
                            type="radio"
                            name="conta-garan"
                            required
                            checked={proposal.freelancerQuiz.hasGuaranteedAccount === true}
                            value="Sim"
                            onChange={() => {
                              setProposal({
                                ...proposal,
                                freelancerQuiz: {
                                  ...proposal.freelancerQuiz!,
                                  hasGuaranteedAccount: true,
                                },
                              });
                            }}
                          />
                          Sim
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="conta-garan"
                            required
                            checked={proposal.freelancerQuiz.hasGuaranteedAccount === false}
                            value="Não"
                            onChange={() => {
                              setProposal({
                                ...proposal,
                                freelancerQuiz: {
                                  ...proposal.freelancerQuiz!,
                                  hasGuaranteedAccount: false,
                                },
                              });
                            }}
                          />
                          Não
                        </label>
                      </div>
                      <div className="divisor"></div>
                    </div>

                    {proposal.freelancerQuiz.hasGuaranteedAccount && (
                      <div className="form-group">
                        <label>Valor da conta garantida:</label>
                        <input
                          type="text"
                          required
                          placeholder="R$"
                          value={Formatter.formatNumberToBRCurrency(proposal.freelancerQuiz.guaranteedAccountValue)} // Usando guaranteedAccountValue
                          onChange={(e) => {
                            setProposal({
                              ...proposal,
                              freelancerQuiz: {
                                ...proposal.freelancerQuiz!,
                                guaranteedAccountValue: Formatter.strToNumber(e.target.value), // Usando guaranteedAccountValue
                              },
                            });
                          }}
                        />
                        <div className="divisor"></div>
                      </div>
                    )}

                    {/* Outros Empréstimos */}
                    <div className="form-group">
                      <label>Outros empréstimos?</label>
                      <div className="radio-group">
                        <label>
                          <input
                            type="radio"
                            name="outros-empr"
                            required
                            checked={proposal.freelancerQuiz.hasOthers === true}
                            value="Sim"
                            onChange={() => {
                              setProposal({
                                ...proposal,
                                freelancerQuiz: {
                                  ...proposal.freelancerQuiz!,
                                  hasOthers: true,
                                },
                              });
                            }}
                          />
                          Sim
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="outros-empr"
                            required
                            checked={proposal.freelancerQuiz.hasOthers === false}
                            value="Não"
                            onChange={() => {
                              setProposal({
                                ...proposal,
                                freelancerQuiz: {
                                  ...proposal.freelancerQuiz!,
                                  hasOthers: false,
                                },
                              });
                            }}
                          />
                          Não
                        </label>
                      </div>
                      <div className="divisor"></div>
                    </div>

                    {proposal.freelancerQuiz.hasOthers && (
                      <div className="form-group">
                        <label>Valor de outros empréstimos:</label>
                        <input
                          type="text"
                          required
                          placeholder="R$"
                          value={Formatter.formatNumberToBRCurrency(proposal.freelancerQuiz.othersValue)} // Usando othersValue
                          onChange={(e) => {
                            setProposal({
                              ...proposal,
                              freelancerQuiz: {
                                ...proposal.freelancerQuiz!,
                                othersValue: Formatter.strToNumber(e.target.value), // Usando othersValue
                              },
                            });
                          }}
                        />
                        <div className="divisor"></div>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>

        )}

        {step === 10 && (
          <>
            <h2>Preencha as informações relevantes:</h2>
            <div className="form-group">
              <label>Importante: use este campo para fornecer informações relevantes que ainda não foram preenchidas.</label>
              <textarea
                placeholder="Adicione suas observações aqui"
                value={proposal.observation}
                onChange={(e) =>
                  setProposal({ ...proposal, observation: e.target.value })
                }
                rows={8}
                cols={50}
              />
              <div className="divisor"></div>
            </div>
          </>
        )}

        {step === 11 && (
          <>
            <h2>Escolha da unidade:</h2>
            <div className="form-group">
              <label>Imóvel:</label>
              {/* here */}
              <select required
                value={selectedEnterprise}
                onChange={(e) => getEnterprise(e.target.value)}>
                <option value="">Selecione um imóvel</option>
                {enterprises.map((enterprise) => (
                  <option key={enterprise.id} value={enterprise.id}>
                    {enterprise.name}
                  </option>
                ))}
              </select>
              <div className="divisor"></div>
            </div>

            <div className="form-group">
              <label>Unidades disponíveis:</label>
              <select required
                value={selectedUnit}
                onChange={(e) => setUnit(e.target.value)}>
                <option value="">Unidades disponíveis:</option>
                {proposal.enterprise.units.map((unit) => (
                  <option key={unit.id} value={unit.id}>
                    {unit.description}
                  </option>
                ))}
              </select>
              <div className="divisor"></div>
            </div>

            <div className="form-group">
              <label>Terá entrada?</label>
              <div className="radio-group">
                <label>
                  <input
                    type="radio"
                    name="tera-entrada"
                    required
                    checked={proposal.paymentSignalMethods.length > 0}
                    value="Sim"
                    onChange={() => {
                      setProposal({
                        ...proposal,
                        paymentSignalMethods: [new PaymentSignal(true)],
                      });
                    }}
                  />{" "}
                  Sim
                </label>
                <label>
                  <input
                    type="radio"
                    name="tera-entrada"
                    required
                    checked={proposal.paymentSignalMethods.length === 0}
                    value="Não"
                    onChange={() => {
                      setProposal({
                        ...proposal,
                        paymentSignalMethods: [],
                      });
                    }}
                  />{" "}
                  Não
                </label>
              </div>
              <div className="divisor"></div>
            </div>

            {proposal.paymentSignalMethods.length > 0 && (
              <>
                {proposal.paymentSignalMethods.map((signal, index) => (
                  <div key={index}>
                    <div className="form-group">
                      <label>Forma de pagamento:</label>
                      <select
                        required
                        name={`paymentMethod-${index}`}
                        value={selectedPaymentMethod}
                        onChange={(e) =>
                          updatePaymentSignalMethod(
                            index,
                            "method",
                            new SignalMethod(e.target.value)
                          )
                        }
                      >
                        <option value="">Selecione a forma de pagamento</option>
                        <option value="debitCard">Cartão de Débito</option>
                        <option value="creditCard">Cartão de Crédito</option>
                        <option value="transfer">Transferência Bancária</option>
                        <option value="boleto">Boleto</option>
                        <option value="pix">Pix</option>
                      </select>
                      <div className="divisor"></div>
                    </div>
                    {checkIfPaymentMethodIsCard(
                      proposal.paymentSignalMethods[index].method
                    ) && (
                        <div className="form-group">
                          <label>O cartão pertence a quem?</label>
                          <select
                            required
                            value={selectedOwnership}
                            name={`ownership-${index}`}
                            onChange={(e) =>
                              updatePaymentSignalMethod(
                                index,
                                "ownership",
                                new Ownership(e.target.value)
                              )
                            }
                          >
                            <option value="">Selecione</option>
                            <option value="own">Cliente</option>
                            <option value="third">Terceiro</option>
                          </select>
                          <div className="divisor"></div>
                        </div>
                      )}

                    {checkIfPaymentMethodIsCard(
                      proposal.paymentSignalMethods[index].method
                    ) &&
                      proposal.paymentSignalMethods[
                        index
                      ].ownership?.getMethod() === "third" && (
                        <div className="form-group">
                          <label>Informe o CPF do terceiro:</label>
                          <InputMask
                            mask="999.999.999-99"
                            required
                            name={`thirdPartyDocument-${index}`}
                            value={proposal.paymentSignalMethods[index].thirdPartyDocument}
                            onChange={(e) =>
                              updatePaymentSignalMethod(
                                index,
                                "thirdPartyDocument",
                                e.target.value
                              )
                            }
                            onBlur={(e) =>
                              validateCpf(e.target.value)
                            }
                            placeholder="000.000.000-00"
                          >
                            {(inputProps) => (
                              <input {...inputProps} type="text" />
                            )}
                          </InputMask>
                          <div className="divisor"></div>
                        </div>
                      )}

                    <div className="form-group">
                      <label>Valor:</label>
                      <input
                        type="text"
                        required
                        name={`amount-${index}`}
                        value={Formatter.formatNumberToBRCurrency(
                          proposal.paymentSignalMethods[index].amount
                        )}
                        onChange={(e) =>
                          updatePaymentSignalMethod(
                            index,
                            "amount",
                            Formatter.strToNumber(e.target.value)
                          )
                        }
                        placeholder="R$ 0,00"
                      />
                      <div className="divisor"></div>
                    </div>

                    {proposal.paymentSignalMethods[index].method.getMethod() === "creditCard" && (
                      <div className="form-group">
                        <label>Em quantas parcelas?</label>
                        <input
                          type="number"
                          required
                          name={`installments-${index}`}
                          value={proposal.paymentSignalMethods[index].installments}
                          onChange={(e) => {
                            const newValue = Number(e.target.value);
                            updatePaymentSignalMethod(
                              index,
                              "installments",
                              newValue > 18 ? 18 : newValue
                            );
                          }}
                          min="1"
                          max="18"
                        />
                        <div className="divisor"></div>
                      </div>
                    )}
                    {proposal.paymentSignalMethods[index].method.getMethod() === "creditCard" && (
                      <>
                        <div className="form-group">
                          <label>Data de pagamento da 1º parcela:</label>
                          <input
                            type="date"
                            required
                            name={`date-${index}`}
                            value={
                              proposal.paymentSignalMethods[index].date
                                ? Formatter.timeStampToInputDate(
                                  proposal.paymentSignalMethods[index].date
                                )
                                : ""
                            }
                            onChange={(e) => {
                              updatePaymentSignalMethod(
                                index,
                                "date",
                                Formatter.dateStringToTimestamp(e.target.value)
                              );
                            }}
                          />
                          <div className="divisor"></div>
                        </div>
                      </>
                    )}


                    <div className="form-group">
                      <label>Adicionar mais um método de pagamento?</label>
                      <div className="radio-group">
                        <label>
                          <input
                            type="radio"
                            name={`addMorePaymentMethods-${index}`}
                            value="Sim"
                            checked={
                              proposal.paymentSignalMethods.length - 1 !== index
                            }
                            onChange={() => {
                              setProposal({
                                ...proposal,
                                paymentSignalMethods: [
                                  ...proposal.paymentSignalMethods,
                                  new PaymentSignal(true, new SignalMethod("")),
                                ],
                              });
                            }}
                          />{" "}
                          Sim
                        </label>
                        <label>
                          <input
                            type="radio"
                            name={`addMorePaymentMethods-${index}`}
                            value="Não"
                            checked={
                              proposal.paymentSignalMethods.length - 1 === index
                            }
                            onChange={() => {
                              if (proposal.paymentSignalMethods.length > 1) {
                                setProposal({
                                  ...proposal,
                                  paymentSignalMethods:
                                    proposal.paymentSignalMethods.filter(
                                      (item, i) => i !== index
                                    ),
                                });
                              }
                            }}
                          />{" "}
                          Não
                        </label>
                      </div>
                      <div className="divisor"></div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </>
        )}

        {step === 12 && (
          <>
            <div id="step-12">
              <div className="resume">
                <h2>Resumo das Informações:</h2>

                <AccordionItemCreate title="Informações pessoais">
                  <div className="groups">
                    {/* Step 01: CPF */}
                    <p>
                      <strong>CPF:</strong> {cpf}
                    </p>
                    {/* Step 02: Dados pessoais */}
                    <p>
                      <strong>Nome:</strong> {proposal.lead.name}
                    </p>
                    <p>
                      <strong>Email:</strong> {proposal.lead.email}
                    </p>
                    <p>
                      <strong>Data de nascimento:</strong>{" "}
                      {Formatter.timestampToDateBR(proposal.lead.birthDate)}
                    </p>
                    <p>
                      <strong>Nome da mãe:</strong> {proposal.lead.motherName}
                    </p>
                    <p>
                      <strong>Celular:</strong> {proposal.lead.phone}
                    </p>
                    <p>
                      <strong>Endereço:</strong> {`${proposal.lead.address.street}, ${proposal.lead.address.number} - 
                      ${proposal.lead.address.neighborhood}, ${proposal.lead.address.city}/${proposal.lead.address.uf} - ${proposal.lead.address.zipCode} `}
                    </p>
                  </div>
                </AccordionItemCreate>

                <AccordionItemCreate title="Renda mensal e patrimônios">
                  {/* Step 03: Renda e Patrimonio */}
                  <div className="groups">
                    <p>
                      <strong>Renda mensal:</strong>{" "}
                      {Formatter.formatNumberToBRCurrency(
                        proposal.lead.monthlyIncome
                      )}
                    </p>
                    <p>
                      <strong>Patrimonio:</strong>{" "}
                      {Formatter.formatNumberToBRCurrency(
                        proposal.lead.patrimony
                      )}
                    </p>
                  </div>
                </AccordionItemCreate>

                <AccordionItemCreate title="Informações de veículos">
                  {/* Step 04: Informações de veiculos */}
                  <div className="groups">
                    <p>
                      <strong>Possui veículo?</strong>{" "}
                      {proposal.vehicleQuiz ? "Sim" : "Não"}
                    </p>
                    {proposal.vehicleQuiz &&
                      proposal.vehicleQuiz.length > 0 && (
                        <>
                          {proposal.vehicleQuiz.map((vehicle, index) => (
                            <div key={index}>
                              <div className="divisor-accordion"></div>
                              <p>
                                <strong>Valor do veículo:</strong>{" "}
                                {Formatter.formatNumberToBRCurrency(
                                  vehicle.value
                                )}
                              </p>
                              <p>
                                <strong>Fabricante:</strong>{" "}
                                {vehicle.manufacturer}
                              </p>
                              <p>
                                <strong>Modelo:</strong> {vehicle.model}
                              </p>
                              <p>
                                <strong>Ano:</strong> {vehicle.year}
                              </p>
                              <p>
                                <strong>KM:</strong> {vehicle.km}
                              </p>
                              <p>
                                <strong>O veículo é próprio?</strong>{" "}
                                {vehicle.isOwned ? "Sim" : "Não"}

                              </p>
                              {!vehicle.isOwned && (
                                <>
                                  <p>
                                    <div className="divisor-accordion"></div>
                                    <strong>CPF do terceiro:</strong>{" "}
                                    {vehicle.thirdPartyDocument}
                                  </p>
                                </>
                              )}
                              <p>
                                <strong>
                                  Será usado como parte do pagamento do imóvel?
                                </strong>{" "}
                                {vehicle.useAsPayment ? "Sim" : "Não"}
                              </p>
                              <p>
                                <strong>O veículo é financiado?</strong>{" "}
                                {vehicle.isFinanced ? "Sim" : "Não"}
                              </p>
                              <p>
                                <strong>Deseja refinanciar?</strong>{" "}
                                {vehicle.wantsRefinance ? "Sim" : "Não"}
                              </p>
                              {vehicle.wantsRefinance && (
                                <>
                                  <p>
                                    <strong>Valor do refinanciamento:</strong>{" "}
                                    {Formatter.formatNumberToBRCurrency(
                                      vehicle.refinanceValue
                                    )}
                                  </p>
                                </>
                              )}
                            </div>
                          ))}
                        </>
                      )}
                  </div>
                </AccordionItemCreate>

                <AccordionItemCreate title="Informações de imóveis">
                  {/* Step 05: Informações de imoveis */}
                  <div className="groups">
                    <p>
                      <strong>Possui imóvel?</strong>{" "}
                      {proposal.propertyQuiz.length > 0 ? "Sim" : "Não"}
                    </p>
                    {proposal.propertyQuiz.length > 0 && (
                      <>
                        {proposal.propertyQuiz.map((property, index) => (
                          <div key={index}>
                            <div className="divisor-accordion"></div>
                            <p>
                              <strong>Valor do imóvel {index + 1}:</strong>{" "}
                              {Formatter.formatNumberToBRCurrency(property.value)}
                            </p>
                            <p>
                              <strong>O imóvel é próprio?</strong>{" "}
                              {property.isOwned ? "Sim" : "Não"}
                            </p>
                            {!property.isOwned && (
                              <>
                                <p>
                                  <div className="divisor-accordion"></div>
                                  <strong>CPF do terceiro:</strong>{" "}
                                  {property.thirdPartyDocument}
                                </p>
                              </>
                            )}

                            <p>
                              <strong>O imóvel é financiado?</strong>{" "}
                              {property.isFinanced ? "Sim" : "Não"}
                            </p>
                            {!property.isFinanced && (
                              <>
                                <p>
                                  <strong>Deseja refinanciar?</strong>{" "}
                                  {property.wantsRefinance ? "Sim" : "Não"}
                                </p>
                              </>
                            )}

                            {property.wantsRefinance && (
                              <>
                                <p>
                                  <strong>Valor do refinanciamento:</strong>{" "}
                                  {Formatter.formatNumberToBRCurrency(property.refinanceValue)}
                                </p>
                              </>
                            )}
                            <p>
                              <strong>
                                Será usado como parte do pagamento do imóvel?
                              </strong>{" "}
                              {property.useAsPayment ? "Sim" : "Não"}
                            </p>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </AccordionItemCreate>

                <AccordionItemCreate title="Informações de FGTS">
                  {/* Step 06: Informações do FGTS */}
                  <div className="groups">
                    <p>
                      <strong>Possui FGTS?</strong>{" "}
                      {proposal.fgtsQuiz ? "Sim" : "Não"}
                    </p>
                    {proposal.fgtsQuiz && (
                      <>
                        <div className="divisor-accordion"></div>
                        <p>
                          <strong>Saldo disponível:</strong>{" "}
                          {Formatter.formatNumberToBRCurrency(
                            proposal.fgtsQuiz!.balance
                          )}
                        </p>
                        <p>
                          <strong>Possui saque aniversário?</strong>{" "}
                          {proposal.fgtsQuiz!.hasAnniversaryWithdrawal
                            ? "Sim"
                            : "Não"}
                        </p>
                        {proposal.fgtsQuiz!.hasAnniversaryWithdrawal && (
                          <>
                            <p>
                              <strong>
                                Tem interesse em usar como parte do pagamento?
                              </strong>{" "}
                              {proposal.fgtsQuiz!.useAsPayment ? "Sim" : "Não"}
                            </p>
                            <p>
                              <strong>
                                Irá usar para amortização na entrega das chaves?
                              </strong>{" "}
                              {proposal.fgtsQuiz!.useForAmortization
                                ? "Sim"
                                : "Não"}
                            </p>
                            <div className="divisor-accordion"></div>
                            <p>
                              <strong>
                                Outra pessoa que possa realizar em conjunto
                                essas operações?
                              </strong>{" "}
                              {proposal.fgtsQuiz!.hasWithdrawalTwo
                                ? "Sim"
                                : "Não"}
                            </p>
                            {proposal.fgtsQuiz!.hasWithdrawalTwo && (
                              <>
                                <p>
                                  <strong>Saldo disponível:</strong>{" "}
                                  {Formatter.formatNumberToBRCurrency(
                                    proposal.fgtsQuiz!.balanceTwo
                                  )}
                                </p>
                                <p>
                                  <strong>CPF do terceiro:</strong>{" "}
                                  {proposal.fgtsQuiz!.thirdPartyDocument}
                                </p>
                              </>
                            )}
                            <p>
                              <strong>Possui saque aniversário?</strong>{" "}
                              {proposal.fgtsQuiz!.hasAnniversaryWithdrawalTwo
                                ? "Sim"
                                : "Não"}
                            </p>
                            <p>
                              <strong>
                                Tem interesse em usar como parte do pagamento?
                              </strong>{" "}
                              {proposal.fgtsQuiz!.useAsPaymentTwo
                                ? "Sim"
                                : "Não"}
                            </p>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </AccordionItemCreate>

                <AccordionItemCreate title="Informações de empréstimo consignado">
                  {/* Step 07: Informações de empréstimo consignado */}
                  <div className="groups">
                    <p>
                      <strong>Possui empréstimo consignado?</strong>{" "}
                      {proposal.loanQuiz ? "Sim" : "Não"}
                    </p>
                    {proposal.loanQuiz && (
                      <>
                        <div className="divisor-accordion"></div>
                        <p>
                          <strong>É próprio?</strong>{" "}
                          {proposal.loanQuiz!.isOwned ? "Sim" : "Não"}
                        </p>
                        <p>
                          <strong>Número da matrícula:</strong>{" "}
                          {proposal.loanQuiz!.registrationNumber}
                        </p>
                        <p>
                          <strong>ID da Averbadora:</strong>{" "}
                          {proposal.loanQuiz!.averbId}
                        </p>
                        <div className="divisor-accordion"></div>
                        {/* <p>
                          <strong>É funcionário público?</strong>{" "}
                          {proposal.loanQuiz!.isPublicServant ? "Sim" : "Não"}
                        </p>
                        {proposal.loanQuiz!.isPublicServant && (
                          <>
                            <p>
                              <strong>Em qual esfera atua?</strong>{" "}
                              {proposal.loanQuiz!.sphereOfAction}
                            </p>
                            <p>
                              <strong>Qual órgão?</strong>{" "}
                              {proposal.loanQuiz!.agency}
                            </p>
                          </>
                        )} */}
                      </>
                    )}
                  </div>
                </AccordionItemCreate>

                <AccordionItemCreate title="Informações patrimôniais">
                  {/* Step 08: Informações de empréstimo consignado */}
                  <div className="groups">
                    <p>
                      <strong>É empresário(a)?</strong>{" "}
                      {proposal.businessQuiz ? "Sim" : "Não"}
                    </p>
                    <div className="divisor-accordion"></div>
                    {proposal.businessQuiz && (
                      <>
                        <p>
                          <strong>CNPJ:</strong> {proposal.businessQuiz.cnpj}
                        </p>
                        <p>
                          <strong>Tipo de empresa:</strong>{" "}
                          {proposal.businessQuiz.companyType}
                        </p>
                        <p>
                          <strong>
                            Faturamento mensal dos últimos 3 meses:
                          </strong>{" "}
                          {Formatter.formatNumberToBRCurrency(
                            proposal.businessQuiz.lastThreeMonthsRevenue
                          )}
                        </p>
                        <p>
                          <strong>Faturamento em cartões:</strong>{" "}
                          {Formatter.formatNumberToBRCurrency(
                            proposal.businessQuiz.cardsRevenue
                          )}
                        </p>
                        <p>
                          <strong>Adquirente atual:</strong>{" "}
                          {proposal.businessQuiz.currentAcquirer}
                        </p>
                        <p>
                          <strong>Taxa de débito:</strong>{" "}
                          {proposal.businessQuiz.debitRate
                            ? Formatter.formatPercentage(
                              proposal.businessQuiz.debitRate
                            )
                            : "0"}
                          %
                        </p>
                        <p>
                          <strong>Taxa de PIX:</strong>{" "}
                          {proposal.businessQuiz.debitRateAtSight
                            ? Formatter.formatPercentage(
                              proposal.businessQuiz.debitRateAtSight
                            )
                            : "0"}
                          %
                        </p>
                        <p>
                          <strong>Taxa de crédito à vista:</strong>{" "}
                          {proposal.businessQuiz.creditRateAtSight
                            ? Formatter.formatPercentage(
                              proposal.businessQuiz.creditRateAtSight
                            )
                            : "0"}
                          %
                        </p>
                        <p>
                          <strong>
                            Taxa de crédito parcelado (2 a 6 parcelas):
                          </strong>{" "}
                          {proposal.businessQuiz.creditRateInstallments
                            ? Formatter.formatPercentage(
                              proposal.businessQuiz.creditRateInstallments
                            )
                            : "0"}
                          $
                        </p>
                        <p>
                          <strong>
                            Taxa de crédito parcelado (7 a 12 parcelas):
                          </strong>{" "}
                          {proposal.businessQuiz.creditRateInstallmentsTwo
                            ? Formatter.formatPercentage(
                              proposal.businessQuiz.creditRateInstallmentsTwo
                            )
                            : "0"}
                          %
                        </p>
                        <div className="divisor-accordion"></div>
                        <p>
                          <strong>Possui algum tipo de endividamento?</strong>{" "}
                          {proposal.businessQuiz.hasDebt ? "Sim" : "Não"}
                        </p>
                        {proposal.businessQuiz.hasDebt && (
                          <>
                            <p>
                              <strong>Valor da dívida:</strong>{" "}
                              {Formatter.formatNumberToBRCurrency(
                                proposal.businessQuiz.debtValue
                              )}
                            </p>
                            <p>
                              <strong>Cheque especial?</strong>{" "}
                              {proposal.businessQuiz.hasSpecialCheck
                                ? "Sim"
                                : "Não"}
                            </p>
                            {proposal.businessQuiz.hasSpecialCheck && (
                              <p>
                                <strong>Valor do cheque especial:</strong>{" "}
                                {Formatter.formatNumberToBRCurrency(proposal.businessQuiz.specialCheckValue)}
                              </p>
                            )}
                            <p>
                              <strong>Conta garantida?</strong>{" "}
                              {proposal.businessQuiz.hasGuaranteedAccount
                                ? "Sim"
                                : "Não"}
                            </p>
                            {proposal.businessQuiz.hasGuaranteedAccount && (
                              <p>
                                <strong>Valor da conta garantida:</strong>{" "}
                                {Formatter.formatNumberToBRCurrency(proposal.businessQuiz.guaranteedAccountValue)}
                              </p>
                            )}
                            <p>
                              <strong>Outros empréstimos?</strong>{" "}
                              {proposal.businessQuiz.hasOtherLoans
                                ? "Sim"
                                : "Não"}
                            </p>
                            {proposal.businessQuiz.hasOtherLoans && (
                              <p>
                                <strong>Valor dos outros empréstimos:</strong>{" "}
                                {Formatter.formatNumberToBRCurrency(proposal.businessQuiz.otherLoansValue)}
                              </p>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </AccordionItemCreate>

                <AccordionItemCreate title="Outras informações">
                  {/* Step 09: Informações Patrimoniais */}
                  <div className="groups">
                    <p>
                      <strong>É profissional liberal?</strong>{" "}
                      {proposal.freelancerQuiz ? "Sim" : "Não"}
                    </p>
                    <div className="divisor-accordion"></div>
                    {proposal.freelancerQuiz && (
                      <>
                        <p>
                          <strong>Profissão:</strong>{" "}
                          {proposal.freelancerQuiz.profession}
                        </p>
                        <p>
                          <strong>Possui algum tipo de endividamento?</strong>{" "}
                          {proposal.freelancerQuiz.hasDebt ? "Sim" : "Não"}
                        </p>

                        {proposal.freelancerQuiz.hasDebt && (
                          <>
                            <p>
                              <strong>Valor da dívida:</strong>{" "}
                              {Formatter.formatNumberToBRCurrency(
                                proposal.freelancerQuiz.debtValue
                              )}
                            </p>
                            <div className="divisor-accordion"></div>
                            <p>
                              <strong>Cheque especial?</strong>{" "}
                              {proposal.freelancerQuiz.hasOverdraft
                                ? "Sim"
                                : "Não"}
                            </p>
                            {proposal.freelancerQuiz.hasOverdraft && (
                              <p>
                                <strong>Valor do cheque especial:</strong>{" "}
                                {Formatter.formatNumberToBRCurrency(proposal.freelancerQuiz.overdraftValue)}
                              </p>
                            )}
                            <p>
                              <strong>Conta garantida?</strong>{" "}
                              {proposal.freelancerQuiz.hasGuaranteedAccount
                                ? "Sim"
                                : "Não"}
                            </p>
                            {proposal.freelancerQuiz.hasGuaranteedAccount && (
                              <p>
                                <strong>Valor da conta garantida:</strong>{" "}
                                {Formatter.formatNumberToBRCurrency(proposal.freelancerQuiz.guaranteedAccountValue)}
                              </p>
                            )}
                            <p>
                              <strong>Outros empréstimos?</strong>{" "}
                              {proposal.freelancerQuiz.hasOthers
                                ? "Sim"
                                : "Não"}
                            </p>
                            {proposal.freelancerQuiz.hasOthers && (
                              <p>
                                <strong>Valor dos outros empréstimos:</strong>{" "}
                                {Formatter.formatNumberToBRCurrency(proposal.freelancerQuiz.othersValue)}
                              </p>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </AccordionItemCreate>

                <AccordionItemCreate title="Observações">
                  {/* Step 10: Observações */}
                  <p>
                    <strong>Observações:</strong> {proposal.observation}
                  </p>
                </AccordionItemCreate>

                <AccordionItemCreate title="Informações sobre as unidades">
                  {/* Step 11: Informações sobre as unidades */}
                  <div className="groups">
                    <p>
                      <strong>Imóvel:</strong> {proposal.enterprise.name}
                    </p>
                    <p>
                      <strong>Unidades disponíveis:</strong>{" "}
                      {proposal.unit.description}
                    </p>
                  </div>
                </AccordionItemCreate>
                <AccordionItemCreate title="Forma de pagamento">
                  {/* Step 11: Informações sobre as unidades */}
                  <div className="groups">
                    <p>
                      <strong>Terá entrada?</strong>{" "}
                      {proposal.paymentSignalMethods.length > 0 ? "Sim" : "Não"}
                    </p>
                    {proposal.paymentSignalMethods.length > 0 && (
                      <>
                        {proposal.paymentSignalMethods.map((signal, index) => (
                          <div key={index}>
                            {proposal.paymentSignalMethods[index].hasEntry && (<>
                              <p>
                                <strong>Forma de pagamento:</strong>{" "}
                                {proposal.paymentSignalMethods[
                                  index
                                ].method.getFormatted()}
                              </p>
                              <p>
                                <strong>O cartão pertence a quem?</strong>{" "}
                                {proposal.paymentSignalMethods[index].ownership?.getFormatted()}
                              </p>
                              {["Terceiros"].includes(proposal?.paymentSignalMethods[index]?.ownership?.getFormatted() ?? "") && (
                                <p>
                                  <strong>CPF do terceiro:</strong>{" "}
                                  {proposal.paymentSignalMethods[index].thirdPartyDocument}
                                </p>)}
                              <p>
                                <strong>Valor:</strong>{" "}
                                {Formatter.formatNumberToBRCurrency(
                                  proposal.paymentSignalMethods[index].amount
                                )}
                              </p>
                              <p>
                                <strong>Em quantas parcelas?</strong>{" "}
                                {proposal.paymentSignalMethods[index].installments}
                              </p>
                              <p>
                                <strong>Data de pagamento da 1º parcela:</strong>{" "}
                                {Formatter.timestampToDateBR(
                                  proposal.paymentSignalMethods[index].date
                                )}
                              </p>
                            </>)}
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </AccordionItemCreate>
              </div>
            </div>
            <div className="teste">
              <button
                type="button"
                className="back-button-12"
                onClick={prevStep}
              >
                Voltar
              </button>
              <button
                type="button"
                className="next-button-12"
                onClick={handleFinalizar}
              >
                Finalizar
              </button>
            </div>
          </>
        )}

        { }
        <div className="form-footer">
          {step !== 12 && (
            <>
              <button type="button" className="back-button" onClick={prevStep}>
                Voltar
              </button>
              <button type="submit" className="next-button" disabled={loading}>
                {loading ? "Carregando..." : "Avançar"}
              </button>
            </>
          )}
        </div>
      </form >
      {/* Toast Container */}
      < ToastContainer />
    </div >
  );
};

export default CreateProposal;
