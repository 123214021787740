import ConstructionCompany from "../entities/constructionCompany";

export default class ConstructionCompanyDTO {
  public static fromJson(json: any): ConstructionCompany {
    return new ConstructionCompany(
      json.id,
      json.name,
      json.document,
      json.responsibleDocument,
      json.responsibleName,
      json.email,
      json.phone,
      json.address,
      json.createdAt,
      json.status
    );
  }
}
