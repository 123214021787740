export default class BusinessQuiz {
  constructor(
    public cnpj: string | undefined = undefined,
    public companyType: string | undefined = undefined,
    public lastThreeMonthsRevenue: number | undefined = undefined,
    public cardsRevenue: number | undefined = undefined,
    public currentAcquirer: string | undefined = undefined,
    public hasSpecialCheck: boolean | undefined = undefined,
    public hasGuaranteedAccount: boolean | undefined = undefined,
    public hasOtherLoans: boolean | undefined = undefined,
    public hasDebt: boolean | undefined = undefined,
    public debtValue: number | undefined = undefined,
    public debitRate: number | undefined = undefined,
    public debitRateAtSight: number | undefined = undefined,
    public creditRateAtSight: number | undefined = undefined,
    public creditRateInstallments: number | undefined = undefined,
    public creditRateInstallmentsTwo: number | undefined = undefined,
    public specialCheckValue: number | undefined = undefined,
    public guaranteedAccountValue: number | undefined = undefined,
    public otherLoansValue: number | undefined = undefined
  ) {}
}
