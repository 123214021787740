import ProjectPlan from "../entities/projectPlan";
import { Proposal } from "../entities/proposal";
import User from "../entities/user";
import Currency from "../entities/valueObjects/currency";
import PreApprovedValue from "../entities/valueObjects/preApprovedValue";
import ProposalStatus from "../entities/valueObjects/proposalStatus";
import BusinessQuizDTO from "./businessQuizDTO";
import EnterpriseDTO from "./enterpriseDTO";
import FgtsQuizDTO from "./fgtsQuizDTO";
import FreelancerQuizDTO from "./freelancerQuizDTO";
import LeadDTO from "./leadDTO";
import LoanQuizDTO from "./loanQuizDTO";
import PaymentSignalDTO from "./paymentSignalDTO";
import PreApprovedValueDTO from "./preApprovedValueDTO";
import ProjectPlanDTO from "./projectPlanDTO";
import PropertyQuizDTO from "./propertyQuizDTO";
import ProposalHistoryDTO from "./proposalHistoryDTO";
import UnitDTO from "./unitDTO";
import UserDTO from "./userDTO";
import VehicleQuizDTO from "./vehicleQuizDTO";

export default class ProposalDTO {
  static toJson(proposal: Proposal): any {
    return {
      leadId: proposal.lead.id,
      entryAmount: proposal.entryAmount.value,
      unitId: proposal.unit.id,
      fgtsQuiz: proposal.fgtsQuiz,
      propertyQuiz: proposal.propertyQuiz,
      vehicleQuiz: proposal.vehicleQuiz,
      loanQuiz: proposal.loanQuiz,
      freelancerQuiz: proposal.freelancerQuiz,
      businessQuiz: proposal.businessQuiz,
      paymentSignalMethods: proposal.paymentSignalMethods.map(
        (paymentSignal: any) => PaymentSignalDTO.toJson(paymentSignal)
      ),
      observation: proposal.observation,
    };
  }

  static fromJson(proposal: any): Proposal {
    return new Proposal({
      id: proposal.id,
      entryAmount: new Currency(proposal.entryAmount),
      unitPrice: new Currency(proposal.unitPrice),
      status: new ProposalStatus(proposal.status),
      statusDetail: proposal.statusDetail,
      createdBy: proposal.createdB
        ? UserDTO.fromJson(proposal.createdBy)
        : new User(),
      createdAt: proposal.createdAt,
      lead: LeadDTO.fromJson(proposal.lead),
      unit: UnitDTO.fromJson(proposal.unit),
      projectPlan: proposal.projectPlan
        ? ProjectPlanDTO.fromJson(proposal.projectPlan)
        : new ProjectPlan(),
      enterprise: EnterpriseDTO.fromJson(proposal.enterprise),
      preApprovedValues: proposal.preApprovedValues
        ? proposal.preApprovedValues.map((preApprovedValue: any) =>
            PreApprovedValueDTO.fromJson(preApprovedValue)
          )
        : [],
      fgtsQuiz: proposal.fgtsQuiz
        ? FgtsQuizDTO.fromJson(proposal.fgtsQuiz)
        : null,
      propertyQuiz: proposal.propertyQuiz
        ? proposal.propertyQuiz.map((propertyQuiz: any) =>
            PropertyQuizDTO.fromJson(propertyQuiz)
          )
        : [],
      vehicleQuiz: proposal.vehicleQuiz
        ? proposal.vehicleQuiz.map((vehicleQuiz: any) =>
            VehicleQuizDTO.fromJson(vehicleQuiz)
          )
        : [],
      loanQuiz: proposal.loanQuiz
        ? LoanQuizDTO.fromJson(proposal.loanQuiz)
        : null,
      freelancerQuiz: proposal.freelancerQuiz
        ? FreelancerQuizDTO.fromJson(proposal.freelancerQuiz)
        : null,
      businessQuiz: proposal.businessQuiz
        ? BusinessQuizDTO.fromJson(proposal.businessQuiz)
        : null,
      paymentSignalMethods: proposal.paymentSignalMethods
        ? proposal.paymentSignalMethods.map((paymentSignal: any) =>
            PaymentSignalDTO.fromJson(paymentSignal)
          )
        : [],
      observation: proposal.observation,
      observations: proposal.observations,
      documents: proposal.documents,
      history: proposal.history
        ? proposal.history.map((history: any) =>
            ProposalHistoryDTO.fromJson(history)
          )
        : [],
    });
  }
}
