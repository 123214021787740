import ScheduleVisit from "../../domain/entities/scheduleVisit";
import { ListSearch } from "../../domain/entities/valueObjects/listSearch";
import { AxiosHttp } from "../../http/axios";
import { ScheduleVisits } from "../../domain/entities/scheduleVisits";
import ScheduleVisitRepository from "../repository/scheduleVisitRepository";

export default class ScheduleVisitProvider {
  private static instance: ScheduleVisitProvider;
  private scheduleVisitRepository: ScheduleVisitRepository;
  authErrors: { email: string; password: string } = { email: "", password: "" };

  private constructor() {
    this.scheduleVisitRepository = ScheduleVisitRepository.getInstance(
      AxiosHttp.getInstance()
    );
  }

  static getInstance(): ScheduleVisitProvider {
    if (!ScheduleVisitProvider.instance) {
      ScheduleVisitProvider.instance = new ScheduleVisitProvider();
    }
    return ScheduleVisitProvider.instance;
  }

  async getScheduledVisits(listSearch: ListSearch): Promise<ScheduleVisits> {
    return await this.scheduleVisitRepository.getScheduledVisits(listSearch);
  }

  async getScheduledVisitById(scheduleVisitId: string): Promise<ScheduleVisit> {
    return await this.scheduleVisitRepository.getScheduledVisitById(
      scheduleVisitId
    );
  }

  async updateScheduledVisitStatusById(
    id: string,
    newStatus: string,
    obs: string
  ) {
    return await this.scheduleVisitRepository.updateScheduledVisitStatusById(
      id,
      newStatus,
      obs
    );
  }
}
