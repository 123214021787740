import { Address } from "./address";

export default class ConstructionCompany {
  constructor(
    public id = "",
    public name = "",
    public document = "",
    public responsibleDocument = "",
    public responsibleName = "",
    public email = "",
    public phone = "",
    public address = new Address(),
    public createdAt = "",
    public status = ""
  ) {}
}
