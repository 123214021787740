import ConstructionStepDTO from "../../domain/dto/constructionStepDTO";
import EnterpriseDTO from "../../domain/dto/enterpriseDTO";
import EnterprisesDTO from "../../domain/dto/enterprisesDTO";
import ConstructionStep from "../../domain/entities/constructionStep";
import Enterprise from "../../domain/entities/enterprise";
import EnterpriseStatus from "../../domain/entities/valueObjects/enterpriseStatus";
import { ListSearch } from "../../domain/entities/valueObjects/listSearch";
import Http from "../../http/http";
import HttpRequest from "../../http/httpRequest";
import ApiRoutes from "../../http/routes";
import { Enterprises } from "../../domain/entities/enterprises";
import CreateEnterprise from "../../domain/entities/createEnterprise";
import ListSearchDTO from "../../domain/dto/listSearchDTO";
import EnterpriseDocuments from "../../domain/entities/valueObjects/enterpriseDocuments";

export default class EnterpriseRepository {
  private static instance: EnterpriseRepository | null = null;

  constructor(private http: Http) {}

  static getInstance(http: Http): EnterpriseRepository {
    if (!EnterpriseRepository.instance) {
      EnterpriseRepository.instance = new EnterpriseRepository(http);
    }
    return EnterpriseRepository.instance;
  }

  async createEnterprise(enterprise: CreateEnterprise) {
    const request = new HttpRequest(ApiRoutes.enterprise, enterprise);

    return await this.http.post<Enterprise>(request);
  }

  async updateEnterprise(enterprise: Enterprise) {
    const body = {
      name: enterprise.name,
      description: enterprise.description,
      categoryId: enterprise.category.id,
      releaseTypeId: enterprise.releaseType.id,
      constructionCompanyId: enterprise.constructionCompany.id,
      address: enterprise.address,
    };

    const request = new HttpRequest(
      `${ApiRoutes.enterprise}/${enterprise.id}`,
      body
    );

    return await this.http.put<Enterprise>(request);
  }

  async getEnterprises(listSearch: ListSearch): Promise<Enterprises> {
    const request = new HttpRequest(
      ApiRoutes.enterprise,
      undefined,
      ListSearchDTO.toJson(listSearch)
    );

    const response = await this.http.get<Enterprises>(request);
    return EnterprisesDTO.fromJson(response);
  }

  async getAllEnterprises(): Promise<Enterprise[]> {
    const request = new HttpRequest(`${ApiRoutes.enterprise}`);

    const response = await this.http.get<{ data: Enterprise[] }>(request);
    return response.data.map(
      (enterprise) => new Enterprise(enterprise.id, enterprise.name)
    );
  }

  async getEnterprisesName(): Promise<Enterprise[]> {
    const request = new HttpRequest(`${ApiRoutes.enterprise}/names`);

    const response = await this.http.get<Enterprise[]>(request);
    return response.map(
      (enterprise) => new Enterprise(enterprise.id, enterprise.name)
    );
  }

  async getEnterpriseById(id: string): Promise<Enterprise> {
    const request = new HttpRequest(`${ApiRoutes.enterprise}/${id}`);

    const response = await this.http.get<Enterprise>(request);
    return EnterpriseDTO.fromJson(response);
  }

  async updateEnterpriseStatus(id: string, status: EnterpriseStatus) {
    const request = new HttpRequest(
      ApiRoutes.enterpriseStatusUpdate.replace("{id}", id),
      { status: status.getStatus() }
    );

    return await this.http.put<Enterprise>(request);
  }

  async updateEnterpriseReleaseType(id: string, releaseTypeId: string) {
    const request = new HttpRequest(
      ApiRoutes.enterpriseReleaseTypeUpdate.replace("{id}", id),
      { releaseTypeId }
    );

    return await this.http.put<Enterprise>(request);
  }

  async getEnterpriseConstructionSteps(id: string) {
    const request = new HttpRequest(
      ApiRoutes.enterpriseConstructionStep.replace("{id}", id)
    );

    const response = await this.http.get<ConstructionStep[]>(request);
    return response.map((constructionStep) =>
      ConstructionStepDTO.fromJson(constructionStep)
    );
  }

  async getEnterpriseConstructionStepById(
    enterpriseId: string,
    constructionStepId: string
  ) {
    const request = new HttpRequest(
      `${ApiRoutes.enterpriseConstructionStep.replace("{id}", enterpriseId)}/${constructionStepId}`
    );

    const response = await this.http.get<ConstructionStep>(request);
    return ConstructionStepDTO.fromJson(response);
  }

  async createEnterpriseConstructionStep(
    enterpriseId: string,
    constructionStep: ConstructionStep
  ) {
    const request = new HttpRequest(
      ApiRoutes.enterpriseConstructionStep.replace("{id}", enterpriseId),
      constructionStep
    );

    return await this.http.post<ConstructionStep>(request);
  }

  async createSubStep(constructionStepId: string, subStep: ConstructionStep) {
    const request = new HttpRequest(
      ApiRoutes.enterpriseConstructionSubStep.replace(
        "{constructionStepId}",
        constructionStepId
      ),
      subStep
    );

    return await this.http.post<ConstructionStep>(request);
  }

  async updateConstructionStepStatus(
    constructionStepId: string,
    status: string
  ) {
    const request = new HttpRequest(
      ApiRoutes.enterpriseConstructionSubStep.replace(
        "{constructionStepId}",
        constructionStepId
      ),
      { status }
    );

    return await this.http.post<ConstructionStep>(request);
  }

  async addCommentToConstructionSubStep(
    constructionStepId: string,
    body: string
  ) {
    const request = new HttpRequest(
      `${ApiRoutes.enterpriseConstructionSubStep.replace("{constructionStepId}", constructionStepId)}/comment`,
      { body }
    );

    return await this.http.post<ConstructionStep>(request);
  }

  async getSubStepComments(constructionStepId: string) {
    const request = new HttpRequest(
      `${ApiRoutes.enterpriseConstructionSubStep.replace("{constructionStepId}", constructionStepId)}/comment`
    );

    return await this.http.get<string[]>(request);
  }

  async addImageToConstructionSubStep(
    constructionStepId: string,
    image: string | File
  ) {
    const body =
      typeof image === "string" ? { imageLink: image } : { imageBase64: image };
    const request = new HttpRequest(
      `${ApiRoutes.enterpriseConstructionSubStep.replace("{constructionStepId}", constructionStepId)}/image`,
      body
    );

    return await this.http.post<void>(request);
  }

  async getSubStepImages(constructionStepId: string) {
    const request = new HttpRequest(
      `${ApiRoutes.enterpriseConstructionSubStep.replace("{constructionStepId}", constructionStepId)}/image`
    );

    return await this.http.get<string[]>(request);
  }

  async addEnterpriseImage(
    enterpriseId: string,
    image: string | File,
    isFavorite: boolean
  ) {
    const body =
      typeof image === "string"
        ? { imageLink: image, isFavorite }
        : { imageBase64: image, isFavorite };

    const request = new HttpRequest(
      `${ApiRoutes.enterpriseImage.replace("{id}", enterpriseId)}`,
      body
    );

    return await this.http.post<void>(request);
  }

  async deleteEnterpriseImage(enterpriseId: string, imageId: string) {
    const request = new HttpRequest(
      `${ApiRoutes.enterpriseImage.replace("{id}", enterpriseId)}/${imageId}`
    );

    return await this.http.delete(request);
  }

  async updateEnterpriseUnitPercentage(
    id: string,
    percentage: number,
    percentageType: string
  ) {
    const request = new HttpRequest(
      ApiRoutes.enterpriseUnitPercentage.replace("{id}", id),
      { percentage, type: percentageType }
    );

    return await this.http.put<Enterprise>(request);
  }

  async getEnterpriseDocuments(
    enterpriseId: string
  ): Promise<EnterpriseDocuments[]> {
    const request = new HttpRequest(
      `${ApiRoutes.enterpriseCompanyDocument.replace("{id}", enterpriseId)}`
    );

    return await this.http.get<EnterpriseDocuments[]>(request);
  }

  async postEnterpriseDocumentById(
    enterpriseId: string,
    code: string,
    description: string,
    documentLink: string | null
  ) {
    const request = new HttpRequest(
      `${ApiRoutes.enterpriseCompanyDocument.replace("{id}", enterpriseId)}`,
      { code, description, documentLink }
    );
  }

  async putEnterpriseDocumentById(
    enterpriseId: string,
    code: string,
    status: string
  ) {
    const request = new HttpRequest(
      `${ApiRoutes.enterpriseCompanyDocument.replace("{id}", enterpriseId)}`,
      { code, status }
    );

    return await this.http.put<EnterpriseDocuments>(request);
  }
}
