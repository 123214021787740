import UnitDTO from "../../domain/dto/unitDTO";
import Unit from "../../domain/entities/unit";
import Http from "../../http/http";
import HttpRequest from "../../http/httpRequest";
import ApiRoutes from "../../http/routes";

export default class UnitRepository {
  private static instance: UnitRepository | null = null;

  constructor(private http: Http) {}

  static getInstance(http: Http): UnitRepository {
    if (!UnitRepository.instance) {
      UnitRepository.instance = new UnitRepository(http);
    }
    return UnitRepository.instance;
  }

  async deleteUnit(id: string) {
    const request = new HttpRequest(`${ApiRoutes.unit}/${id}`);

    return await this.http.delete(request);
  }

  /** @warning remember to put projectPlanId inside Unit  */
  async createUnit(unit: Unit, enterpriseId: string) {
    const payload = {
      id: unit.id,
      quantity: unit.quantity,
      price: unit.price,
      description: unit.description,
      status: unit.status.unitStatus,
      projectPlanId: unit.projectPlanId,
    };
    const request = new HttpRequest(
      ApiRoutes.enterpriseUnit.replace("{id}", enterpriseId),
      payload
    );

    return await this.http.post<Unit>(request);
  }

  async updateUnit(unit: Unit) {
    const payload = {
      id: unit.id,
      quantity: unit.quantity,
      price: unit.price,
      description: unit.description,
      status: unit.status.unitStatus,
      projectPlanId: unit.projectPlanId,
    };

    const request = new HttpRequest(`${ApiRoutes.unit}/${unit.id}`, payload);

    return await this.http.put<void>(request);
  }

  async updateUnitStatus(unitId: string, status: string) {
    const request = new HttpRequest(
      ApiRoutes.unitUpdateStatus.replace("{id}", unitId),
      { status }
    );

    return await this.http.put<void>(request);
  }

  async getUnits(enterpriseId: string): Promise<Unit[]> {
    const request = new HttpRequest(
      ApiRoutes.enterpriseUnit.replace("{id}", enterpriseId)
    );

    const response = await this.http.get<Unit[]>(request);
    return response.map((unit) => UnitDTO.fromJson(unit));
  }

  async getUnitById(unitId: string): Promise<Unit> {
    const request = new HttpRequest(`${ApiRoutes.unit}/${unitId}`);

    const response = await this.http.get<Unit>(request);
    return UnitDTO.fromJson(response);
  }
}
