import React, { useState, useEffect } from "react";
import "../../styles/components/_novosempreendimentos.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router";
import InputMask from "react-input-mask";
import ConstructionCompanyProvider from "../../infra/provider/constructionCompanyProvider";
import ConstructionCompany from "../../domain/entities/constructionCompany";
import { Address } from "../../domain/entities/address";
import ZipCode from "../../domain/entities/valueObjects/zipCode";

interface FormData {
    id: string;
    createdAt: string;
    cnpj: string;
    razaoSocial: string;
    responsibleName: string;
    email: string;
    phone: string;
    cpf: string;
    address: Address;
}

const CreateIncorporadoras: React.FC = () => {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(1);
    const [cep, setCep] = useState("");
    const [cnpj, setCnpj] = useState("");
    const [propertyName, setPropertyName] = useState("");
    const [cpf, setCpf] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [isValid, setIsValid] = useState(true);
    const [razaoSocial, setRazaoSocial] = useState("");
    const [address, setAddress] = useState<Address>({
        id: 0,
        city: "",
        uf: "",
        neighborhood: "",
        street: "",
        number: "",
        zipCode: new ZipCode(),
        complement: "",
    });

    const [loading, setLoading] = useState(false);
    const totalSteps = 3;
    const constructionCompanyProvider = ConstructionCompanyProvider.getInstance();

    const handleCnpjBlur = () => {
        const cnpjAux = cnpj.replace(/\D/g, "");
        if (cnpjAux.length === 14) {
            fetch(`https://publica.cnpj.ws/cnpj/${cnpjAux}`)
                .then(response => response.json())
                .then(data => {
                    setRazaoSocial(data.razao_social || "");
                })
                .catch(() => toast.error("Erro ao buscar CNPJ!"));
        }
    };

    const handleCepBlur = () => {
        if (cep.length === 9) {
            fetch(`https://viacep.com.br/ws/${cep.replace(/\D/g, "")}/json/`)
                .then(response => response.json())
                .then(data => {
                    if (!data.erro) {
                        setAddress(prev => ({
                            ...prev,
                            city: data.localidade,
                            uf: data.uf,
                            neighborhood: data.bairro,
                            street: data.logradouro,
                            zipCode: data.cep,
                        }));
                    } else {
                        toast.error("CEP inválido!");
                    }
                })
                .catch(() => toast.error("Erro ao buscar CEP!"));
        }
    };

    const validateStep1 = () => {
        if (!cnpj || cnpj.length !== 18) {
            toast.error("Digite um CNPJ válido!");
            return false;
        }
        if (!razaoSocial) {
            toast.error("Razão Social é obrigatória!");
            return false;
        }
        return true;
    };

    const validateStep2 = () => {
        if (!cep || cep.length !== 9) {
            toast.error("Digite um CEP válido!");
            return false;
        }
        if (!address.street) {
            toast.error("Endereço é obrigatório!");
            return false;
        }
        if (!address.neighborhood) {
            toast.error("Bairro é obrigatório!");
            return false;
        }
        if (!address.city) {
            toast.error("Cidade é obrigatória!");
            return false;
        }
        if (!address.uf) {
            toast.error("Estado é obrigatório!");
            return false;
        }
        return true;
    };

    const validateStep3 = () => {
        const cpfRegex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
        const phoneRegex = /^\(\d{2}\)\s\d{5}-\d{4}$/;
        const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;

        if (!propertyName) {
            toast.error("Nome do responsável é obrigatório!");
            return false;
        }
        if (!cpfRegex.test(cpf)) {
            toast.error("CPF inválido!");
            return false;
        }
        if (!phoneRegex.test(phone)) {
            toast.error("Telefone inválido!");
            return false;
        }
        if (!emailRegex.test(email)) {
            toast.error("Email inválido!");
            return false;
        }
        return true;
    };

    const handleFinalize = async () => {
        if (!validateStep3()) return;

        try {
            const newConstructionCompany = new ConstructionCompany(
                Math.random().toString(36).substr(2, 9),
                razaoSocial,
                cnpj,
                cpf,
                propertyName,
                email,
                phone,
                address
            );
            await constructionCompanyProvider.createConstructionCompany(newConstructionCompany);

            toast.success("Incorporadora cadasrada com sucesso!", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });

            setTimeout(() => navigate(`/incorporadoras/visualizar`), 1000);

        } catch (error) {
            toast.error("Erro ao cadastrar.", {
            });
        }

    };

    const handleNext = () => {
        let isValid = true;

        if (currentStep === 1) {
            isValid = validateStep1();
        }

        if (currentStep === 2) {
            isValid = validateStep2();
        }

        if (currentStep === 3) {
            isValid = validateStep3();
        }

        if (isValid && currentStep < totalSteps) {
            setCurrentStep((prev) => prev + 1);
        }
    };

    const handleBack = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };

    return (
        <div className="steps-container">
            <div className="form-header">
                <button className="back-button-arrow" onClick={() => navigate(-1)}>
                    &lt;
                </button>
                <span className="step-indicator">
                    {currentStep}/{totalSteps}
                </span>
            </div>
            <div className="progress-bar-main">
                <div
                    className="progress-bar-main__fill"
                    style={{ width: `${(currentStep / totalSteps) * 100}%` }}
                ></div>
            </div>

            <form className="step-form" onSubmit={(e) => e.preventDefault()}>
                {currentStep === 1 && (
                    <>
                        <h2 className="step-header">
                            Preencha as informações de identificação:
                        </h2>
                        <div className="form-group">
                            <label>CNPJ:</label>
                            <InputMask
                                mask="99.999.999/9999-99"
                                value={cnpj}
                                onChange={(e) => setCnpj(e.target.value)}
                                onBlur={handleCnpjBlur}
                                placeholder="Digite o CNPJ"
                            />
                        </div>
                        <div className="divisor"></div>
                        <div className="form-group">
                            <label>Razão Social:</label>
                            <input type="text" value={razaoSocial} readOnly />
                        </div>
                    </>
                )}

                {currentStep === 2 && (
                    <>
                        <h2 className="step-header">
                            Preencha as informações de endereço:
                        </h2>
                        <div className="form-group">
                            <label>CEP:</label>
                            <InputMask
                                mask="99999-999"
                                value={cep}
                                onChange={(e) => setCep(e.target.value)}
                                onBlur={handleCepBlur}
                                placeholder="Digite o CEP"
                            />
                        </div>
                        <div className="divisor"></div>
                        <div className="form-group">
                            <label>Logradouro:</label>
                            <input type="text" value={address.street} readOnly />
                        </div>
                        <div className="divisor"></div>
                        <div className="form-group">
                            <label>Bairro:</label>
                            <input type="text" value={address.neighborhood} readOnly />
                        </div>
                        <div className="divisor"></div>
                        <div className="form-group">
                            <label>Cidade:</label>
                            <input type="text" value={address.city} readOnly />
                        </div>
                        <div className="divisor"></div>
                        <div className="form-group">
                            <label>Estado:</label>
                            <input type="text" value={address.uf} readOnly />
                        </div>
                        <div className="divisor"></div>
                        <div className="form-group">
                            <label>Número:</label>
                            <input
                                type="text"
                                value={address.number}
                                onChange={(e) => setAddress(prev => ({ ...prev, number: e.target.value }))}
                                placeholder="Digite o número"
                            />
                        </div>
                        <div className="divisor"></div>
                        <div className="form-group">
                            <label>Complemento (opcional):</label>
                            <input
                                type="text"
                                value={address.complement}
                                onChange={(e) => setAddress(prev => ({ ...prev, complement: e.target.value }))}
                            />
                        </div>
                    </>
                )}

                {currentStep === 3 && (
                    <>
                        <h2 className="step-header">Preencha as informações do responsável:</h2>
                        <div className="form-group">
                            <label htmlFor="property-name">Nome do responsável:</label>
                            <input
                                required
                                id="property-name"
                                type="text"
                                placeholder="Nome completo"
                                value={propertyName}
                                onChange={(e) => setPropertyName(e.target.value)}
                            />
                        </div>
                        <div className="divisor"></div>
                        <div className="form-group">
                            <label htmlFor="property-description">CPF:</label>
                            <InputMask
                                mask="999.999.999-99"
                                value={cpf}
                                onChange={(e) => setCpf(e.target.value)}
                                maskChar={null}
                                required
                                placeholder="Digite o CPF"
                            />
                        </div>
                        <div className="divisor"></div>
                        <div className="form-group">
                            <label htmlFor="property-description">Telefone ou WhatsApp:</label>
                            <InputMask
                                mask="(99) 99999-9999"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                maskChar={null}
                                required
                                placeholder="Digite o telefone"
                            />
                        </div>
                        <div className="divisor"></div>
                        <div className="form-group">
                            <label htmlFor="property-description">Email corporativo:</label>
                            <input
                                id="property-description"
                                type="email"
                                required
                                placeholder="Digite o email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        {!isValid && <p className="error-message">Preencha todos os campos corretamente!</p>}
                    </>
                )}
            </form>

            <div className="step-footer">
                <button type="button" className="btn btn--back" onClick={handleBack}>
                    Voltar
                </button>
                <button
                    type="submit"
                    className="btn btn--next"
                    disabled={loading}
                    onClick={currentStep === totalSteps ? handleFinalize : handleNext}
                >
                    {currentStep === totalSteps && loading
                        ? "Carregando..."
                        : currentStep === totalSteps
                            ? "Finalizar"
                            : "Avançar"}
                </button>
                <ToastContainer />
            </div>
        </div>
    );
};

export default CreateIncorporadoras;