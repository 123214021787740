import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/components/_login.scss';
import { CircleAlert, Eye, EyeOff } from 'lucide-react';
import AuthProvider from '../../infra/provider/authProvider';
import header from '../../assets/images/header-new2.png';
import logo from '../../assets/images/homecorp-logo.png';
import PopupError from '../popup/PopupError';
import { Link } from 'react-router-dom';
import Loading from '../Loading';

const Login = () => {
  const authProvider = AuthProvider.getInstance();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [remember, setRemember] = useState(false);
  const [popup, setPopup] = useState(false);
  const [error, setError] = useState('');
  const [authErrors, setAuthErrors] = useState({ email: '', password: '' });
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const userEmail = localStorage.getItem('userEmail');
    if (userEmail) setEmail(userEmail);
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const errors = await authProvider.signin(email, password);
      if (errors) {
        setAuthErrors(errors);
        setIsLoading(false);
        return;
      }
      if (remember) localStorage.setItem('userEmail', email);
      navigate('/home');
    } catch (error: any) {
      setError('Não foi possível realizar o login. Verifique se o e-mail e a senha estão corretos e tente novamente.');
      setPopup(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-container-login">

      <div className="login-image">
        <img src={header} alt="Construtech" />
      </div>

      <div className="login-form-login">
        <div className="form-container-login">
          <a href="https://www.homecorp.com.br/" target="_blank" rel="noopener noreferrer">
            <img src={logo} alt="Homecorp logo" className="logo-login" />
          </a>
          <h1>Seja bem-vindo(a) à plataforma Homecorp!</h1>
          <p className="frase-form-login">Preencha os campos abaixo para acessar sua conta.</p>
          <form onSubmit={handleSubmit}>
            <label>E-mail</label>
            <input
              type="email"
              placeholder="Digite o seu e-mail"
              value={email}
              onChange={(email) => setEmail(email.target.value)}
              className={authErrors.email ? 'error' : ''}
            />
            {authErrors.email && (
              <div className="error-message">
                <span className="icon-alert">
                  <CircleAlert size={20} />
                </span>
                {authErrors.email}
              </div>
            )}

            <label>Senha</label>
            <div style={{ position: 'relative' }}>
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="Digite a sua senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={authErrors.password ? 'error' : ''}
              />
              <span
                className="show-password-icon"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </span>
            </div>
            {authErrors.password && (
              <div className="error-message">
                <span className="icon-alert">
                  <CircleAlert size={20} />
                </span>
                {authErrors.password}
              </div>
            )}

            <div className="remember-me">
              <input
                type="checkbox"
                checked={remember}
                onChange={() => setRemember(!remember)}
                id="remember"
                className="input-remember"
              />
              <label htmlFor="remember">Lembrar-se de mim</label>
            </div>

            {/* Melhorando loading */}
            <button type="submit" disabled={isLoading} className={isLoading ? 'loading' : ''}>
              {isLoading ? (
                <>
                  <div className="spinner"></div>
                  <span>Acessando...</span>
                </>
              ) : (
                "Acessar conta"
              )}
            </button>
          </form>
          <button className="btn-forgotpass">
            <Link to="/ForgotPassword">Esqueci minha senha</Link>
          </button>
        </div>
      </div>

      {popup &&
        PopupError({
          message: error,
          onClose: () => setPopup(false),
        })}
    </div>
  );
};

export default Login;
