import ProfileDTO from "../../domain/dto/profileDTO";
import CorrespondingEntity from "../../domain/entities/correspondingEntity";
import Profile from "../../domain/entities/profile";
import User from "../../domain/entities/user";
import Http from "../../http/http";
import HttpRequest from "../../http/httpRequest";
import ApiRoutes from "../../http/routes";

export default class UserRepository {
  private static instance: UserRepository | null = null;

  constructor(private http: Http) {}

  static getInstance(http: Http): UserRepository {
    if (!UserRepository.instance) {
      UserRepository.instance = new UserRepository(http);
    }
    return UserRepository.instance;
  }

  async getUserByDocument(document: string): Promise<Profile> {
    const request = new HttpRequest(ApiRoutes.user, undefined, { document });

    const response = await this.http.get<User>(request);
    return ProfileDTO.fromJson(response);
  }

  async getUsers(): Promise<CorrespondingEntity[]> {
    const response = await this.http.get<{ data: CorrespondingEntity[] }>(
      new HttpRequest(ApiRoutes.user)
    );
    return response.data;
  }

  async createUser(user: CorrespondingEntity) {
    const payload = {
      userType: user.userType,
      name: user.namePersonResponsible,
      socialName: user.companyName,
      document: user.documentName,
      email: user.email,
      assignedEnterprises: user.linkedVentures,
    };

    const request = new HttpRequest(ApiRoutes.user, payload);
    return await this.http.post<void>(request);
  }

  async updateUser(user: CorrespondingEntity) {
    const payload = {
      userType: user.userType,
      name: user.namePersonResponsible,
      socialName: user.companyName,
      document: user.documentName,
      email: user.email,
      assignedEnterprises: user.linkedVentures,
    };

    const request = new HttpRequest(`${ApiRoutes.user}/${user.id}`, payload);
    return await this.http.put<void>(request);
  }

  async updateUserStatus(userId: string, status: string) {
    const request = new HttpRequest(`${ApiRoutes.user}/${userId}/status`, {
      status,
    });
    return await this.http.put<void>(request);
  }
}
