import Room from "./valueObjects/room";

export default class ProjectPlan {
  constructor(
    public id: number | undefined = undefined,
    public name: string = "",
    public area: number | undefined = undefined,
    public builtArea: number | undefined = undefined,
    public rooms: Room[] = [
      new Room("", "Quarto", 0, undefined),
      new Room("", "Banheiro", 0, undefined),
      new Room("", "Garagem", 0, undefined),
    ],
    public images: { id: number; imageLink: string }[] = []
  ) {}
}
